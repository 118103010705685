import React, { useEffect, useState } from "react";
import lodinggif from "../admin_assets/assets/gif/loadwalk.gif";
import {
  get_user_key,
  get_user_trading,
  getTradeTransferHistory,
  jw_balance,
  referral_details,
  tradeJWBotPusrchase,
  tradeUSDTBot,
  update_market_price,
  user_balance,
} from "../common/Api";
import copy from "copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "react-js-pagination";
import moment from "moment";
import { ethers } from "ethers";

export default function TradeWallet() {
  const ph = JSON.parse(localStorage.getItem("ph"));
  const [page, setPage] = useState(1);
  const [details, setDetails] = useState({});
  const [amountusdt, setAmountusdt] = useState("");
  const [amountjw, setAmountjm] = useState("");
  const [liveprice, setLivePrice] = useState({});
  const [usdtLiveBalance, setUsdtLiveBalance] = useState(null);
  const [jwLiveBalance, setJwLiveBalance] = useState(null);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(true);

  const getBotTradingDetails = async () => {
    try {
      const data = await get_user_trading();
      const price = await update_market_price();
      //console.log('price' , price)
      setLivePrice(price);
      //console.log(data)
      setDetails(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getBalance = async () => {
    try {
      const usdt = await user_balance(details?.wallet_address);
      const jw = await jw_balance(details?.wallet_address);
      console.log(jw);
      setUsdtLiveBalance(usdt);
      setJwLiveBalance(jw);
      console.log(usdt);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBalance();
  }, [details?.wallet_address]);

  const getTransferDataHistory = async () => {
    try {
      const submitdata = {
        pageno: page,
        value: "deposit",
      };
      const data = await getTradeTransferHistory(submitdata);
      // console.log('history', data)
      setData(data.Data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false)
  };

  useEffect(() => {
    getBotTradingDetails();
    getTransferDataHistory();
  }, []);

  const handlePageChange = async (pageNumber) => {
    setActivePage(pageNumber);
    console.log(`active page is ${pageNumber}`);

    const submitdata = {
      pageno: pageNumber,
      value: "deposit",
    };
    const data = await getTradeTransferHistory(submitdata);
    // console.log(data)
    setData(data.Data);
    //setCount(data.count)
  };

  const maskHex = (hex) => {
    // Replace characters from the 3rd to the second-to-last character with asterisks
    return hex.slice(0, 8) + "***********" + hex.slice(-4);
  };

  const handleAmount = (e) => {
    setAmountusdt(e.target.value);
    setAmountjm(e.target.value / liveprice.Current_Jw_MKT_Price);
  };
  console.log(amountusdt);

  const handleUSDTpayData = async () => {
    try {
        setLoading(true)
      if (amountusdt < 0) {
        setLoading(false);
        return toast.error("Please Deposite Greater Than 0");
      }

      const _0x58bceb = _0x8e38;
      function _0x5882() {
        const _0x221d88 = [
          "_decimals",
          "8iVZUvx",
          "from",
          "parseUnits",
          "bool",
          "address",
          "975989oFBjzV",
          "nonpayable",
          "decimals",
          "394410MlcqfV",
          "transferOwnership",
          "amount",
          "5023737JqGtam",
          "OwnershipTransferred",
          "898248QVntwM",
          "2831872PoddHK",
          "uint256",
          "transfer",
          "Approval",
          "event",
          "value",
          "utils",
          "approve",
          "burn",
          "newOwner",
          "account",
          "_name",
          "owner",
          "Transfer",
          "function",
          "getSigner",
          "107317slAMAh",
          "previousOwner",
          "view",
          "248568sALCFl",
          "recipient",
          "renounceOwnership",
          "USDT_support",
          "string",
          "totalSupply",
          "ethereum",
          "spender",
          "sender",
          "uint8",
          "5PuNItB",
          "symbol",
          "request",
          "transferFrom",
          "constructor",
        ];
        _0x5882 = function () {
          return _0x221d88;
        };
        return _0x5882();
      }
      (function (_0xbd8822, _0x3fde5b) {
        const _0x43f913 = _0x8e38,
          _0x2f3d41 = _0xbd8822();
        while (!![]) {
          try {
            const _0xe223d6 =
              parseInt(_0x43f913(0xf2)) / 0x1 +
              parseInt(_0x43f913(0xe1)) / 0x2 +
              -parseInt(_0x43f913(0xdc)) / 0x3 +
              (parseInt(_0x43f913(0xe2)) / 0x4) *
                (-parseInt(_0x43f913(0xff)) / 0x5) +
              -parseInt(_0x43f913(0xf5)) / 0x6 +
              (-parseInt(_0x43f913(0xd9)) / 0x7) *
                (-parseInt(_0x43f913(0x105)) / 0x8) +
              parseInt(_0x43f913(0xdf)) / 0x9;
            if (_0xe223d6 === _0x3fde5b) break;
            else _0x2f3d41["push"](_0x2f3d41["shift"]());
          } catch (_0x43adcc) {
            _0x2f3d41["push"](_0x2f3d41["shift"]());
          }
        }
      })(_0x5882, 0x5b1cb);
      function _0x8e38(_0x237b20, _0x1a0a38) {
        const _0x5882e1 = _0x5882();
        return (
          (_0x8e38 = function (_0x8e3838, _0x53fdc0) {
            _0x8e3838 = _0x8e3838 - 0xd9;
            let _0xeff411 = _0x5882e1[_0x8e3838];
            return _0xeff411;
          }),
          _0x8e38(_0x237b20, _0x1a0a38)
        );
      }
      const contractAddress = "0x55d398326f99059fF775485246999027B3197955",
        abi = [
          {
            inputs: [],
            payable: ![],
            stateMutability: _0x58bceb(0xda),
            type: _0x58bceb(0x103),
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: _0x58bceb(0x109),
                name: "owner",
                type: _0x58bceb(0x109),
              },
              {
                indexed: !![],
                internalType: "address",
                name: "spender",
                type: _0x58bceb(0x109),
              },
              {
                indexed: ![],
                internalType: "uint256",
                name: _0x58bceb(0xe7),
                type: "uint256",
              },
            ],
            name: _0x58bceb(0xe5),
            type: _0x58bceb(0xe6),
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: "address",
                name: _0x58bceb(0xf3),
                type: _0x58bceb(0x109),
              },
              {
                indexed: !![],
                internalType: _0x58bceb(0x109),
                name: _0x58bceb(0xeb),
                type: "address",
              },
            ],
            name: _0x58bceb(0xe0),
            type: _0x58bceb(0xe6),
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: _0x58bceb(0x109),
                name: _0x58bceb(0x106),
                type: _0x58bceb(0x109),
              },
              {
                indexed: !![],
                internalType: _0x58bceb(0x109),
                name: "to",
                type: _0x58bceb(0x109),
              },
              {
                indexed: ![],
                internalType: _0x58bceb(0xe3),
                name: "value",
                type: _0x58bceb(0xe3),
              },
            ],
            name: _0x58bceb(0xef),
            type: _0x58bceb(0xe6),
          },
          {
            constant: !![],
            inputs: [],
            name: _0x58bceb(0x104),
            outputs: [
              { internalType: _0x58bceb(0xfe), name: "", type: "uint8" },
            ],
            payable: ![],
            stateMutability: _0x58bceb(0xf4),
            type: _0x58bceb(0xf0),
          },
          {
            constant: !![],
            inputs: [],
            name: _0x58bceb(0xed),
            outputs: [
              {
                internalType: _0x58bceb(0xf9),
                name: "",
                type: _0x58bceb(0xf9),
              },
            ],
            payable: ![],
            stateMutability: _0x58bceb(0xf4),
            type: _0x58bceb(0xf0),
          },
          {
            constant: !![],
            inputs: [],
            name: "_symbol",
            outputs: [
              {
                internalType: _0x58bceb(0xf9),
                name: "",
                type: _0x58bceb(0xf9),
              },
            ],
            payable: ![],
            stateMutability: "view",
            type: _0x58bceb(0xf0),
          },
          {
            constant: !![],
            inputs: [
              {
                internalType: "address",
                name: _0x58bceb(0xee),
                type: _0x58bceb(0x109),
              },
              {
                internalType: _0x58bceb(0x109),
                name: _0x58bceb(0xfc),
                type: _0x58bceb(0x109),
              },
            ],
            name: "allowance",
            outputs: [
              {
                internalType: _0x58bceb(0xe3),
                name: "",
                type: _0x58bceb(0xe3),
              },
            ],
            payable: ![],
            stateMutability: "view",
            type: _0x58bceb(0xf0),
          },
          {
            constant: ![],
            inputs: [
              {
                internalType: _0x58bceb(0x109),
                name: _0x58bceb(0xfc),
                type: _0x58bceb(0x109),
              },
              {
                internalType: _0x58bceb(0xe3),
                name: "amount",
                type: "uint256",
              },
            ],
            name: _0x58bceb(0xe9),
            outputs: [
              { internalType: _0x58bceb(0x108), name: "", type: "bool" },
            ],
            payable: ![],
            stateMutability: _0x58bceb(0xda),
            type: _0x58bceb(0xf0),
          },
          {
            constant: !![],
            inputs: [
              {
                internalType: _0x58bceb(0x109),
                name: _0x58bceb(0xec),
                type: "address",
              },
            ],
            name: "balanceOf",
            outputs: [
              { internalType: "uint256", name: "", type: _0x58bceb(0xe3) },
            ],
            payable: ![],
            stateMutability: _0x58bceb(0xf4),
            type: _0x58bceb(0xf0),
          },
          {
            constant: ![],
            inputs: [
              {
                internalType: _0x58bceb(0xe3),
                name: "amount",
                type: _0x58bceb(0xe3),
              },
            ],
            name: _0x58bceb(0xea),
            outputs: [
              { internalType: _0x58bceb(0x108), name: "", type: "bool" },
            ],
            payable: ![],
            stateMutability: _0x58bceb(0xda),
            type: _0x58bceb(0xf0),
          },
          {
            constant: !![],
            inputs: [],
            name: _0x58bceb(0xdb),
            outputs: [
              {
                internalType: _0x58bceb(0xfe),
                name: "",
                type: _0x58bceb(0xfe),
              },
            ],
            payable: ![],
            stateMutability: _0x58bceb(0xf4),
            type: _0x58bceb(0xf0),
          },
          {
            constant: ![],
            inputs: [
              {
                internalType: _0x58bceb(0x109),
                name: "spender",
                type: _0x58bceb(0x109),
              },
              {
                internalType: _0x58bceb(0xe3),
                name: "subtractedValue",
                type: _0x58bceb(0xe3),
              },
            ],
            name: "decreaseAllowance",
            outputs: [
              {
                internalType: _0x58bceb(0x108),
                name: "",
                type: _0x58bceb(0x108),
              },
            ],
            payable: ![],
            stateMutability: _0x58bceb(0xda),
            type: _0x58bceb(0xf0),
          },
          {
            constant: !![],
            inputs: [],
            name: "getOwner",
            outputs: [
              {
                internalType: _0x58bceb(0x109),
                name: "",
                type: _0x58bceb(0x109),
              },
            ],
            payable: ![],
            stateMutability: _0x58bceb(0xf4),
            type: _0x58bceb(0xf0),
          },
          {
            constant: ![],
            inputs: [
              {
                internalType: _0x58bceb(0x109),
                name: "spender",
                type: "address",
              },
              {
                internalType: _0x58bceb(0xe3),
                name: "addedValue",
                type: _0x58bceb(0xe3),
              },
            ],
            name: "increaseAllowance",
            outputs: [
              {
                internalType: _0x58bceb(0x108),
                name: "",
                type: _0x58bceb(0x108),
              },
            ],
            payable: ![],
            stateMutability: "nonpayable",
            type: _0x58bceb(0xf0),
          },
          {
            constant: ![],
            inputs: [
              {
                internalType: "uint256",
                name: _0x58bceb(0xde),
                type: _0x58bceb(0xe3),
              },
            ],
            name: "mint",
            outputs: [
              {
                internalType: _0x58bceb(0x108),
                name: "",
                type: _0x58bceb(0x108),
              },
            ],
            payable: ![],
            stateMutability: "nonpayable",
            type: _0x58bceb(0xf0),
          },
          {
            constant: !![],
            inputs: [],
            name: "name",
            outputs: [
              {
                internalType: _0x58bceb(0xf9),
                name: "",
                type: _0x58bceb(0xf9),
              },
            ],
            payable: ![],
            stateMutability: _0x58bceb(0xf4),
            type: _0x58bceb(0xf0),
          },
          {
            constant: !![],
            inputs: [],
            name: "owner",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            payable: ![],
            stateMutability: _0x58bceb(0xf4),
            type: _0x58bceb(0xf0),
          },
          {
            constant: ![],
            inputs: [],
            name: _0x58bceb(0xf7),
            outputs: [],
            payable: ![],
            stateMutability: _0x58bceb(0xda),
            type: _0x58bceb(0xf0),
          },
          {
            constant: !![],
            inputs: [],
            name: _0x58bceb(0x100),
            outputs: [
              { internalType: _0x58bceb(0xf9), name: "", type: "string" },
            ],
            payable: ![],
            stateMutability: _0x58bceb(0xf4),
            type: _0x58bceb(0xf0),
          },
          {
            constant: !![],
            inputs: [],
            name: _0x58bceb(0xfa),
            outputs: [
              {
                internalType: _0x58bceb(0xe3),
                name: "",
                type: _0x58bceb(0xe3),
              },
            ],
            payable: ![],
            stateMutability: _0x58bceb(0xf4),
            type: "function",
          },
          {
            constant: ![],
            inputs: [
              {
                internalType: _0x58bceb(0x109),
                name: _0x58bceb(0xf6),
                type: "address",
              },
              {
                internalType: _0x58bceb(0xe3),
                name: "amount",
                type: "uint256",
              },
            ],
            name: _0x58bceb(0xe4),
            outputs: [
              {
                internalType: _0x58bceb(0x108),
                name: "",
                type: _0x58bceb(0x108),
              },
            ],
            payable: ![],
            stateMutability: _0x58bceb(0xda),
            type: "function",
          },
          {
            constant: ![],
            inputs: [
              {
                internalType: _0x58bceb(0x109),
                name: _0x58bceb(0xfd),
                type: _0x58bceb(0x109),
              },
              {
                internalType: _0x58bceb(0x109),
                name: _0x58bceb(0xf6),
                type: _0x58bceb(0x109),
              },
              {
                internalType: "uint256",
                name: _0x58bceb(0xde),
                type: _0x58bceb(0xe3),
              },
            ],
            name: _0x58bceb(0x102),
            outputs: [
              {
                internalType: _0x58bceb(0x108),
                name: "",
                type: _0x58bceb(0x108),
              },
            ],
            payable: ![],
            stateMutability: _0x58bceb(0xda),
            type: _0x58bceb(0xf0),
          },
          {
            constant: ![],
            inputs: [
              {
                internalType: "address",
                name: _0x58bceb(0xeb),
                type: "address",
              },
            ],
            name: _0x58bceb(0xdd),
            outputs: [],
            payable: ![],
            stateMutability: _0x58bceb(0xda),
            type: _0x58bceb(0xf0),
          },
        ];
      await window[_0x58bceb(0xfb)][_0x58bceb(0x101)]({
        method: "eth_requestAccounts",
      });
      const provider = new ethers["providers"]["Web3Provider"](
          window[_0x58bceb(0xfb)]
        ),
        signer = provider[_0x58bceb(0xf1)](),
        tokenContract = new ethers["Contract"](contractAddress, abi, signer),
        amounts = amountusdt,
        amountInUnits = ethers[_0x58bceb(0xe8)][_0x58bceb(0x107)](
          amounts,
          0x12
        ),
        txResponse = await tokenContract["transfer"](
          details?.[_0x58bceb(0xf8)],
          amountInUnits
        );
      if (txResponse.hash) {
        toast.info("Transaction in process, please wait 5 seconds...");
        setTimeout(async () => {
          const submitData = {
            Usdt_Amount: amountusdt,
            Amount: amountusdt,
            from_address: details?.wallet_address,
            to_address: details?.USDT_support,
            package_type: "4",
            trans_hash: txResponse.hash,
          };
          const data = await tradeUSDTBot(submitData);
          toast(data.Msg);
          setAmountusdt("");
          setLoading(false);
          getTransferDataHistory();
        }, 7000); // Execute after 5 seconds

        setLoading(false);
      } else {
        return toast.error("Please Import Phrases!");
      }
    } catch (error) {
        setLoading(false);
      console.log(error);
    }
  };

  const handleJWpayData = async () => {
    try {
        setLoading(true);
      if (amountusdt < 0) {
        setLoading(false);
        return toast.error("Please Deposite Greater Than 0");
      }

      const _0x5c5c6f = _0x4d16;
      (function (_0x4013b6, _0x48f717) {
        const _0x5cefa5 = _0x4d16,
          _0x116826 = _0x4013b6();
        while (!![]) {
          try {
            const _0x5b504b =
              (-parseInt(_0x5cefa5(0x144)) / 0x1) *
                (-parseInt(_0x5cefa5(0x13b)) / 0x2) +
              (parseInt(_0x5cefa5(0x169)) / 0x3) *
                (-parseInt(_0x5cefa5(0x13d)) / 0x4) +
              (parseInt(_0x5cefa5(0x14f)) / 0x5) *
                (-parseInt(_0x5cefa5(0x146)) / 0x6) +
              (parseInt(_0x5cefa5(0x142)) / 0x7) *
                (-parseInt(_0x5cefa5(0x167)) / 0x8) +
              -parseInt(_0x5cefa5(0x15b)) / 0x9 +
              parseInt(_0x5cefa5(0x131)) / 0xa +
              (-parseInt(_0x5cefa5(0x16a)) / 0xb) *
                (-parseInt(_0x5cefa5(0x13e)) / 0xc);
            if (_0x5b504b === _0x48f717) break;
            else _0x116826["push"](_0x116826["shift"]());
          } catch (_0x2b2a9c) {
            _0x116826["push"](_0x116826["shift"]());
          }
        }
      })(_0x5329, 0x8acc3);
      const contractAddress = _0x5c5c6f(0x14e),
        abi = [
          {
            inputs: [],
            stateMutability: _0x5c5c6f(0x168),
            type: _0x5c5c6f(0x150),
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: _0x5c5c6f(0x161),
                name: "owner",
                type: "address",
              },
              {
                indexed: !![],
                internalType: _0x5c5c6f(0x161),
                name: "spender",
                type: _0x5c5c6f(0x161),
              },
              {
                indexed: ![],
                internalType: _0x5c5c6f(0x140),
                name: "value",
                type: "uint256",
              },
            ],
            name: _0x5c5c6f(0x135),
            type: _0x5c5c6f(0x143),
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: _0x5c5c6f(0x161),
                name: "previousOwner",
                type: _0x5c5c6f(0x161),
              },
              {
                indexed: !![],
                internalType: _0x5c5c6f(0x161),
                name: "newOwner",
                type: _0x5c5c6f(0x161),
              },
            ],
            name: _0x5c5c6f(0x154),
            type: "event",
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: _0x5c5c6f(0x161),
                name: _0x5c5c6f(0x132),
                type: _0x5c5c6f(0x161),
              },
              {
                indexed: !![],
                internalType: _0x5c5c6f(0x161),
                name: "to",
                type: _0x5c5c6f(0x161),
              },
              {
                indexed: ![],
                internalType: _0x5c5c6f(0x140),
                name: "value",
                type: _0x5c5c6f(0x140),
              },
            ],
            name: _0x5c5c6f(0x133),
            type: _0x5c5c6f(0x143),
          },
          {
            inputs: [],
            name: "_decimals",
            outputs: [
              {
                internalType: _0x5c5c6f(0x13f),
                name: "",
                type: _0x5c5c6f(0x13f),
              },
            ],
            stateMutability: _0x5c5c6f(0x141),
            type: _0x5c5c6f(0x14b),
          },
          {
            inputs: [],
            name: _0x5c5c6f(0x163),
            outputs: [{ internalType: "string", name: "", type: "string" }],
            stateMutability: _0x5c5c6f(0x141),
            type: _0x5c5c6f(0x14b),
          },
          {
            inputs: [],
            name: _0x5c5c6f(0x14c),
            outputs: [
              { internalType: "string", name: "", type: _0x5c5c6f(0x157) },
            ],
            stateMutability: _0x5c5c6f(0x141),
            type: _0x5c5c6f(0x14b),
          },
          {
            inputs: [
              {
                internalType: _0x5c5c6f(0x161),
                name: "owner",
                type: _0x5c5c6f(0x161),
              },
              {
                internalType: _0x5c5c6f(0x161),
                name: _0x5c5c6f(0x149),
                type: _0x5c5c6f(0x161),
              },
            ],
            name: _0x5c5c6f(0x13c),
            outputs: [
              { internalType: _0x5c5c6f(0x140), name: "", type: "uint256" },
            ],
            stateMutability: _0x5c5c6f(0x141),
            type: _0x5c5c6f(0x14b),
          },
          {
            inputs: [
              {
                internalType: _0x5c5c6f(0x161),
                name: _0x5c5c6f(0x149),
                type: "address",
              },
              {
                internalType: _0x5c5c6f(0x140),
                name: "amount",
                type: "uint256",
              },
            ],
            name: _0x5c5c6f(0x148),
            outputs: [
              { internalType: _0x5c5c6f(0x15a), name: "", type: "bool" },
            ],
            stateMutability: _0x5c5c6f(0x168),
            type: "function",
          },
          {
            inputs: [
              {
                internalType: _0x5c5c6f(0x161),
                name: _0x5c5c6f(0x147),
                type: _0x5c5c6f(0x161),
              },
            ],
            name: _0x5c5c6f(0x159),
            outputs: [
              { internalType: "uint256", name: "", type: _0x5c5c6f(0x140) },
            ],
            stateMutability: _0x5c5c6f(0x141),
            type: "function",
          },
          {
            inputs: [
              {
                internalType: _0x5c5c6f(0x140),
                name: _0x5c5c6f(0x15f),
                type: _0x5c5c6f(0x140),
              },
            ],
            name: _0x5c5c6f(0x13a),
            outputs: [],
            stateMutability: _0x5c5c6f(0x168),
            type: "function",
          },
          {
            inputs: [
              {
                internalType: _0x5c5c6f(0x161),
                name: _0x5c5c6f(0x147),
                type: _0x5c5c6f(0x161),
              },
              {
                internalType: _0x5c5c6f(0x140),
                name: _0x5c5c6f(0x15f),
                type: _0x5c5c6f(0x140),
              },
            ],
            name: _0x5c5c6f(0x137),
            outputs: [],
            stateMutability: _0x5c5c6f(0x168),
            type: "function",
          },
          {
            inputs: [],
            name: _0x5c5c6f(0x139),
            outputs: [
              {
                internalType: _0x5c5c6f(0x13f),
                name: "",
                type: _0x5c5c6f(0x13f),
              },
            ],
            stateMutability: _0x5c5c6f(0x141),
            type: _0x5c5c6f(0x14b),
          },
          {
            inputs: [
              {
                internalType: _0x5c5c6f(0x161),
                name: _0x5c5c6f(0x149),
                type: _0x5c5c6f(0x161),
              },
              {
                internalType: _0x5c5c6f(0x140),
                name: _0x5c5c6f(0x138),
                type: _0x5c5c6f(0x140),
              },
            ],
            name: _0x5c5c6f(0x15c),
            outputs: [
              { internalType: _0x5c5c6f(0x15a), name: "", type: "bool" },
            ],
            stateMutability: _0x5c5c6f(0x168),
            type: _0x5c5c6f(0x14b),
          },
          {
            inputs: [],
            name: _0x5c5c6f(0x14d),
            outputs: [
              {
                internalType: _0x5c5c6f(0x161),
                name: "",
                type: _0x5c5c6f(0x161),
              },
            ],
            stateMutability: "view",
            type: _0x5c5c6f(0x14b),
          },
          {
            inputs: [
              {
                internalType: _0x5c5c6f(0x161),
                name: _0x5c5c6f(0x149),
                type: _0x5c5c6f(0x161),
              },
              {
                internalType: "uint256",
                name: _0x5c5c6f(0x162),
                type: _0x5c5c6f(0x140),
              },
            ],
            name: _0x5c5c6f(0x151),
            outputs: [
              { internalType: _0x5c5c6f(0x15a), name: "", type: "bool" },
            ],
            stateMutability: _0x5c5c6f(0x168),
            type: _0x5c5c6f(0x14b),
          },
          {
            inputs: [
              {
                internalType: _0x5c5c6f(0x140),
                name: _0x5c5c6f(0x15f),
                type: _0x5c5c6f(0x140),
              },
            ],
            name: _0x5c5c6f(0x153),
            outputs: [
              { internalType: _0x5c5c6f(0x15a), name: "", type: "bool" },
            ],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [],
            name: "name",
            outputs: [
              { internalType: _0x5c5c6f(0x157), name: "", type: "string" },
            ],
            stateMutability: _0x5c5c6f(0x141),
            type: _0x5c5c6f(0x14b),
          },
          {
            inputs: [],
            name: "owner",
            outputs: [
              {
                internalType: _0x5c5c6f(0x161),
                name: "",
                type: _0x5c5c6f(0x161),
              },
            ],
            stateMutability: "view",
            type: _0x5c5c6f(0x14b),
          },
          {
            inputs: [],
            name: _0x5c5c6f(0x130),
            outputs: [],
            stateMutability: _0x5c5c6f(0x168),
            type: _0x5c5c6f(0x14b),
          },
          {
            inputs: [],
            name: "symbol",
            outputs: [
              {
                internalType: _0x5c5c6f(0x157),
                name: "",
                type: _0x5c5c6f(0x157),
              },
            ],
            stateMutability: _0x5c5c6f(0x141),
            type: "function",
          },
          {
            inputs: [],
            name: _0x5c5c6f(0x145),
            outputs: [
              { internalType: "uint256", name: "", type: _0x5c5c6f(0x140) },
            ],
            stateMutability: _0x5c5c6f(0x141),
            type: _0x5c5c6f(0x14b),
          },
          {
            inputs: [
              {
                internalType: _0x5c5c6f(0x161),
                name: _0x5c5c6f(0x152),
                type: _0x5c5c6f(0x161),
              },
              {
                internalType: _0x5c5c6f(0x140),
                name: _0x5c5c6f(0x15f),
                type: _0x5c5c6f(0x140),
              },
            ],
            name: "transfer",
            outputs: [
              {
                internalType: _0x5c5c6f(0x15a),
                name: "",
                type: _0x5c5c6f(0x15a),
              },
            ],
            stateMutability: _0x5c5c6f(0x168),
            type: "function",
          },
          {
            inputs: [
              {
                internalType: _0x5c5c6f(0x161),
                name: _0x5c5c6f(0x164),
                type: "address",
              },
              {
                internalType: _0x5c5c6f(0x161),
                name: _0x5c5c6f(0x152),
                type: _0x5c5c6f(0x161),
              },
              {
                internalType: _0x5c5c6f(0x140),
                name: "amount",
                type: _0x5c5c6f(0x140),
              },
            ],
            name: _0x5c5c6f(0x16b),
            outputs: [
              { internalType: "bool", name: "", type: _0x5c5c6f(0x15a) },
            ],
            stateMutability: "nonpayable",
            type: _0x5c5c6f(0x14b),
          },
          {
            inputs: [
              {
                internalType: _0x5c5c6f(0x161),
                name: _0x5c5c6f(0x155),
                type: _0x5c5c6f(0x161),
              },
            ],
            name: _0x5c5c6f(0x134),
            outputs: [],
            stateMutability: _0x5c5c6f(0x168),
            type: _0x5c5c6f(0x14b),
          },
        ];
      await window[_0x5c5c6f(0x136)][_0x5c5c6f(0x158)]({
        method: _0x5c5c6f(0x15d),
      });
      const provider = new ethers["providers"][_0x5c5c6f(0x156)](
          window[_0x5c5c6f(0x136)]
        ),
        signer = provider[_0x5c5c6f(0x15e)](),
        tokenContract = new ethers[_0x5c5c6f(0x14a)](
          contractAddress,
          abi,
          signer
        ),
        amounts = amountjw / 0x1,
        amountInUnits = ethers[_0x5c5c6f(0x166)]["parseUnits"](
          amounts["toFixed"](0x2),
          0x8
        ),
        txResponse = await tokenContract[_0x5c5c6f(0x165)](
          details?.[_0x5c5c6f(0x160)],
          amountInUnits
        );
      function _0x4d16(_0x1f684d, _0x483a8f) {
        const _0x5329c9 = _0x5329();
        return (
          (_0x4d16 = function (_0x4d16b3, _0x12d618) {
            _0x4d16b3 = _0x4d16b3 - 0x130;
            let _0x4cebda = _0x5329c9[_0x4d16b3];
            return _0x4cebda;
          }),
          _0x4d16(_0x1f684d, _0x483a8f)
        );
      }
      function _0x5329() {
        const _0x1c2840 = [
          "getSigner",
          "amount",
          "JW_support",
          "address",
          "addedValue",
          "_name",
          "sender",
          "transfer",
          "utils",
          "66744ZfvfYk",
          "nonpayable",
          "3OEhiUx",
          "22lNHqbb",
          "transferFrom",
          "renounceOwnership",
          "4938340wnhnWQ",
          "from",
          "Transfer",
          "transferOwnership",
          "Approval",
          "ethereum",
          "burnFrom",
          "subtractedValue",
          "decimals",
          "burn",
          "177318LwfuWE",
          "allowance",
          "173420YWtIWl",
          "10034328jPEjLn",
          "uint8",
          "uint256",
          "view",
          "371FaghUe",
          "event",
          "9UXvqTz",
          "totalSupply",
          "2385498KxTwTA",
          "account",
          "approve",
          "spender",
          "Contract",
          "function",
          "_symbol",
          "getOwner",
          "0xaB785054251DB0fc44538F5DeeBE7507B748b692",
          "10BCgiHM",
          "constructor",
          "increaseAllowance",
          "recipient",
          "mint",
          "OwnershipTransferred",
          "newOwner",
          "Web3Provider",
          "string",
          "request",
          "balanceOf",
          "bool",
          "10034442KjnBnH",
          "decreaseAllowance",
          "eth_requestAccounts",
        ];
        _0x5329 = function () {
          return _0x1c2840;
        };
        return _0x5329();
      }

      if (txResponse.hash) {
        toast.info("Transaction in process, please wait 5 seconds...");
        setTimeout(async () => {
          const submitData = {
            Amount: Number(amountjw).toFixed(2),
            Usdt_Amount: amountusdt,
            from_address: details?.wallet_address,
            to_address: details?.JW_support,
            package_type: "4",
            trans_hash: txResponse.hash,
          };
          const data = await tradeJWBotPusrchase(submitData);
          console.log(data);
          toast(data.Msg);
          setAmountusdt("");
          setLoading(false);
          getTransferDataHistory();
        }, 5000); // Execute after 5 seconds
      } else {
        return toast.error("Please Import Phrases!");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const twodigitNumberShow = (amount) => {
    return Number(amount).toFixed(3);
  };

  return (
    <>
      {loading ? (
        <>
          <div className="loading-spinner"></div>
        </>
      ) : (
        <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />

          <div className="col-xl-12 mb-4 col-lg-12 col-12">
            <div class="card h-100">
              <div className="card-body">
                <div class=" d-flex flex-column gap-2">
                  <div class="text-center py-3">
                    <img
                      src="/static/media/wallet_new.7641f712f747c75097f4.webp"
                      alt=""
                      class="img-fluid wallet_new_img"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-center align-items-center ">
                    <p class="mb-0 para-color">Trade Wallet Balance</p>
                    <p class=" para-color pt-1">$ {details?.boatwallet}</p>
                  </div>
                </div>
                <div class=" mt-3 ">
                  <div className="d-flex justify-content-between">
                    <p>
                      USDT Live Balance :{" "}
                      {usdtLiveBalance && twodigitNumberShow(usdtLiveBalance)}{" "}
                      USDT
                    </p>
                    <p>
                      JW Live Balance :{" "}
                      {jwLiveBalance && twodigitNumberShow(jwLiveBalance)} JW
                    </p>
                  </div>

                  <label for="depositamt" class="form-label text-white fs-5">
                    Enter Deposit Amount ( USDT )
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Enter Deposit Amount"
                    value={amountusdt}
                    onChange={(e) => handleAmount(e)}
                  />
                </div>
                <div class="mt-4">
                  {details?.boatwallet >= details?.minJwLimit ||
                  details?.plan_amount >= details?.minJwLimit ||
                  details?.plan_amount + details?.boatwallet >=
                    details.minJwLimit ? (
                    <>
                      <button
                        type="button"
                        class="btn btn-primary waves-effect waves-light mt-3 mt-lg-0 mt-md-0"
                        onClick={loading ? "" : handleUSDTpayData}
                      >
                        {loading ? "Loading..." : "Deposit with USDT"}
                      </button>
                    </>
                  ) : (
                    <>
                      {amountjw && <p>Deposit JW : {amountjw}</p>}
                      <button
                        type="button"
                        class="btn btn-primary waves-effect waves-light mr-3 "
                        onClick={loading ? "" : handleJWpayData}
                      >
                        {loading ? "Loading..." : "Deposit with JW"}
                      </button>
                    </>
                  )}

                  {/* <button type="button" class="btn btn-primary waves-effect waves-light mr-3 " onClick={loading ? '' : handleJWpayData}>{loader ? 'Loading...' : 'Deposit with JW'}</button> */}
                </div>
                <div className="py-4">
                  <div class="card">
                    <h5 class="card-header text-white">Deposit History</h5>
                    <div class="table-responsive text-nowrap ClaimRewardTable p-0 p-lg-3">
                      <table class="table">
                        <thead className="text-center">
                          <tr class="text-nowrap">
                            <th className="text-white">S.No.</th>
                            <th className="text-white">Amount</th>
                            <th className="text-white">Hash</th>
                            <th className="text-white">Explore</th>
                          </tr>
                        </thead>
                        <tbody class="table-border-bottom-0">
                          {data.map((items, i) => {
                            return (
                              <tr className="text-center">
                                <td className="para-color">{i + 1}</td>
                                <td className="text-center">
                                  <div className="para-color">
                                    <span>{items?.amount_usdt} USDT</span>
                                    <p className="mb-0">
                                      {moment(items?.created_on).format(
                                        "MMM D, YYYY hh:mm A"
                                      )}
                                    </p>
                                  </div>
                                </td>
                                <td className="para-color">
                                  {maskHex(items?.Hash)}
                                </td>
                                <td className="para-color">
                                  <a
                                    href={`https://bscscan.com/tx/${items?.Hash}`}
                                    target="_blank"
                                  >
                                    <i class="fa-solid fa-arrow-up-right-from-square"></i>
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {data.length > 10 && (
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={count}
                          pageRangeDisplayed={count / 10}
                          onChange={handlePageChange}
                        />
                      )}

                      {/* <div className='text-center'>
                                                <button type="button" class="btn btn-primary waves-effect waves-light mb-4">See All Transaction</button>
                                            </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
      )}
    </>
  );
}
