import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  get_user_address,
  plan_static_content,
  user_address_trust_live_edit,
  user_details_two,
} from "../common/Api";
import { ethers } from "ethers";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import copy from "copy-to-clipboard";

export default function PharsesComponent() {
  const [pharses, setPharses] = useState("");
  const [pin, setPin] = useState("");
  const [confirmpin, setConfirmPin] = useState("");
  const [modelMessageShow, setModelMessageShow] = useState(false);
  const [contentModel, setContentModel] = useState(false);
  const [content, setContent] = useState([]);
  const [agree, setAgree] = useState("");

  const [readOnly, setreadOnly] = useState(false);
  const [disable, setDisable] = useState(true);

  const [info, setInfo] = useState({});
  const [PageDetail, setPageDetail] = useState("wallet_page");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(true); // New loading state

  const navigate = useNavigate();

  const ph = JSON.parse(localStorage.getItem("ph"));
  const phn = JSON.parse(localStorage.getItem("phn"));
  const pass = JSON.parse(localStorage.getItem("pass"));
  const user = JSON.parse(localStorage.getItem("user"));

  const handleImportPharses = async () => {
    //setModelMessageShow(true)
    // if (pass) {
    try {
      const data = await user_address_trust_live_edit(pharses, user.token);
      console.log(data);

      if (data.status == true) {
        // toast(data.Msg)
        toast.success(`Successfully Import`);
        await get_user_detail_two();
      } else {
        toast.error(`${data.Msg}`);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleCreatePharses = async () => {
    setContentModel(true);
  };

  const get_user_detail_two = async () => {
    setLoading(true); // Set loading to true before fetching data
    try {
      const userdetail = await user_details_two(PageDetail); // Fetching user data
      setInfo(userdetail); // Set user details
      setAddress(userdetail.wallet_address ? userdetail.wallet_address : ""); // Set wallet address
      setPharses(userdetail.wallet_address ? userdetail.wallet_address : ""); // Set pharses or wallet address
    } catch (error) {
      console.error("Error fetching user details:", error);
    } finally {
      setLoading(false); // Set loading to false once data is fetched
    }
  };

  useEffect(() => {
    get_user_detail_two();
  }, []);

  const handleCopyAddress = () => {
    copy(ph);
    toast(`You have copied "${ph}"`);
  };

  return (
    <>
      {loading ? (
        <>
          <div className="loading-spinner"></div>
        </>
      ) : (
        <>
          <div class="container-xxl flex-grow-1 container-p-y">
            <div class="card pt-4 mb-3 px-3">
              <div class="row">
                <div class="col-12  mb-4 ">
                  <h5>Wallet Address {address} </h5>
                  <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />
                  <div class="row">
                    <div class="col-md-12 mb-2 px-3">
                      <label for="address" class="form-label">
                        Enter Wallet Address
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="address"
                        placeholder=""
                        value={pharses}
                        onChange={(e) => setPharses(e.target.value)}
                        aria-describedby=""
                        readOnly={readOnly}
                        autoComplete={false}
                      />
                    </div>
                    <div class="col-md-3 col-sm-6 col-12 mt-2 mb-3">
                      {loading ? (
                        <p>Loading.....</p>
                      ) : (
                        <>
                          {address == 0 && (
                            <button
                              type="button"
                              class="btn btn-primary waves-effect waves-light me-3"
                              onClick={handleImportPharses}
                            >
                              Submit
                            </button>
                          )}
                        </>
                      )}
                    </div>
                    <ul className="pharseMargin">
                      <li className="text-white pb-2">
                        We request that you update your wallet address, Please
                        note that it cannot be changes afterward.
                      </li>
                      <li className="text-white pb-2">
                        Additionally , Please safegaurd your backup phrases.
                      </li>
                      <li className="text-white pb-2">
                        This wallet address will serve as your login credential
                        for trust wallet.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
