import React, { useEffect, useState } from "react";
import lodinggif from "../admin_assets/assets/gif/loadwalk.gif";
import { ClaiAamounts, referral_details, TradeCLaim } from "../common/Api";
import copy from "copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";

export default function ClaimReward() {
  const [loading, setLoading] = useState(true);
  const [withdrawalloading, setWithdrawalloading] = useState(false);
  const [pendingclaim, setPendingClaim] = useState("");
  const [amount, setAmount] = useState("");
  const [TotalClaimAmount, setTotalClaimAmount] = useState("");
  const [TotalReward, setTotalReward] = useState("");
  const [data, setData] = useState([]);
  const [referal, setReferal] = useState("1VG3FG");
  const get_referral_details = async () => {
    try {
      const data = await ClaiAamounts();
      setData(data.MonthlyRewards);
      setData(data.MonthlyRewards);
      setPendingClaim(data.PendingClaim);
      setTotalClaimAmount(data.TotalClaimAmount);
      setTotalReward(data.reward);
    } catch (error) {
      toast.error(`Api not load!`);
    }

    setLoading(false);
  };
  useEffect(() => {
    get_referral_details();
  }, []);

  const handleTradeClaim = async (types) => {
    setWithdrawalloading(true);
    try {
      if (amount == "") {
        setWithdrawalloading(false);
        return toast.error("Please Anter Amount");
      }

      if (amount > pendingclaim) {
        setWithdrawalloading(false);
        return toast.error("Insufficent Balance");
      }

      const submitada = {
        Amount: amount,
        type: types,
      };
      const data = await TradeCLaim(submitada);
      if (data.status == "false") {
        setWithdrawalloading(false);
        return toast.error(data.message);
      }
      toast.success(data.message);
      setAmount("");
      setWithdrawalloading(false);
      //console.log(data)
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {loading ? (
        <div className="loading-spinner"></div>
      ) : (
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row">
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />

            <div className="col-xl-12 mb-4 col-lg-12 col-12">
              <div class="card h-100">
                <div className="card-body">
                  <div>
                    <p className="text-white mb-0 pb-1">
                      Total Reward :{" "}
                      <span className="text-success fw-semibold">
                        {TotalReward ? Number(TotalReward).toFixed(2) : "0.00"}{" "}
                        USDT
                      </span>
                    </p>
                    <p className="text-white mb-0 pb-1">
                      Pending Reward :{" "}
                      <span className="text-success fw-semibold">
                        {pendingclaim
                          ? Number(pendingclaim).toFixed(2)
                          : "0.00"}{" "}
                        USDT
                      </span>
                    </p>
                    <p className="text-white mb-0 pb-1">
                      Total Claimed :{" "}
                      <span className="text-success fw-semibold">
                        {TotalClaimAmount
                          ? Number(TotalClaimAmount).toFixed(2)
                          : "0.00"}{" "}
                        USDT
                      </span>
                    </p>
                  </div>

                  <div class=" mt-3 ">
                    <label for="depositamt" class="form-label text-white fs-6">
                      Amount ( USDT )
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="0.00"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </div>
                  <div class="mt-4">
                    {withdrawalloading ? (
                      <p style={{ color: "green" }}>Loading....</p>
                    ) : (
                      <>
                        <button
                          type="button"
                          class="btn btn-primary waves-effect waves-light mr-3"
                          onClick={() => handleTradeClaim(1)}
                        >
                          Compound
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary waves-effect waves-light"
                          onClick={() => handleTradeClaim(2)}
                        >
                          Withdraw
                        </button>
                      </>
                    )}
                  </div>
                  <div className="py-4">
                    <div class="card">
                      <h5 class="card-header text-white">Deposit History</h5>
                      <div class="ClaimRewardTable table-responsive text-nowrap p-0 p-lg-3">
                        <table class="table">
                          <thead className="text-center">
                            <tr class="text-nowrap border-0">
                              <th className="text-white border-bottom-1 border-top-1">
                                S.No.
                              </th>
                              <th className="text-white border-bottom-1 border-top-1">
                                Date
                              </th>
                              <th className="text-white border-bottom-1 border-top-1">
                                Reward
                              </th>
                            </tr>
                          </thead>
                          <tbody class="border-0">
                            {data.map((items, i) => {
                              return (
                                <tr className="text-center" key={i}>
                                  <td className="para-color">{i + 1}</td>
                                  <td className="text-center">
                                    <div className="para-color">
                                      <span>{items.month} </span>
                                    </div>
                                  </td>
                                  <td className="para-color">
                                    {items.reward
                                      ? Number(items.reward).toFixed(2)
                                      : "0.00"}{" "}
                                    USDT
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
