import React from "react";
import DashboardComponents from "../components/DashboardComponents";
import "../admin_assets/assets/css/demo.css";
import "../admin_assets/assets/css/style.css";
import "../admin_assets/assets/vendor/css/rtl/core-dark.css";
import "../admin_assets/assets/vendor/css/rtl/theme-default-dark.css";
import "../admin_assets/assets/vendor/libs/node-waves/node-waves.css";
import "../admin_assets/assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css";
import "../admin_assets/assets/vendor/libs/typeahead-js/typeahead.css";
import "../admin_assets/assets/vendor/libs/apex-charts/apex-charts.css";
import "../admin_assets/assets/vendor/libs/swiper/swiper.css";
import "../admin_assets/assets/vendor/libs/datatables-bs5/datatables.bootstrap5.css";
import "../admin_assets/assets/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.css";
import "../admin_assets/assets/vendor/libs/datatables-checkboxes-jquery/datatables.checkboxes.css";
import "../admin_assets/assets/vendor/css/pages/cards-advance.css";
import "../admin_assets/assets/vendor/fonts/tabler/tabler-icons.eot";
import "../admin_assets/assets/vendor/fonts/tabler/tabler-icons.ttf";
import "../admin_assets/assets/vendor/fonts/tabler/tabler-icons.woff";
import "../admin_assets/assets/vendor/fonts/tabler/tabler-icons.woff2";
import "../admin_assets/assets/vendor/fonts/tabler-icons.css";

export default function DashboardPage() {
  return <DashboardComponents />;
}
