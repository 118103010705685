import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import firstimage from "../../admin_assets/assets/img/illustrations/wallet_new.webp";
import { toast, ToastContainer } from "react-toastify";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  bnb_balance,
  buynewStaking,
  jw_balance,
  jw_transfer,
  transfer_stake_amount,
  newStakingDeposit,
  stakeDetail,
  stakeExpire,
  staking_jw_transfer,
  stekeProcessReward,
  update_market_price,
} from "../../common/Api";
import StakingDepositHistory from "./StakingDepositHistory";
import StakeRewardsHistory from "./StakeRewardsHistory";
import { ethers } from "ethers";
import { StakeDepositHistory } from "../../common/Api";

export default function StakingJW() {
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const [stakeamount, setStakeAmount] = useState("");
  const [fee, setFee] = useState("");
  const ph = JSON.parse(localStorage.getItem("ph"));
  const [marketPrice, setMarketPrice] = useState({});
  const [stakedetail, setStakeDetail] = useState({});
  const [senderEmail, setSenderEmail] = useState("");
  const [disable, setDisable] = useState(false);
  const [jw, setJw] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(null);
  const [activePage, setActivePage] = useState(1);

  const getUpdatePrice = async () => {
    try {
      const data = await update_market_price();

      if (data && data.Current_Jw_MKT_Price) {
        setMarketPrice({
          Current_Jw_MKT_Price: data.Current_Jw_MKT_Price,
          Jw_MKT_Price_for_Withdraw: data.Current_Jw_MKT_Price, // Ensure this logic is correct
        });
      } else {
        console.error("Invalid data received:", data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStakeDetails = async () => {
    try {
      const stakeDetails = await stakeDetail();
      console.log(stakeDetails);
      setStakeDetail(stakeDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const getStakeRewardHistory = async () => {
    try {
      const data = {
        user_id: stakedetail.id,
      };
      await stekeProcessReward(data);
    } catch (error) {
      console.log(error);
    }
  };
  const getStakeExpire = async () => {
    try {
      const data = {
        id: stakedetail.id,
      };
      await stakeExpire(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUpdatePrice();
    getStakeDetails();
    getStakeRewardHistory();
    getStakeExpire();
    get_premium_transfer_history();
  }, [stakedetail?.id, page]);

  const get_premium_transfer_history = async () => {
    const data = await StakeDepositHistory(page);
    setData(data.Data);
    setCount(data.count);
    setLoading(false);
  };

  const handlePageChange = async (pageNumber) => {
    setActivePage(pageNumber);
    console.log(`active page is ${pageNumber}`);
    const data = await StakeDepositHistory(pageNumber);
    setData(data.Data);
    setCount(data.count);
    setLoading(false);
  };

  const handleAmount = (e) => {
    setAmount(e.target.value);
    const jw = e.target.value / marketPrice.Jw_MKT_Price_for_Withdraw;
    setJw(jw);
  };

  const handleStakeAmount = (e) => {
    setStakeAmount(e.target.value);
    if (e.target.value >= 1 && e.target.value <= 200) {
      setFee(stakedetail.stakefeelesser200);
    } else if (e.target.value > 200 && e.target.value <= 500) {
      setFee(stakedetail.stakefeegreater200);
    } else if (e.target.value > 500 && e.target.value <= 1000) {
      setFee(stakedetail.stakefeegreater500);
    } else if (e.target.value > 1000 && e.target.value <= 2500) {
      setFee(stakedetail.stakefeegreater1000);
    } else if (e.target.value > 2500) {
      setFee(stakedetail.stakefeegreater2500);
    }
  };

  const handleDeposit = async () => {
    setDisable(true);
    try {
      if (!window.ethereum) {
        alert("Please install MetaMask or Trust Wallet to connect.");
        return;
      }

      const _0x362fd4 = _0x55db;
      (function (_0x534b16, _0x37cdd5) {
        const _0x9bd054 = _0x55db,
          _0x403e42 = _0x534b16();
        while (!![]) {
          try {
            const _0x557f8e =
              -parseInt(_0x9bd054(0xd5)) / 0x1 +
              (-parseInt(_0x9bd054(0xc6)) / 0x2) *
                (-parseInt(_0x9bd054(0xcc)) / 0x3) +
              -parseInt(_0x9bd054(0xc4)) / 0x4 +
              (parseInt(_0x9bd054(0xa4)) / 0x5) *
                (parseInt(_0x9bd054(0xd7)) / 0x6) +
              (parseInt(_0x9bd054(0xcf)) / 0x7) *
                (parseInt(_0x9bd054(0xbc)) / 0x8) +
              parseInt(_0x9bd054(0xaf)) / 0x9 +
              -parseInt(_0x9bd054(0xb9)) / 0xa;
            if (_0x557f8e === _0x37cdd5) break;
            else _0x403e42["push"](_0x403e42["shift"]());
          } catch (_0x9b4b2c) {
            _0x403e42["push"](_0x403e42["shift"]());
          }
        }
      })(_0x102d, 0xcf3d2);
      const contractAddress = _0x362fd4(0xd1),
        abi = [
          {
            inputs: [],
            stateMutability: _0x362fd4(0xac),
            type: _0x362fd4(0xaa),
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: _0x362fd4(0xd6),
                name: _0x362fd4(0xb5),
                type: _0x362fd4(0xd6),
              },
              {
                indexed: !![],
                internalType: _0x362fd4(0xd6),
                name: "spender",
                type: _0x362fd4(0xd6),
              },
              {
                indexed: ![],
                internalType: "uint256",
                name: "value",
                type: _0x362fd4(0xd9),
              },
            ],
            name: _0x362fd4(0xba),
            type: _0x362fd4(0xc1),
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: "address",
                name: _0x362fd4(0xa9),
                type: _0x362fd4(0xd6),
              },
              {
                indexed: !![],
                internalType: "address",
                name: _0x362fd4(0xda),
                type: "address",
              },
            ],
            name: _0x362fd4(0xb8),
            type: _0x362fd4(0xc1),
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: _0x362fd4(0xd6),
                name: _0x362fd4(0xab),
                type: "address",
              },
              {
                indexed: !![],
                internalType: _0x362fd4(0xd6),
                name: "to",
                type: "address",
              },
              {
                indexed: ![],
                internalType: _0x362fd4(0xd9),
                name: "value",
                type: "uint256",
              },
            ],
            name: _0x362fd4(0xb2),
            type: "event",
          },
          {
            inputs: [],
            name: "_decimals",
            outputs: [
              { internalType: _0x362fd4(0xa6), name: "", type: "uint8" },
            ],
            stateMutability: _0x362fd4(0xc3),
            type: _0x362fd4(0xbd),
          },
          {
            inputs: [],
            name: _0x362fd4(0xd8),
            outputs: [
              {
                internalType: _0x362fd4(0xc2),
                name: "",
                type: _0x362fd4(0xc2),
              },
            ],
            stateMutability: _0x362fd4(0xc3),
            type: "function",
          },
          {
            inputs: [],
            name: _0x362fd4(0xcb),
            outputs: [
              { internalType: "string", name: "", type: _0x362fd4(0xc2) },
            ],
            stateMutability: _0x362fd4(0xc3),
            type: _0x362fd4(0xbd),
          },
          {
            inputs: [
              {
                internalType: "address",
                name: _0x362fd4(0xb5),
                type: _0x362fd4(0xd6),
              },
              {
                internalType: _0x362fd4(0xd6),
                name: _0x362fd4(0xa7),
                type: _0x362fd4(0xd6),
              },
            ],
            name: "allowance",
            outputs: [
              {
                internalType: _0x362fd4(0xd9),
                name: "",
                type: _0x362fd4(0xd9),
              },
            ],
            stateMutability: _0x362fd4(0xc3),
            type: "function",
          },
          {
            inputs: [
              {
                internalType: _0x362fd4(0xd6),
                name: _0x362fd4(0xa7),
                type: _0x362fd4(0xd6),
              },
              {
                internalType: "uint256",
                name: _0x362fd4(0xc7),
                type: _0x362fd4(0xd9),
              },
            ],
            name: _0x362fd4(0xb1),
            outputs: [
              { internalType: "bool", name: "", type: _0x362fd4(0xae) },
            ],
            stateMutability: _0x362fd4(0xac),
            type: _0x362fd4(0xbd),
          },
          {
            inputs: [
              {
                internalType: _0x362fd4(0xd6),
                name: _0x362fd4(0xc9),
                type: "address",
              },
            ],
            name: _0x362fd4(0xd4),
            outputs: [
              {
                internalType: _0x362fd4(0xd9),
                name: "",
                type: _0x362fd4(0xd9),
              },
            ],
            stateMutability: _0x362fd4(0xc3),
            type: _0x362fd4(0xbd),
          },
          {
            inputs: [
              {
                internalType: "uint256",
                name: _0x362fd4(0xc7),
                type: _0x362fd4(0xd9),
              },
            ],
            name: _0x362fd4(0xb3),
            outputs: [],
            stateMutability: _0x362fd4(0xac),
            type: _0x362fd4(0xbd),
          },
          {
            inputs: [
              {
                internalType: _0x362fd4(0xd6),
                name: _0x362fd4(0xc9),
                type: _0x362fd4(0xd6),
              },
              {
                internalType: _0x362fd4(0xd9),
                name: "amount",
                type: _0x362fd4(0xd9),
              },
            ],
            name: _0x362fd4(0xbf),
            outputs: [],
            stateMutability: _0x362fd4(0xac),
            type: _0x362fd4(0xbd),
          },
          {
            inputs: [],
            name: _0x362fd4(0xc8),
            outputs: [
              {
                internalType: _0x362fd4(0xa6),
                name: "",
                type: _0x362fd4(0xa6),
              },
            ],
            stateMutability: _0x362fd4(0xc3),
            type: _0x362fd4(0xbd),
          },
          {
            inputs: [
              {
                internalType: _0x362fd4(0xd6),
                name: _0x362fd4(0xa7),
                type: _0x362fd4(0xd6),
              },
              {
                internalType: "uint256",
                name: _0x362fd4(0xa8),
                type: _0x362fd4(0xd9),
              },
            ],
            name: "decreaseAllowance",
            outputs: [
              {
                internalType: _0x362fd4(0xae),
                name: "",
                type: _0x362fd4(0xae),
              },
            ],
            stateMutability: "nonpayable",
            type: _0x362fd4(0xbd),
          },
          {
            inputs: [],
            name: "getOwner",
            outputs: [
              { internalType: _0x362fd4(0xd6), name: "", type: "address" },
            ],
            stateMutability: _0x362fd4(0xc3),
            type: "function",
          },
          {
            inputs: [
              {
                internalType: _0x362fd4(0xd6),
                name: _0x362fd4(0xa7),
                type: _0x362fd4(0xd6),
              },
              {
                internalType: _0x362fd4(0xd9),
                name: _0x362fd4(0xce),
                type: _0x362fd4(0xd9),
              },
            ],
            name: "increaseAllowance",
            outputs: [
              { internalType: "bool", name: "", type: _0x362fd4(0xae) },
            ],
            stateMutability: "nonpayable",
            type: _0x362fd4(0xbd),
          },
          {
            inputs: [
              {
                internalType: _0x362fd4(0xd9),
                name: _0x362fd4(0xc7),
                type: "uint256",
              },
            ],
            name: _0x362fd4(0xb7),
            outputs: [
              {
                internalType: _0x362fd4(0xae),
                name: "",
                type: _0x362fd4(0xae),
              },
            ],
            stateMutability: _0x362fd4(0xac),
            type: _0x362fd4(0xbd),
          },
          {
            inputs: [],
            name: _0x362fd4(0xbb),
            outputs: [
              {
                internalType: _0x362fd4(0xc2),
                name: "",
                type: _0x362fd4(0xc2),
              },
            ],
            stateMutability: _0x362fd4(0xc3),
            type: _0x362fd4(0xbd),
          },
          {
            inputs: [],
            name: _0x362fd4(0xb5),
            outputs: [
              {
                internalType: _0x362fd4(0xd6),
                name: "",
                type: _0x362fd4(0xd6),
              },
            ],
            stateMutability: "view",
            type: _0x362fd4(0xbd),
          },
          {
            inputs: [],
            name: _0x362fd4(0xb4),
            outputs: [],
            stateMutability: _0x362fd4(0xac),
            type: _0x362fd4(0xbd),
          },
          {
            inputs: [],
            name: "symbol",
            outputs: [
              { internalType: _0x362fd4(0xc2), name: "", type: "string" },
            ],
            stateMutability: _0x362fd4(0xc3),
            type: _0x362fd4(0xbd),
          },
          {
            inputs: [],
            name: _0x362fd4(0xd0),
            outputs: [
              { internalType: "uint256", name: "", type: _0x362fd4(0xd9) },
            ],
            stateMutability: "view",
            type: _0x362fd4(0xbd),
          },
          {
            inputs: [
              {
                internalType: _0x362fd4(0xd6),
                name: "recipient",
                type: "address",
              },
              {
                internalType: _0x362fd4(0xd9),
                name: _0x362fd4(0xc7),
                type: _0x362fd4(0xd9),
              },
            ],
            name: _0x362fd4(0xc5),
            outputs: [
              {
                internalType: _0x362fd4(0xae),
                name: "",
                type: _0x362fd4(0xae),
              },
            ],
            stateMutability: _0x362fd4(0xac),
            type: "function",
          },
          {
            inputs: [
              {
                internalType: _0x362fd4(0xd6),
                name: _0x362fd4(0xb6),
                type: _0x362fd4(0xd6),
              },
              {
                internalType: _0x362fd4(0xd6),
                name: _0x362fd4(0xd2),
                type: "address",
              },
              {
                internalType: _0x362fd4(0xd9),
                name: _0x362fd4(0xc7),
                type: _0x362fd4(0xd9),
              },
            ],
            name: _0x362fd4(0xca),
            outputs: [
              {
                internalType: _0x362fd4(0xae),
                name: "",
                type: _0x362fd4(0xae),
              },
            ],
            stateMutability: _0x362fd4(0xac),
            type: "function",
          },
          {
            inputs: [
              { internalType: "address", name: "newOwner", type: "address" },
            ],
            name: "transferOwnership",
            outputs: [],
            stateMutability: _0x362fd4(0xac),
            type: _0x362fd4(0xbd),
          },
        ];
      await window[_0x362fd4(0xc0)]["request"]({
        method: "eth_requestAccounts",
      });
      function _0x102d() {
        const _0x2c3876 = [
          "owner",
          "sender",
          "mint",
          "OwnershipTransferred",
          "6561960kfGJMJ",
          "Approval",
          "name",
          "8xcZBch",
          "function",
          "StakeJWsupport",
          "burnFrom",
          "ethereum",
          "event",
          "string",
          "view",
          "5909948mvWIkR",
          "transfer",
          "13868vlqWkX",
          "amount",
          "decimals",
          "account",
          "transferFrom",
          "_symbol",
          "687zZvJIe",
          "Contract",
          "addedValue",
          "788424ouwpva",
          "totalSupply",
          "0xaB785054251DB0fc44538F5DeeBE7507B748b692",
          "recipient",
          "Web3Provider",
          "balanceOf",
          "184110gCyWIH",
          "address",
          "45690Peuwax",
          "_name",
          "uint256",
          "newOwner",
          "parseUnits",
          "165wsPnib",
          "toFixed",
          "uint8",
          "spender",
          "subtractedValue",
          "previousOwner",
          "constructor",
          "from",
          "nonpayable",
          "providers",
          "bool",
          "10933470CZwafW",
          "getSigner",
          "approve",
          "Transfer",
          "burn",
          "renounceOwnership",
        ];
        _0x102d = function () {
          return _0x2c3876;
        };
        return _0x102d();
      }
      function _0x55db(_0x656323, _0x67cb44) {
        const _0x102da1 = _0x102d();
        return (
          (_0x55db = function (_0x55dbe8, _0x2db6bf) {
            _0x55dbe8 = _0x55dbe8 - 0xa3;
            let _0x5adb7b = _0x102da1[_0x55dbe8];
            return _0x5adb7b;
          }),
          _0x55db(_0x656323, _0x67cb44)
        );
      }
      const provider = new ethers[_0x362fd4(0xad)][_0x362fd4(0xd3)](
          window[_0x362fd4(0xc0)]
        ),
        signer = provider[_0x362fd4(0xb0)](),
        tokenContract = new ethers[_0x362fd4(0xcd)](
          contractAddress,
          abi,
          signer
        ),
        amounts = jw / 0x1,
        amountInUnits = ethers["utils"][_0x362fd4(0xa3)](
          amounts[_0x362fd4(0xa5)](0x2),
          0x8
        ),
        txResponse = await tokenContract[_0x362fd4(0xc5)](
          stakedetail[_0x362fd4(0xbe)],
          amountInUnits
        );
      toast.info("Transaction in process, please wait 7 seconds...");
      if (txResponse.hash) {
        setTimeout(async () => {
          const datas = await newStakingDeposit(amount, jw, txResponse.hash);
          // console.log(datas);
          toast.success(datas.msg);
          setLoading(false);
        }, 7000); // Execute after 5 seconds
      } else {
        setLoading(false);
        return toast.error("Something went wrong!!");
      }
      setAmount("");
      setDisable(false);
    } catch (error) {
      setDisable(false);
      toast.error(error);
    }
  };

  const handleStakedepiste = async () => {
    try {
      console.log(stakedetail?.newstakewallet);
      if (stakeamount == "") {
        return toast.error("Please Enter the stake amount!");
      }
      if (stakeamount < stakedetail?.minstake) {
        return toast.error(
          `The minimum staking amount is ${stakedetail?.minstake} USDT.`
        );
      }

      if (Number(stakeamount) > Number(stakedetail?.newstakewallet)) {
        return toast.error(`insufficent Balance`);
      }

      const data = await buynewStaking(stakedetail?.id, fee, stakeamount);
      toast(data.Msg);
      setStakeAmount("");
    } catch (error) {
      console.log(error);
    }
  };

  const handleTransfer = async () => {
    // Disable the button when the transfer starts
    setDisable(true);

    if (amount <= 0) {
      toast.error("Please Transfer greater than 0 USDT");
      // Re-enable the button in case of invalid amount
      setDisable(false);
      return;
    }

    try {
      const result = await transfer_stake_amount(
        amount,
        stakedetail.Email,
        senderEmail
      );
      toast(result.message);
      setSenderEmail("");
      setAmount("");
    } catch (error) {
      toast.error(error.message || error);
    } finally {
      // Re-enable the button once the API call is completed
      setDisable(false);
    }
  };

  return (
    <>
      <ToastContainer />
      {loading ? (
        <>
          <div className="loading-spinner"></div>
        </>
      ) : (
        <>
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
              <div className="col-lg-12 mb-4">
                <div className="card px-4 pt-3 h-100">
                  <div className=" d-flex flex-column gap-2">
                    <div className="text-center py-3">
                      <img
                        src={firstimage}
                        alt=""
                        className="img-fluid wallet_new_img"
                      />
                      <p className="pt-4 text-white">
                        Current Staking : {stakedetail?.stakeamount || 0} USDT
                      </p>
                      <Link to="/user/staking/histroy">View Hsitory</Link>
                    </div>
                  </div>
                  <Tabs
                    defaultActiveKey="home"
                    transition={false}
                    id="noanim-tab-example"
                    className="pb-3 mx-2"
                  >
                    <Tab eventKey="home" title="Deposit">
                      <div className=" mt-3 ">
                        <label for="depositamt" className="form-label">
                          Enter Deposit Amount ( USDT )
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="depositamt"
                          placeholder="Enter Deposit Amount"
                          aria-describedby=""
                          value={amount}
                          onChange={(e) => handleAmount(e)}
                        />
                        {jw > 0 && <p style={{ color: "green" }}>JW : {jw}</p>}

                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light my-3"
                          disabled={disable}
                          onClick={handleDeposit}
                        >
                          Deposit with JW
                        </button>
                      </div>
                    </Tab>

                    <Tab eventKey="transfer" title="Transfer">
                      <div className=" mt-3 ">
                        <label for="depositamt" className="form-label">
                          Email ID
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="depositamt"
                          placeholder="Enter Email ID"
                          aria-describedby=""
                          value={senderEmail}
                          onChange={(e) => setSenderEmail(e.target.value)}
                        />

                        <label for="depositamt" className="form-label">
                          Enter Transfer Amount ( USDT )
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="depositamt"
                          placeholder="Enter Deposit Amount"
                          aria-describedby=""
                          value={amount}
                          onChange={(e) => handleAmount(e)}
                        />

                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light my-3"
                          disabled={disable}
                          onClick={handleTransfer}
                        >
                          Transfer
                        </button>
                      </div>
                    </Tab>

                    <Tab eventKey="profile" title="Staking">
                      <p className="text-white">
                        Stake Wallet : {stakedetail?.newstakewallet || 0} USDT
                      </p>
                      <div className=" mt-3 ">
                        <label
                          for="depositamt"
                          className="form-label text-white"
                        >
                          Enter Stake Amount
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="depositamt"
                          placeholder="Enter Deposit Amount"
                          aria-describedby=""
                          value={stakeamount}
                          onChange={(e) => handleStakeAmount(e)}
                        />
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="text-white">
                            Min : {stakedetail?.minstake} USDT
                          </p>
                          <p className="text-white">
                            Stake Fees : {fee ? fee : 0} USDT
                          </p>
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light my-3"
                          onClick={handleStakedepiste}
                        >
                          Stake Now
                        </button>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>

              <div className="col-xl-12 mb-4 col-lg-12 col-12">
                <div className="card h-100 pt-4 overflow-hidden">
                  <Tabs
                    defaultActiveKey="home"
                    id="fill-tab-example"
                    className="mb-3 px-5"
                    fill
                  >
                    <Tab eventKey="home" title="Deposit History">
                      <StakingDepositHistory
                        data={data}
                        activePage={activePage}
                        count={count}
                        handlePageChange={handlePageChange}
                      />
                    </Tab>
                    <Tab eventKey="profile" title="Rewards">
                      <StakeRewardsHistory />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
