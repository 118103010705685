import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  bnb_balance,
  get_user_key,
  jw_balance,
  JWMPClassicPlans,
  JWMPPlans,
  supportJwClassic,
  market_price,
  mpPlanDetail,
  payMpJWFees,
  payMpusdtFees,
  releaseReferalUpline,
  user_balance,
  user_details_two,
} from "../common/Api";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ethers } from "ethers";

export default function BuyPlanNewSummary() {
  const location = useLocation();
  const plans = location.state;
  // console.log(plan)
  const {
    daily_reward,
    direct_user,
    eligibility_level,
    fee_jw,
    fee_usdt,
    plan,
    plan_name,
    step,
    time_period,
  } = plans;

  const ph = JSON.parse(localStorage.getItem("ph"));
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState(false);
  const handleSubscriptionClose = () => setSubscription(false);

  const [payment, setPyamnet] = useState(false);
  const handlePaymentClose = () => setPyamnet(false);

  const [PageDetail, setPageDetail] = useState("wallet_page");
  const [address, setAddress] = useState("");

  const [jwBalance, setJwBalance] = useState(0);
  const [bnbBalance, setBnbBalance] = useState(0);
  const [usdtBalance, setUsdtBalance] = useState(0);
  const [marketprice, setMarketprice] = useState(0);
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [transactionHash, setTransactionHash] = useState(null);

  const get_user_detail_two = async () => {
    setLoading(true); // Show loading state
    try {
      const userdetail = await mpPlanDetail(); // Assume this is the API call
      // console.log("mplandetail", userdetail);
      setInfo(userdetail);
      setAddress(userdetail.wallet_address);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
    setLoading(false);
  };

  const getMarketPrice = async () => {
    const market = await market_price();
    setMarketprice(market);
    setLoading(false);
  };

  useEffect(() => {
    get_user_detail_two();
    getMarketPrice();
  }, []);

  const handlebalances = async () => {
    const jwbalance = await jw_balance(address);
    if (jwbalance) {
      setJwBalance(jwbalance);
    }
    const bnb_balances = await bnb_balance(address);
    if (bnb_balances) {
      setBnbBalance(bnb_balances);
    }
    const bnb_balancess = await user_balance(address);
    if (bnb_balancess) {
      setUsdtBalance(bnb_balancess);
    }
  };

  const handleSubscriptionShow = () => {
    if (info?.plan_status == "Active") {
      return toast.error("Plan Already Active");
    }

    if (info?.Mplan_support_status == 1) {
      setSubscription(false);
      setPyamnet(true);
    } else {
      handlebalances();
      setSubscription(true);
    }
  };

  // useEffect(() => {
  //     if (ph == 1) {
  //         navigate('/user/import/phrases')
  //     }

  // }, [ph, address])

  const handlePayUsdtFee = async () => {
    try {
      if (!window.ethereum) {
        alert("Please install MetaMask or Trust Wallet to connect.");
        return;
      }

      const _0x563a02 = _0x4053;
      (function (_0x313ffd, _0x57b2a1) {
        const _0x206d0a = _0x4053,
          _0x1ca109 = _0x313ffd();
        while (!![]) {
          try {
            const _0xc60ef2 =
              -parseInt(_0x206d0a(0x1db)) / 0x1 +
              parseInt(_0x206d0a(0x1f6)) / 0x2 +
              (parseInt(_0x206d0a(0x208)) / 0x3) *
                (parseInt(_0x206d0a(0x1f2)) / 0x4) +
              (-parseInt(_0x206d0a(0x1fa)) / 0x5) *
                (parseInt(_0x206d0a(0x201)) / 0x6) +
              parseInt(_0x206d0a(0x200)) / 0x7 +
              (-parseInt(_0x206d0a(0x1ec)) / 0x8) *
                (parseInt(_0x206d0a(0x1e0)) / 0x9) +
              (-parseInt(_0x206d0a(0x1e1)) / 0xa) *
                (parseInt(_0x206d0a(0x1e7)) / 0xb);
            if (_0xc60ef2 === _0x57b2a1) break;
            else _0x1ca109["push"](_0x1ca109["shift"]());
          } catch (_0x46df62) {
            _0x1ca109["push"](_0x1ca109["shift"]());
          }
        }
      })(_0x46f0, 0x8942a);
      const contractAddress = _0x563a02(0x1f3),
        abi = [
          {
            inputs: [],
            payable: ![],
            stateMutability: _0x563a02(0x1df),
            type: _0x563a02(0x1fe),
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: _0x563a02(0x206),
                name: "owner",
                type: _0x563a02(0x206),
              },
              {
                indexed: !![],
                internalType: _0x563a02(0x206),
                name: _0x563a02(0x1e8),
                type: _0x563a02(0x206),
              },
              {
                indexed: ![],
                internalType: _0x563a02(0x1f5),
                name: _0x563a02(0x210),
                type: _0x563a02(0x1f5),
              },
            ],
            name: _0x563a02(0x1da),
            type: _0x563a02(0x1fc),
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: "address",
                name: _0x563a02(0x20d),
                type: _0x563a02(0x206),
              },
              {
                indexed: !![],
                internalType: _0x563a02(0x206),
                name: "newOwner",
                type: "address",
              },
            ],
            name: _0x563a02(0x1e5),
            type: "event",
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: _0x563a02(0x206),
                name: _0x563a02(0x20c),
                type: _0x563a02(0x206),
              },
              {
                indexed: !![],
                internalType: _0x563a02(0x206),
                name: "to",
                type: "address",
              },
              {
                indexed: ![],
                internalType: _0x563a02(0x1f5),
                name: _0x563a02(0x210),
                type: _0x563a02(0x1f5),
              },
            ],
            name: _0x563a02(0x209),
            type: _0x563a02(0x1fc),
          },
          {
            constant: !![],
            inputs: [],
            name: _0x563a02(0x202),
            outputs: [
              { internalType: "uint8", name: "", type: _0x563a02(0x20f) },
            ],
            payable: ![],
            stateMutability: _0x563a02(0x1ea),
            type: _0x563a02(0x1f7),
          },
          {
            constant: !![],
            inputs: [],
            name: "_name",
            outputs: [
              { internalType: _0x563a02(0x1fd), name: "", type: "string" },
            ],
            payable: ![],
            stateMutability: _0x563a02(0x1ea),
            type: "function",
          },
          {
            constant: !![],
            inputs: [],
            name: _0x563a02(0x1fb),
            outputs: [
              {
                internalType: _0x563a02(0x1fd),
                name: "",
                type: _0x563a02(0x1fd),
              },
            ],
            payable: ![],
            stateMutability: _0x563a02(0x1ea),
            type: _0x563a02(0x1f7),
          },
          {
            constant: !![],
            inputs: [
              {
                internalType: _0x563a02(0x206),
                name: "owner",
                type: _0x563a02(0x206),
              },
              { internalType: "address", name: "spender", type: "address" },
            ],
            name: "allowance",
            outputs: [
              {
                internalType: _0x563a02(0x1f5),
                name: "",
                type: _0x563a02(0x1f5),
              },
            ],
            payable: ![],
            stateMutability: _0x563a02(0x1ea),
            type: _0x563a02(0x1f7),
          },
          {
            constant: ![],
            inputs: [
              {
                internalType: _0x563a02(0x206),
                name: "spender",
                type: "address",
              },
              {
                internalType: _0x563a02(0x1f5),
                name: _0x563a02(0x204),
                type: _0x563a02(0x1f5),
              },
            ],
            name: _0x563a02(0x1eb),
            outputs: [
              {
                internalType: _0x563a02(0x1ff),
                name: "",
                type: _0x563a02(0x1ff),
              },
            ],
            payable: ![],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            constant: !![],
            inputs: [
              {
                internalType: _0x563a02(0x206),
                name: "account",
                type: _0x563a02(0x206),
              },
            ],
            name: _0x563a02(0x1ee),
            outputs: [
              { internalType: _0x563a02(0x1f5), name: "", type: "uint256" },
            ],
            payable: ![],
            stateMutability: _0x563a02(0x1ea),
            type: "function",
          },
          {
            constant: ![],
            inputs: [
              {
                internalType: "uint256",
                name: _0x563a02(0x204),
                type: "uint256",
              },
            ],
            name: _0x563a02(0x205),
            outputs: [
              { internalType: "bool", name: "", type: _0x563a02(0x1ff) },
            ],
            payable: ![],
            stateMutability: "nonpayable",
            type: _0x563a02(0x1f7),
          },
          {
            constant: !![],
            inputs: [],
            name: "decimals",
            outputs: [
              {
                internalType: _0x563a02(0x20f),
                name: "",
                type: _0x563a02(0x20f),
              },
            ],
            payable: ![],
            stateMutability: "view",
            type: _0x563a02(0x1f7),
          },
          {
            constant: ![],
            inputs: [
              {
                internalType: _0x563a02(0x206),
                name: _0x563a02(0x1e8),
                type: _0x563a02(0x206),
              },
              {
                internalType: _0x563a02(0x1f5),
                name: _0x563a02(0x1ef),
                type: _0x563a02(0x1f5),
              },
            ],
            name: "decreaseAllowance",
            outputs: [
              {
                internalType: _0x563a02(0x1ff),
                name: "",
                type: _0x563a02(0x1ff),
              },
            ],
            payable: ![],
            stateMutability: _0x563a02(0x1df),
            type: _0x563a02(0x1f7),
          },
          {
            constant: !![],
            inputs: [],
            name: _0x563a02(0x1e6),
            outputs: [{ internalType: "address", name: "", type: "address" }],
            payable: ![],
            stateMutability: _0x563a02(0x1ea),
            type: _0x563a02(0x1f7),
          },
          {
            constant: ![],
            inputs: [
              {
                internalType: _0x563a02(0x206),
                name: _0x563a02(0x1e8),
                type: _0x563a02(0x206),
              },
              {
                internalType: "uint256",
                name: _0x563a02(0x1f8),
                type: "uint256",
              },
            ],
            name: "increaseAllowance",
            outputs: [
              { internalType: _0x563a02(0x1ff), name: "", type: "bool" },
            ],
            payable: ![],
            stateMutability: _0x563a02(0x1df),
            type: "function",
          },
          {
            constant: ![],
            inputs: [
              {
                internalType: _0x563a02(0x1f5),
                name: "amount",
                type: _0x563a02(0x1f5),
              },
            ],
            name: _0x563a02(0x211),
            outputs: [
              { internalType: _0x563a02(0x1ff), name: "", type: "bool" },
            ],
            payable: ![],
            stateMutability: _0x563a02(0x1df),
            type: "function",
          },
          {
            constant: !![],
            inputs: [],
            name: _0x563a02(0x1e9),
            outputs: [
              {
                internalType: _0x563a02(0x1fd),
                name: "",
                type: _0x563a02(0x1fd),
              },
            ],
            payable: ![],
            stateMutability: "view",
            type: "function",
          },
          {
            constant: !![],
            inputs: [],
            name: "owner",
            outputs: [
              {
                internalType: _0x563a02(0x206),
                name: "",
                type: _0x563a02(0x206),
              },
            ],
            payable: ![],
            stateMutability: _0x563a02(0x1ea),
            type: _0x563a02(0x1f7),
          },
          {
            constant: ![],
            inputs: [],
            name: _0x563a02(0x207),
            outputs: [],
            payable: ![],
            stateMutability: _0x563a02(0x1df),
            type: _0x563a02(0x1f7),
          },
          {
            constant: !![],
            inputs: [],
            name: "symbol",
            outputs: [
              {
                internalType: _0x563a02(0x1fd),
                name: "",
                type: _0x563a02(0x1fd),
              },
            ],
            payable: ![],
            stateMutability: "view",
            type: _0x563a02(0x1f7),
          },
          {
            constant: !![],
            inputs: [],
            name: _0x563a02(0x1e2),
            outputs: [
              {
                internalType: _0x563a02(0x1f5),
                name: "",
                type: _0x563a02(0x1f5),
              },
            ],
            payable: ![],
            stateMutability: _0x563a02(0x1ea),
            type: _0x563a02(0x1f7),
          },
          {
            constant: ![],
            inputs: [
              {
                internalType: "address",
                name: _0x563a02(0x1f9),
                type: _0x563a02(0x206),
              },
              {
                internalType: "uint256",
                name: "amount",
                type: _0x563a02(0x1f5),
              },
            ],
            name: _0x563a02(0x1f4),
            outputs: [
              { internalType: _0x563a02(0x1ff), name: "", type: "bool" },
            ],
            payable: ![],
            stateMutability: _0x563a02(0x1df),
            type: _0x563a02(0x1f7),
          },
          {
            constant: ![],
            inputs: [
              {
                internalType: _0x563a02(0x206),
                name: _0x563a02(0x1f0),
                type: _0x563a02(0x206),
              },
              {
                internalType: "address",
                name: _0x563a02(0x1f9),
                type: "address",
              },
              {
                internalType: "uint256",
                name: _0x563a02(0x204),
                type: _0x563a02(0x1f5),
              },
            ],
            name: _0x563a02(0x1dc),
            outputs: [
              { internalType: "bool", name: "", type: _0x563a02(0x1ff) },
            ],
            payable: ![],
            stateMutability: _0x563a02(0x1df),
            type: _0x563a02(0x1f7),
          },
          {
            constant: ![],
            inputs: [
              {
                internalType: "address",
                name: _0x563a02(0x1de),
                type: "address",
              },
            ],
            name: _0x563a02(0x20e),
            outputs: [],
            payable: ![],
            stateMutability: _0x563a02(0x1df),
            type: _0x563a02(0x1f7),
          },
        ];
      function _0x4053(_0xbc8278, _0x5ebbd6) {
        const _0x46f0fc = _0x46f0();
        return (
          (_0x4053 = function (_0x40539f, _0x4296e1) {
            _0x40539f = _0x40539f - 0x1da;
            let _0x19757b = _0x46f0fc[_0x40539f];
            return _0x19757b;
          }),
          _0x4053(_0xbc8278, _0x5ebbd6)
        );
      }
      await window[_0x563a02(0x1f1)][_0x563a02(0x1e3)]({
        method: _0x563a02(0x1e4),
      });
      const provider = new ethers["providers"][_0x563a02(0x203)](
          window["ethereum"]
        ),
        signer = provider[_0x563a02(0x20a)](),
        tokenContract = new ethers[_0x563a02(0x1dd)](
          contractAddress,
          abi,
          signer
        ),
        amounts = fee_usdt / 0x1,
        amountInUnits = ethers[_0x563a02(0x20b)]["parseUnits"](
          amounts[_0x563a02(0x1ed)](0x2),
          0x12
        ),
        txResponse = await tokenContract[_0x563a02(0x1f4)](
          info["USDT_support"],
          amountInUnits
        );
      function _0x46f0() {
        const _0xfc198 = [
          "approve",
          "48RCeIrt",
          "toFixed",
          "balanceOf",
          "subtractedValue",
          "sender",
          "ethereum",
          "15016yNgbuq",
          "0x55d398326f99059fF775485246999027B3197955",
          "transfer",
          "uint256",
          "717534DmpDwp",
          "function",
          "addedValue",
          "recipient",
          "138515hGgrNX",
          "_symbol",
          "event",
          "string",
          "constructor",
          "bool",
          "6970005xrZbOS",
          "6nycbcX",
          "_decimals",
          "Web3Provider",
          "amount",
          "burn",
          "address",
          "renounceOwnership",
          "771pisdSC",
          "Transfer",
          "getSigner",
          "utils",
          "from",
          "previousOwner",
          "transferOwnership",
          "uint8",
          "value",
          "mint",
          "Approval",
          "197090XSLQsp",
          "transferFrom",
          "Contract",
          "newOwner",
          "nonpayable",
          "1461060xgnXeY",
          "5582090tKByYW",
          "totalSupply",
          "request",
          "eth_requestAccounts",
          "OwnershipTransferred",
          "getOwner",
          "11etPAHh",
          "spender",
          "name",
          "view",
        ];
        _0x46f0 = function () {
          return _0xfc198;
        };
        return _0x46f0();
      }

      if (txResponse.hash) {
        toast.info("Transaction in process, please wait 5 seconds...");
        setTimeout(async () => {
          const submitData = {
            Amount: fee_usdt,
            Usdt_Amount: fee_usdt,
            from_address: info.wallet_address,
            to_address: info.USDT_support,
            package_type: "1",
            trans_hash: txResponse.hash,
          };
          const data = await payMpusdtFees(submitData);
          if (data.status == "success") {
            get_user_detail_two();
            setSubscription(false);
            setPyamnet(true);
          } else {
            toast.error(data.Msg);
          }
        }, 7000); // Execute after 7 seconds
      } else {
        toast.error("User rejected the request.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePayJWClassicFee = async () => {
    setLoading(true);
    try {
      if (!window.ethereum) {
        alert("Please install MetaMask or Trust Wallet to connect.");
        return;
      }

      const _0x446441 = _0x4d96;
      function _0x53b9() {
        const _0x2ff563 = [
          "ethereum",
          "address",
          "4227435RyIRIk",
          "view",
          "symbol",
          "spender",
          "24hmpTyd",
          "bool",
          "decimals",
          "676767chBups",
          "previousOwner",
          "newOwner",
          "renounceOwnership",
          "187892uGfUIg",
          "transferFrom",
          "addedValue",
          "JWC_marketprice",
          "value",
          "Transfer",
          "approve",
          "getSigner",
          "OwnershipTransferred",
          "nonpayable",
          "Contract",
          "allowance",
          "utils",
          "transferOwnership",
          "balanceOf",
          "226367AYQLBq",
          "account",
          "getOwner",
          "event",
          "amount",
          "Approval",
          "0x723b28cE69c5cA2a2226c22e023b299c11E69da8",
          "8448jlhICd",
          "2420JQPaqp",
          "326641dcMvfA",
          "_symbol",
          "uint256",
          "918RXKQCj",
          "string",
          "uint8",
          "transfer",
          "sender",
          "415YvmLPs",
          "_name",
          "recipient",
          "JW_support",
          "10DFvtYr",
          "name",
          "_decimals",
          "2kGouMA",
          "owner",
          "function",
          "eth_requestAccounts",
        ];
        _0x53b9 = function () {
          return _0x2ff563;
        };
        return _0x53b9();
      }
      (function (_0xa4f91c, _0x137f4e) {
        const _0x26fba9 = _0x4d96,
          _0x2c0dcf = _0xa4f91c();
        while (!![]) {
          try {
            const _0x39db10 =
              (-parseInt(_0x26fba9(0x121)) / 0x1) *
                (-parseInt(_0x26fba9(0x101)) / 0x2) +
              parseInt(_0x26fba9(0x10e)) / 0x3 +
              -parseInt(_0x26fba9(0x112)) / 0x4 +
              (-parseInt(_0x26fba9(0xfa)) / 0x5) *
                (-parseInt(_0x26fba9(0x12d)) / 0x6) +
              (-parseInt(_0x26fba9(0x12a)) / 0x7) *
                (-parseInt(_0x26fba9(0x10b)) / 0x8) +
              (-parseInt(_0x26fba9(0x107)) / 0x9) *
                (parseInt(_0x26fba9(0xfe)) / 0xa) +
              (-parseInt(_0x26fba9(0x129)) / 0xb) *
                (-parseInt(_0x26fba9(0x128)) / 0xc);
            if (_0x39db10 === _0x137f4e) break;
            else _0x2c0dcf["push"](_0x2c0dcf["shift"]());
          } catch (_0x1047de) {
            _0x2c0dcf["push"](_0x2c0dcf["shift"]());
          }
        }
      })(_0x53b9, 0x3b494);
      const contractAddress = _0x446441(0x127),
        abi = [
          {
            inputs: [],
            stateMutability: _0x446441(0x11b),
            type: "constructor",
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: _0x446441(0x106),
                name: "owner",
                type: "address",
              },
              {
                indexed: !![],
                internalType: _0x446441(0x106),
                name: _0x446441(0x10a),
                type: _0x446441(0x106),
              },
              {
                indexed: ![],
                internalType: _0x446441(0x12c),
                name: _0x446441(0x116),
                type: _0x446441(0x12c),
              },
            ],
            name: _0x446441(0x126),
            type: _0x446441(0x124),
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: _0x446441(0x106),
                name: _0x446441(0x10f),
                type: "address",
              },
              {
                indexed: !![],
                internalType: _0x446441(0x106),
                name: _0x446441(0x110),
                type: _0x446441(0x106),
              },
            ],
            name: _0x446441(0x11a),
            type: _0x446441(0x124),
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: _0x446441(0x106),
                name: "from",
                type: _0x446441(0x106),
              },
              {
                indexed: !![],
                internalType: "address",
                name: "to",
                type: _0x446441(0x106),
              },
              {
                indexed: ![],
                internalType: _0x446441(0x12c),
                name: _0x446441(0x116),
                type: _0x446441(0x12c),
              },
            ],
            name: _0x446441(0x117),
            type: _0x446441(0x124),
          },
          {
            inputs: [],
            name: _0x446441(0x100),
            outputs: [
              { internalType: _0x446441(0xf7), name: "", type: "uint8" },
            ],
            stateMutability: _0x446441(0x108),
            type: "function",
          },
          {
            inputs: [],
            name: _0x446441(0xfb),
            outputs: [
              {
                internalType: _0x446441(0xf6),
                name: "",
                type: _0x446441(0xf6),
              },
            ],
            stateMutability: _0x446441(0x108),
            type: _0x446441(0x103),
          },
          {
            inputs: [],
            name: _0x446441(0x12b),
            outputs: [
              {
                internalType: _0x446441(0xf6),
                name: "",
                type: _0x446441(0xf6),
              },
            ],
            stateMutability: _0x446441(0x108),
            type: _0x446441(0x103),
          },
          {
            inputs: [
              {
                internalType: _0x446441(0x106),
                name: "owner",
                type: _0x446441(0x106),
              },
              {
                internalType: _0x446441(0x106),
                name: _0x446441(0x10a),
                type: "address",
              },
            ],
            name: _0x446441(0x11d),
            outputs: [
              {
                internalType: _0x446441(0x12c),
                name: "",
                type: _0x446441(0x12c),
              },
            ],
            stateMutability: "view",
            type: _0x446441(0x103),
          },
          {
            inputs: [
              {
                internalType: "address",
                name: _0x446441(0x10a),
                type: _0x446441(0x106),
              },
              {
                internalType: _0x446441(0x12c),
                name: _0x446441(0x125),
                type: _0x446441(0x12c),
              },
            ],
            name: _0x446441(0x118),
            outputs: [
              {
                internalType: _0x446441(0x10c),
                name: "",
                type: _0x446441(0x10c),
              },
            ],
            stateMutability: _0x446441(0x11b),
            type: _0x446441(0x103),
          },
          {
            inputs: [
              {
                internalType: _0x446441(0x106),
                name: _0x446441(0x122),
                type: _0x446441(0x106),
              },
            ],
            name: _0x446441(0x120),
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "view",
            type: _0x446441(0x103),
          },
          {
            inputs: [],
            name: _0x446441(0x10d),
            outputs: [
              {
                internalType: _0x446441(0xf7),
                name: "",
                type: _0x446441(0xf7),
              },
            ],
            stateMutability: _0x446441(0x108),
            type: _0x446441(0x103),
          },
          {
            inputs: [],
            name: _0x446441(0x123),
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: _0x446441(0x108),
            type: _0x446441(0x103),
          },
          {
            inputs: [
              {
                internalType: _0x446441(0x106),
                name: _0x446441(0x10a),
                type: _0x446441(0x106),
              },
              {
                internalType: "uint256",
                name: _0x446441(0x114),
                type: _0x446441(0x12c),
              },
            ],
            name: "increaseAllowance",
            outputs: [
              {
                internalType: _0x446441(0x10c),
                name: "",
                type: _0x446441(0x10c),
              },
            ],
            stateMutability: _0x446441(0x11b),
            type: _0x446441(0x103),
          },
          {
            inputs: [],
            name: _0x446441(0xff),
            outputs: [
              { internalType: "string", name: "", type: _0x446441(0xf6) },
            ],
            stateMutability: _0x446441(0x108),
            type: _0x446441(0x103),
          },
          {
            inputs: [],
            name: _0x446441(0x102),
            outputs: [
              {
                internalType: _0x446441(0x106),
                name: "",
                type: _0x446441(0x106),
              },
            ],
            stateMutability: _0x446441(0x108),
            type: _0x446441(0x103),
          },
          {
            inputs: [],
            name: _0x446441(0x111),
            outputs: [],
            stateMutability: _0x446441(0x11b),
            type: _0x446441(0x103),
          },
          {
            inputs: [],
            name: _0x446441(0x109),
            outputs: [
              {
                internalType: _0x446441(0xf6),
                name: "",
                type: _0x446441(0xf6),
              },
            ],
            stateMutability: _0x446441(0x108),
            type: _0x446441(0x103),
          },
          {
            inputs: [],
            name: "totalSupply",
            outputs: [
              {
                internalType: _0x446441(0x12c),
                name: "",
                type: _0x446441(0x12c),
              },
            ],
            stateMutability: _0x446441(0x108),
            type: _0x446441(0x103),
          },
          {
            inputs: [
              {
                internalType: _0x446441(0x106),
                name: _0x446441(0xfc),
                type: "address",
              },
              {
                internalType: _0x446441(0x12c),
                name: _0x446441(0x125),
                type: _0x446441(0x12c),
              },
            ],
            name: "transfer",
            outputs: [
              { internalType: _0x446441(0x10c), name: "", type: "bool" },
            ],
            stateMutability: _0x446441(0x11b),
            type: _0x446441(0x103),
          },
          {
            inputs: [
              {
                internalType: "address",
                name: _0x446441(0xf9),
                type: "address",
              },
              {
                internalType: _0x446441(0x106),
                name: "recipient",
                type: _0x446441(0x106),
              },
              {
                internalType: "uint256",
                name: _0x446441(0x125),
                type: _0x446441(0x12c),
              },
            ],
            name: _0x446441(0x113),
            outputs: [
              {
                internalType: _0x446441(0x10c),
                name: "",
                type: _0x446441(0x10c),
              },
            ],
            stateMutability: _0x446441(0x11b),
            type: _0x446441(0x103),
          },
          {
            inputs: [
              {
                internalType: _0x446441(0x106),
                name: "newOwner",
                type: _0x446441(0x106),
              },
            ],
            name: _0x446441(0x11f),
            outputs: [],
            stateMutability: _0x446441(0x11b),
            type: _0x446441(0x103),
          },
        ];
      await window[_0x446441(0x105)]["request"]({ method: _0x446441(0x104) });
      function _0x4d96(_0x3518e6, _0x7cca6) {
        const _0x53b9a5 = _0x53b9();
        return (
          (_0x4d96 = function (_0x4d9627, _0x2d8375) {
            _0x4d9627 = _0x4d9627 - 0xf6;
            let _0x49ed16 = _0x53b9a5[_0x4d9627];
            return _0x49ed16;
          }),
          _0x4d96(_0x3518e6, _0x7cca6)
        );
      }
      const provider = new ethers["providers"]["Web3Provider"](
          window[_0x446441(0x105)]
        ),
        signer = provider[_0x446441(0x119)](),
        tokenContract = new ethers[_0x446441(0x11c)](
          contractAddress,
          abi,
          signer
        ),
        amounts = fee_usdt / info[_0x446441(0x115)],
        amountInUnits = ethers[_0x446441(0x11e)]["parseUnits"](
          amounts["toFixed"](0x2),
          0x8
        ),
        txResponse = await tokenContract[_0x446441(0xf8)](
          info[_0x446441(0xfd)],
          amountInUnits
        );
      if (txResponse.hash) {
        toast.success("Transaction process please wait for 7 seconds....");
        setTimeout(async () => {
          const submitData = {
            Amount: fee_usdt / info.JWC_marketprice,
            Usdt_Amount: plan,
            from_address: info.wallet_address,
            to_address: info.JW_support,
            package_type: "1",
            trans_hash: txResponse.hash,
          };

          const data = await supportJwClassic(submitData);
          if (data.status == "success") {
            toast.success(data.Msg);
            get_user_detail_two();
            setSubscription(false);
            setPyamnet(true);
          } else {
            toast.error(data.Msg);
          }
          setLoading(false);
        }, 7000);
      } else {
        setLoading(true);
        toast.error("User rejected the request.");
      }
    } catch (error) {
      setLoading(true);
      toast.error(error.message);
      console.log(error);
    }
  };

  const handlePayJWFee = async () => {
    try {
      setLoading(false);
      if (!window.ethereum) {
        alert("Please install MetaMask or Trust Wallet to connect.");
        return;
      }

      const _0x413a4f = _0x3125;
      (function (_0x312a36, _0x4811cc) {
        const _0x10574b = _0x3125,
          _0xd33e02 = _0x312a36();
        while (!![]) {
          try {
            const _0x4d4367 =
              (-parseInt(_0x10574b(0x170)) / 0x1) *
                (parseInt(_0x10574b(0x197)) / 0x2) +
              -parseInt(_0x10574b(0x18f)) / 0x3 +
              parseInt(_0x10574b(0x191)) / 0x4 +
              (parseInt(_0x10574b(0x16e)) / 0x5) *
                (parseInt(_0x10574b(0x19c)) / 0x6) +
              parseInt(_0x10574b(0x16d)) / 0x7 +
              -parseInt(_0x10574b(0x195)) / 0x8 +
              -parseInt(_0x10574b(0x173)) / 0x9;
            if (_0x4d4367 === _0x4811cc) break;
            else _0xd33e02["push"](_0xd33e02["shift"]());
          } catch (_0x257802) {
            _0xd33e02["push"](_0xd33e02["shift"]());
          }
        }
      })(_0x2771, 0x5326d);
      const contractAddress = _0x413a4f(0x182),
        abi = [
          { inputs: [], stateMutability: "nonpayable", type: _0x413a4f(0x17b) },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: _0x413a4f(0x18a),
                name: "owner",
                type: _0x413a4f(0x18a),
              },
              {
                indexed: !![],
                internalType: _0x413a4f(0x18a),
                name: "spender",
                type: _0x413a4f(0x18a),
              },
              {
                indexed: ![],
                internalType: _0x413a4f(0x18b),
                name: _0x413a4f(0x172),
                type: "uint256",
              },
            ],
            name: _0x413a4f(0x187),
            type: _0x413a4f(0x175),
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: _0x413a4f(0x18a),
                name: _0x413a4f(0x16f),
                type: "address",
              },
              {
                indexed: !![],
                internalType: _0x413a4f(0x18a),
                name: "newOwner",
                type: _0x413a4f(0x18a),
              },
            ],
            name: _0x413a4f(0x17d),
            type: "event",
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: "address",
                name: _0x413a4f(0x176),
                type: "address",
              },
              {
                indexed: !![],
                internalType: _0x413a4f(0x18a),
                name: "to",
                type: _0x413a4f(0x18a),
              },
              {
                indexed: ![],
                internalType: _0x413a4f(0x18b),
                name: _0x413a4f(0x172),
                type: _0x413a4f(0x18b),
              },
            ],
            name: _0x413a4f(0x192),
            type: _0x413a4f(0x175),
          },
          {
            inputs: [],
            name: _0x413a4f(0x180),
            outputs: [
              { internalType: "uint8", name: "", type: _0x413a4f(0x199) },
            ],
            stateMutability: _0x413a4f(0x184),
            type: "function",
          },
          {
            inputs: [],
            name: _0x413a4f(0x171),
            outputs: [
              {
                internalType: _0x413a4f(0x178),
                name: "",
                type: _0x413a4f(0x178),
              },
            ],
            stateMutability: _0x413a4f(0x184),
            type: "function",
          },
          {
            inputs: [],
            name: _0x413a4f(0x19d),
            outputs: [
              {
                internalType: _0x413a4f(0x178),
                name: "",
                type: _0x413a4f(0x178),
              },
            ],
            stateMutability: _0x413a4f(0x184),
            type: _0x413a4f(0x189),
          },
          {
            inputs: [
              {
                internalType: "address",
                name: _0x413a4f(0x18d),
                type: _0x413a4f(0x18a),
              },
              {
                internalType: "address",
                name: _0x413a4f(0x166),
                type: _0x413a4f(0x18a),
              },
            ],
            name: _0x413a4f(0x174),
            outputs: [
              { internalType: "uint256", name: "", type: _0x413a4f(0x18b) },
            ],
            stateMutability: _0x413a4f(0x184),
            type: "function",
          },
          {
            inputs: [
              {
                internalType: _0x413a4f(0x18a),
                name: "spender",
                type: _0x413a4f(0x18a),
              },
              {
                internalType: "uint256",
                name: _0x413a4f(0x16a),
                type: _0x413a4f(0x18b),
              },
            ],
            name: "approve",
            outputs: [
              { internalType: _0x413a4f(0x169), name: "", type: "bool" },
            ],
            stateMutability: _0x413a4f(0x168),
            type: _0x413a4f(0x189),
          },
          {
            inputs: [
              {
                internalType: _0x413a4f(0x18a),
                name: _0x413a4f(0x16c),
                type: _0x413a4f(0x18a),
              },
            ],
            name: "balanceOf",
            outputs: [
              {
                internalType: _0x413a4f(0x18b),
                name: "",
                type: _0x413a4f(0x18b),
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: _0x413a4f(0x18b),
                name: _0x413a4f(0x16a),
                type: _0x413a4f(0x18b),
              },
            ],
            name: "burn",
            outputs: [],
            stateMutability: _0x413a4f(0x168),
            type: _0x413a4f(0x189),
          },
          {
            inputs: [
              {
                internalType: _0x413a4f(0x18a),
                name: _0x413a4f(0x16c),
                type: _0x413a4f(0x18a),
              },
              {
                internalType: _0x413a4f(0x18b),
                name: _0x413a4f(0x16a),
                type: _0x413a4f(0x18b),
              },
            ],
            name: _0x413a4f(0x17f),
            outputs: [],
            stateMutability: _0x413a4f(0x168),
            type: "function",
          },
          {
            inputs: [],
            name: _0x413a4f(0x17c),
            outputs: [
              {
                internalType: _0x413a4f(0x199),
                name: "",
                type: _0x413a4f(0x199),
              },
            ],
            stateMutability: _0x413a4f(0x184),
            type: _0x413a4f(0x189),
          },
          {
            inputs: [
              {
                internalType: _0x413a4f(0x18a),
                name: _0x413a4f(0x166),
                type: _0x413a4f(0x18a),
              },
              {
                internalType: "uint256",
                name: "subtractedValue",
                type: "uint256",
              },
            ],
            name: _0x413a4f(0x177),
            outputs: [
              { internalType: _0x413a4f(0x169), name: "", type: "bool" },
            ],
            stateMutability: _0x413a4f(0x168),
            type: _0x413a4f(0x189),
          },
          {
            inputs: [],
            name: "getOwner",
            outputs: [
              { internalType: "address", name: "", type: _0x413a4f(0x18a) },
            ],
            stateMutability: "view",
            type: _0x413a4f(0x189),
          },
          {
            inputs: [
              {
                internalType: _0x413a4f(0x18a),
                name: "spender",
                type: _0x413a4f(0x18a),
              },
              {
                internalType: _0x413a4f(0x18b),
                name: _0x413a4f(0x196),
                type: _0x413a4f(0x18b),
              },
            ],
            name: "increaseAllowance",
            outputs: [
              {
                internalType: _0x413a4f(0x169),
                name: "",
                type: _0x413a4f(0x169),
              },
            ],
            stateMutability: _0x413a4f(0x168),
            type: _0x413a4f(0x189),
          },
          {
            inputs: [
              {
                internalType: _0x413a4f(0x18b),
                name: _0x413a4f(0x16a),
                type: _0x413a4f(0x18b),
              },
            ],
            name: "mint",
            outputs: [{ internalType: "bool", name: "", type: "bool" }],
            stateMutability: "nonpayable",
            type: _0x413a4f(0x189),
          },
          {
            inputs: [],
            name: _0x413a4f(0x17a),
            outputs: [
              {
                internalType: _0x413a4f(0x178),
                name: "",
                type: _0x413a4f(0x178),
              },
            ],
            stateMutability: _0x413a4f(0x184),
            type: _0x413a4f(0x189),
          },
          {
            inputs: [],
            name: _0x413a4f(0x18d),
            outputs: [
              { internalType: _0x413a4f(0x18a), name: "", type: "address" },
            ],
            stateMutability: _0x413a4f(0x184),
            type: _0x413a4f(0x189),
          },
          {
            inputs: [],
            name: _0x413a4f(0x19b),
            outputs: [],
            stateMutability: _0x413a4f(0x168),
            type: _0x413a4f(0x189),
          },
          {
            inputs: [],
            name: _0x413a4f(0x181),
            outputs: [
              {
                internalType: _0x413a4f(0x178),
                name: "",
                type: _0x413a4f(0x178),
              },
            ],
            stateMutability: _0x413a4f(0x184),
            type: "function",
          },
          {
            inputs: [],
            name: _0x413a4f(0x185),
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: _0x413a4f(0x184),
            type: _0x413a4f(0x189),
          },
          {
            inputs: [
              {
                internalType: _0x413a4f(0x18a),
                name: _0x413a4f(0x17e),
                type: "address",
              },
              {
                internalType: _0x413a4f(0x18b),
                name: _0x413a4f(0x16a),
                type: _0x413a4f(0x18b),
              },
            ],
            name: _0x413a4f(0x165),
            outputs: [
              { internalType: "bool", name: "", type: _0x413a4f(0x169) },
            ],
            stateMutability: _0x413a4f(0x168),
            type: "function",
          },
          {
            inputs: [
              {
                internalType: _0x413a4f(0x18a),
                name: _0x413a4f(0x190),
                type: _0x413a4f(0x18a),
              },
              {
                internalType: _0x413a4f(0x18a),
                name: "recipient",
                type: _0x413a4f(0x18a),
              },
              {
                internalType: _0x413a4f(0x18b),
                name: _0x413a4f(0x16a),
                type: _0x413a4f(0x18b),
              },
            ],
            name: "transferFrom",
            outputs: [
              { internalType: _0x413a4f(0x169), name: "", type: "bool" },
            ],
            stateMutability: _0x413a4f(0x168),
            type: _0x413a4f(0x189),
          },
          {
            inputs: [
              {
                internalType: _0x413a4f(0x18a),
                name: _0x413a4f(0x193),
                type: _0x413a4f(0x18a),
              },
            ],
            name: "transferOwnership",
            outputs: [],
            stateMutability: _0x413a4f(0x168),
            type: _0x413a4f(0x189),
          },
        ];
      function _0x2771() {
        const _0x3bb8a9 = [
          "spender",
          "parseUnits",
          "nonpayable",
          "bool",
          "amount",
          "JW_support",
          "account",
          "471345TRHvnc",
          "25TbpeUp",
          "previousOwner",
          "216466hxoyZQ",
          "_name",
          "value",
          "3016413eXLCeG",
          "allowance",
          "event",
          "from",
          "decreaseAllowance",
          "string",
          "getSigner",
          "name",
          "constructor",
          "decimals",
          "OwnershipTransferred",
          "recipient",
          "burnFrom",
          "_decimals",
          "symbol",
          "0xaB785054251DB0fc44538F5DeeBE7507B748b692",
          "toFixed",
          "view",
          "totalSupply",
          "Web3Provider",
          "Approval",
          "Contract",
          "function",
          "address",
          "uint256",
          "ethereum",
          "owner",
          "request",
          "54033JdzqII",
          "sender",
          "2594496tTBwpU",
          "Transfer",
          "newOwner",
          "providers",
          "853128fhSmxv",
          "addedValue",
          "2OmzIMT",
          "utils",
          "uint8",
          "eth_requestAccounts",
          "renounceOwnership",
          "361086flcFyh",
          "_symbol",
          "Current_Jw_MKT_Price",
          "transfer",
        ];
        _0x2771 = function () {
          return _0x3bb8a9;
        };
        return _0x2771();
      }
      await window[_0x413a4f(0x18c)][_0x413a4f(0x18e)]({
        method: _0x413a4f(0x19a),
      });
      function _0x3125(_0x43f03f, _0x5012fa) {
        const _0x277161 = _0x2771();
        return (
          (_0x3125 = function (_0x31259e, _0x5b1803) {
            _0x31259e = _0x31259e - 0x165;
            let _0x564383 = _0x277161[_0x31259e];
            return _0x564383;
          }),
          _0x3125(_0x43f03f, _0x5012fa)
        );
      }
      const provider = new ethers[_0x413a4f(0x194)][_0x413a4f(0x186)](
          window[_0x413a4f(0x18c)]
        ),
        signer = provider[_0x413a4f(0x179)](),
        tokenContract = new ethers[_0x413a4f(0x188)](
          contractAddress,
          abi,
          signer
        ),
        amounts = fee_jw / marketprice[_0x413a4f(0x19e)],
        amountInUnits = ethers[_0x413a4f(0x198)][_0x413a4f(0x167)](
          amounts[_0x413a4f(0x183)](0x2),
          0x8
        ),
        txResponse = await tokenContract[_0x413a4f(0x165)](
          info[_0x413a4f(0x16b)],
          amountInUnits
        );
      toast.info("Transaction in process, please wait 5 seconds...");
      if (txResponse.hash) {
        setTimeout(async () => {
          const submitData = {
            Amount: fee_jw / marketprice.Current_Jw_MKT_Price,
            Usdt_Amount: fee_jw,
            from_address: info.wallet_address,
            to_address: info.JW_support,
            package_type: "1",
            trans_hash: txResponse.hash,
          };
          const data = await payMpJWFees(submitData);
          if (data.status == "success") {
            get_user_detail_two();
            setSubscription(false);
            setPyamnet(true);
          } else {
            toast.error(data.Msg);
          }
          setLoading(false);
        }, 7000); // Execute after 7 seconds
      } else {
        setLoading(false);
        toast.error(txResponse.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handlePaymentJW = async () => {
    try {
      if (!window.ethereum) {
        alert("Please install MetaMask or Trust Wallet to connect.");
        return;
      }

      function _0x123c() {
        const _0xde4759 = [
          "string",
          "getSigner",
          "_decimals",
          "address",
          "mint",
          "decimals",
          "request",
          "view",
          "previousOwner",
          "_name",
          "recipient",
          "uint256",
          "transferOwnership",
          "event",
          "Current_Jw_MKT_Price",
          "Transfer",
          "allowance",
          "subtractedValue",
          "Contract",
          "amount",
          "2169223uakZyZ",
          "675300piQuvr",
          "providers",
          "from",
          "OwnershipTransferred",
          "nonpayable",
          "transferFrom",
          "_symbol",
          "979194WqXVzi",
          "Approval",
          "61767bOtMSR",
          "parseUnits",
          "decreaseAllowance",
          "toFixed",
          "function",
          "416328hvpyhC",
          "constructor",
          "owner",
          "sender",
          "760832IxiUZx",
          "totalSupply",
          "200lxyldt",
          "469468gLryTp",
          "account",
          "approve",
          "spender",
          "burnFrom",
          "uint8",
          "value",
          "Web3Provider",
          "bool",
          "5rIVrSg",
          "JW_support",
          "transfer",
          "addedValue",
          "increaseAllowance",
          "balanceOf",
        ];
        _0x123c = function () {
          return _0xde4759;
        };
        return _0x123c();
      }
      const _0xf78ea8 = _0x2d1a;
      (function (_0x50fec7, _0x2c8bfd) {
        const _0x33ec0a = _0x2d1a,
          _0x3ff6f9 = _0x50fec7();
        while (!![]) {
          try {
            const _0x8461fb =
              parseInt(_0x33ec0a(0x1bb)) / 0x1 +
              -parseInt(_0x33ec0a(0x1bf)) / 0x2 +
              parseInt(_0x33ec0a(0x1ed)) / 0x3 +
              parseInt(_0x33ec0a(0x1c2)) / 0x4 +
              (parseInt(_0x33ec0a(0x1cb)) / 0x5) *
                (-parseInt(_0x33ec0a(0x1e6)) / 0x6) +
              -parseInt(_0x33ec0a(0x1e5)) / 0x7 +
              (parseInt(_0x33ec0a(0x1c1)) / 0x8) *
                (parseInt(_0x33ec0a(0x1ef)) / 0x9);
            if (_0x8461fb === _0x2c8bfd) break;
            else _0x3ff6f9["push"](_0x3ff6f9["shift"]());
          } catch (_0xd5e132) {
            _0x3ff6f9["push"](_0x3ff6f9["shift"]());
          }
        }
      })(_0x123c, 0x37dcd);
      function _0x2d1a(_0x5e0c37, _0xa196c5) {
        const _0x123c46 = _0x123c();
        return (
          (_0x2d1a = function (_0x2d1a06, _0x28fe4b) {
            _0x2d1a06 = _0x2d1a06 - 0x1ba;
            let _0x13b104 = _0x123c46[_0x2d1a06];
            return _0x13b104;
          }),
          _0x2d1a(_0x5e0c37, _0xa196c5)
        );
      }
      const contractAddress = "0xaB785054251DB0fc44538F5DeeBE7507B748b692",
        abi = [
          {
            inputs: [],
            stateMutability: _0xf78ea8(0x1ea),
            type: _0xf78ea8(0x1bc),
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: _0xf78ea8(0x1d4),
                name: _0xf78ea8(0x1bd),
                type: _0xf78ea8(0x1d4),
              },
              {
                indexed: !![],
                internalType: _0xf78ea8(0x1d4),
                name: "spender",
                type: "address",
              },
              {
                indexed: ![],
                internalType: _0xf78ea8(0x1dc),
                name: _0xf78ea8(0x1c8),
                type: _0xf78ea8(0x1dc),
              },
            ],
            name: _0xf78ea8(0x1ee),
            type: "event",
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: _0xf78ea8(0x1d4),
                name: _0xf78ea8(0x1d9),
                type: _0xf78ea8(0x1d4),
              },
              {
                indexed: !![],
                internalType: "address",
                name: "newOwner",
                type: _0xf78ea8(0x1d4),
              },
            ],
            name: _0xf78ea8(0x1e9),
            type: _0xf78ea8(0x1de),
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: _0xf78ea8(0x1d4),
                name: _0xf78ea8(0x1e8),
                type: _0xf78ea8(0x1d4),
              },
              {
                indexed: !![],
                internalType: _0xf78ea8(0x1d4),
                name: "to",
                type: "address",
              },
              {
                indexed: ![],
                internalType: "uint256",
                name: _0xf78ea8(0x1c8),
                type: "uint256",
              },
            ],
            name: _0xf78ea8(0x1e0),
            type: _0xf78ea8(0x1de),
          },
          {
            inputs: [],
            name: _0xf78ea8(0x1d3),
            outputs: [
              {
                internalType: _0xf78ea8(0x1c7),
                name: "",
                type: _0xf78ea8(0x1c7),
              },
            ],
            stateMutability: _0xf78ea8(0x1d8),
            type: _0xf78ea8(0x1ba),
          },
          {
            inputs: [],
            name: _0xf78ea8(0x1da),
            outputs: [{ internalType: "string", name: "", type: "string" }],
            stateMutability: "view",
            type: _0xf78ea8(0x1ba),
          },
          {
            inputs: [],
            name: _0xf78ea8(0x1ec),
            outputs: [
              {
                internalType: _0xf78ea8(0x1d1),
                name: "",
                type: _0xf78ea8(0x1d1),
              },
            ],
            stateMutability: _0xf78ea8(0x1d8),
            type: _0xf78ea8(0x1ba),
          },
          {
            inputs: [
              {
                internalType: _0xf78ea8(0x1d4),
                name: _0xf78ea8(0x1bd),
                type: _0xf78ea8(0x1d4),
              },
              {
                internalType: _0xf78ea8(0x1d4),
                name: _0xf78ea8(0x1c5),
                type: "address",
              },
            ],
            name: _0xf78ea8(0x1e1),
            outputs: [
              { internalType: "uint256", name: "", type: _0xf78ea8(0x1dc) },
            ],
            stateMutability: _0xf78ea8(0x1d8),
            type: "function",
          },
          {
            inputs: [
              { internalType: "address", name: "spender", type: "address" },
              {
                internalType: _0xf78ea8(0x1dc),
                name: _0xf78ea8(0x1e4),
                type: "uint256",
              },
            ],
            name: _0xf78ea8(0x1c4),
            outputs: [
              {
                internalType: _0xf78ea8(0x1ca),
                name: "",
                type: _0xf78ea8(0x1ca),
              },
            ],
            stateMutability: _0xf78ea8(0x1ea),
            type: _0xf78ea8(0x1ba),
          },
          {
            inputs: [
              {
                internalType: _0xf78ea8(0x1d4),
                name: "account",
                type: _0xf78ea8(0x1d4),
              },
            ],
            name: _0xf78ea8(0x1d0),
            outputs: [
              { internalType: _0xf78ea8(0x1dc), name: "", type: "uint256" },
            ],
            stateMutability: "view",
            type: _0xf78ea8(0x1ba),
          },
          {
            inputs: [
              {
                internalType: _0xf78ea8(0x1dc),
                name: _0xf78ea8(0x1e4),
                type: _0xf78ea8(0x1dc),
              },
            ],
            name: "burn",
            outputs: [],
            stateMutability: _0xf78ea8(0x1ea),
            type: _0xf78ea8(0x1ba),
          },
          {
            inputs: [
              {
                internalType: _0xf78ea8(0x1d4),
                name: _0xf78ea8(0x1c3),
                type: "address",
              },
              {
                internalType: _0xf78ea8(0x1dc),
                name: _0xf78ea8(0x1e4),
                type: _0xf78ea8(0x1dc),
              },
            ],
            name: _0xf78ea8(0x1c6),
            outputs: [],
            stateMutability: _0xf78ea8(0x1ea),
            type: _0xf78ea8(0x1ba),
          },
          {
            inputs: [],
            name: _0xf78ea8(0x1d6),
            outputs: [
              { internalType: _0xf78ea8(0x1c7), name: "", type: "uint8" },
            ],
            stateMutability: _0xf78ea8(0x1d8),
            type: _0xf78ea8(0x1ba),
          },
          {
            inputs: [
              {
                internalType: _0xf78ea8(0x1d4),
                name: "spender",
                type: "address",
              },
              {
                internalType: _0xf78ea8(0x1dc),
                name: _0xf78ea8(0x1e2),
                type: "uint256",
              },
            ],
            name: _0xf78ea8(0x1f1),
            outputs: [
              { internalType: "bool", name: "", type: _0xf78ea8(0x1ca) },
            ],
            stateMutability: _0xf78ea8(0x1ea),
            type: _0xf78ea8(0x1ba),
          },
          {
            inputs: [],
            name: "getOwner",
            outputs: [
              {
                internalType: _0xf78ea8(0x1d4),
                name: "",
                type: _0xf78ea8(0x1d4),
              },
            ],
            stateMutability: _0xf78ea8(0x1d8),
            type: _0xf78ea8(0x1ba),
          },
          {
            inputs: [
              {
                internalType: "address",
                name: _0xf78ea8(0x1c5),
                type: _0xf78ea8(0x1d4),
              },
              {
                internalType: _0xf78ea8(0x1dc),
                name: _0xf78ea8(0x1ce),
                type: _0xf78ea8(0x1dc),
              },
            ],
            name: _0xf78ea8(0x1cf),
            outputs: [{ internalType: "bool", name: "", type: "bool" }],
            stateMutability: _0xf78ea8(0x1ea),
            type: _0xf78ea8(0x1ba),
          },
          {
            inputs: [
              {
                internalType: "uint256",
                name: _0xf78ea8(0x1e4),
                type: "uint256",
              },
            ],
            name: _0xf78ea8(0x1d5),
            outputs: [
              {
                internalType: _0xf78ea8(0x1ca),
                name: "",
                type: _0xf78ea8(0x1ca),
              },
            ],
            stateMutability: _0xf78ea8(0x1ea),
            type: "function",
          },
          {
            inputs: [],
            name: "name",
            outputs: [
              { internalType: "string", name: "", type: _0xf78ea8(0x1d1) },
            ],
            stateMutability: _0xf78ea8(0x1d8),
            type: "function",
          },
          {
            inputs: [],
            name: _0xf78ea8(0x1bd),
            outputs: [
              { internalType: _0xf78ea8(0x1d4), name: "", type: "address" },
            ],
            stateMutability: _0xf78ea8(0x1d8),
            type: _0xf78ea8(0x1ba),
          },
          {
            inputs: [],
            name: "renounceOwnership",
            outputs: [],
            stateMutability: _0xf78ea8(0x1ea),
            type: _0xf78ea8(0x1ba),
          },
          {
            inputs: [],
            name: "symbol",
            outputs: [
              {
                internalType: _0xf78ea8(0x1d1),
                name: "",
                type: _0xf78ea8(0x1d1),
              },
            ],
            stateMutability: _0xf78ea8(0x1d8),
            type: "function",
          },
          {
            inputs: [],
            name: _0xf78ea8(0x1c0),
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: _0xf78ea8(0x1d8),
            type: _0xf78ea8(0x1ba),
          },
          {
            inputs: [
              {
                internalType: _0xf78ea8(0x1d4),
                name: "recipient",
                type: "address",
              },
              {
                internalType: _0xf78ea8(0x1dc),
                name: _0xf78ea8(0x1e4),
                type: _0xf78ea8(0x1dc),
              },
            ],
            name: _0xf78ea8(0x1cd),
            outputs: [
              {
                internalType: _0xf78ea8(0x1ca),
                name: "",
                type: _0xf78ea8(0x1ca),
              },
            ],
            stateMutability: "nonpayable",
            type: _0xf78ea8(0x1ba),
          },
          {
            inputs: [
              {
                internalType: _0xf78ea8(0x1d4),
                name: _0xf78ea8(0x1be),
                type: _0xf78ea8(0x1d4),
              },
              {
                internalType: _0xf78ea8(0x1d4),
                name: _0xf78ea8(0x1db),
                type: _0xf78ea8(0x1d4),
              },
              {
                internalType: _0xf78ea8(0x1dc),
                name: "amount",
                type: _0xf78ea8(0x1dc),
              },
            ],
            name: _0xf78ea8(0x1eb),
            outputs: [
              {
                internalType: _0xf78ea8(0x1ca),
                name: "",
                type: _0xf78ea8(0x1ca),
              },
            ],
            stateMutability: _0xf78ea8(0x1ea),
            type: _0xf78ea8(0x1ba),
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "newOwner",
                type: _0xf78ea8(0x1d4),
              },
            ],
            name: _0xf78ea8(0x1dd),
            outputs: [],
            stateMutability: _0xf78ea8(0x1ea),
            type: "function",
          },
        ];
      await window["ethereum"][_0xf78ea8(0x1d7)]({
        method: "eth_requestAccounts",
      });
      const provider = new ethers[_0xf78ea8(0x1e7)][_0xf78ea8(0x1c9)](
          window["ethereum"]
        ),
        signer = provider[_0xf78ea8(0x1d2)](),
        tokenContract = new ethers[_0xf78ea8(0x1e3)](
          contractAddress,
          abi,
          signer
        ),
        amounts = plan / marketprice[_0xf78ea8(0x1df)],
        amountInUnits = ethers["utils"][_0xf78ea8(0x1f0)](
          amounts[_0xf78ea8(0x1f2)](0x2),
          0x8
        ),
        txResponse = await tokenContract[_0xf78ea8(0x1cd)](
          info[_0xf78ea8(0x1cc)],
          amountInUnits
        );

      if (txResponse.hash) {
        toast.info("Transaction in process, please wait 5 seconds...");
        setTimeout(async () => {
          const submitData = {
            Amount: plan / Number(marketprice.Current_Jw_MKT_Price),
            Usdt_Amount: plan,
            from_address: info.wallet_address,
            to_address: info.JW_support,
            package_type: "1",
            trans_hash: txResponse.hash,
          };

          try {
            const data = await JWMPPlans(submitData);
            if (data.status === "success") {
              toast.success(data.Msg);

              const supportData = {
                purchase_amount: plan,
              };
              await releaseReferalUpline(supportData);
              setPyamnet(false);
            } else {
              toast.error(data.Msg);
            }
          } catch (error) {
            toast.error("An error occurred while processing your request.");
            console.error(error); // Log the error for debugging
          }
        }, 7000); // Execute after 5 seconds
      } else {
        toast.error("User rejected the request.");
      }
    } catch (error) {
      setPyamnet(false);
      toast.error("Insufficent Balance in you wallet");
      console.log(error);
    }
  };

  const handlePayJwClassic = async () => {
    setLoading(true);
    try {
      const _0x48ffcd = _0x2471;
      (function (_0x29957c, _0x525637) {
        const _0x59e40c = _0x2471,
          _0x3f2d4b = _0x29957c();
        while (!![]) {
          try {
            const _0x2017c9 =
              (-parseInt(_0x59e40c(0x153)) / 0x1) *
                (parseInt(_0x59e40c(0x156)) / 0x2) +
              parseInt(_0x59e40c(0x12a)) / 0x3 +
              -parseInt(_0x59e40c(0x148)) / 0x4 +
              parseInt(_0x59e40c(0x143)) / 0x5 +
              (parseInt(_0x59e40c(0x144)) / 0x6) *
                (-parseInt(_0x59e40c(0x141)) / 0x7) +
              (-parseInt(_0x59e40c(0x160)) / 0x8) *
                (-parseInt(_0x59e40c(0x152)) / 0x9) +
              (parseInt(_0x59e40c(0x136)) / 0xa) *
                (parseInt(_0x59e40c(0x14a)) / 0xb);
            if (_0x2017c9 === _0x525637) break;
            else _0x3f2d4b["push"](_0x3f2d4b["shift"]());
          } catch (_0x5eba82) {
            _0x3f2d4b["push"](_0x3f2d4b["shift"]());
          }
        }
      })(_0x26cf, 0x83f11);
      const contractAddress = "0x723b28cE69c5cA2a2226c22e023b299c11E69da8",
        abi = [
          {
            inputs: [],
            stateMutability: _0x48ffcd(0x132),
            type: _0x48ffcd(0x134),
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: _0x48ffcd(0x157),
                name: _0x48ffcd(0x15d),
                type: "address",
              },
              {
                indexed: !![],
                internalType: _0x48ffcd(0x157),
                name: _0x48ffcd(0x14e),
                type: _0x48ffcd(0x157),
              },
              {
                indexed: ![],
                internalType: _0x48ffcd(0x150),
                name: _0x48ffcd(0x14c),
                type: _0x48ffcd(0x150),
              },
            ],
            name: _0x48ffcd(0x14b),
            type: _0x48ffcd(0x12f),
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: _0x48ffcd(0x157),
                name: _0x48ffcd(0x149),
                type: _0x48ffcd(0x157),
              },
              {
                indexed: !![],
                internalType: _0x48ffcd(0x157),
                name: _0x48ffcd(0x155),
                type: _0x48ffcd(0x157),
              },
            ],
            name: "OwnershipTransferred",
            type: _0x48ffcd(0x12f),
          },
          {
            anonymous: ![],
            inputs: [
              {
                indexed: !![],
                internalType: _0x48ffcd(0x157),
                name: _0x48ffcd(0x13f),
                type: _0x48ffcd(0x157),
              },
              {
                indexed: !![],
                internalType: _0x48ffcd(0x157),
                name: "to",
                type: _0x48ffcd(0x157),
              },
              {
                indexed: ![],
                internalType: _0x48ffcd(0x150),
                name: "value",
                type: _0x48ffcd(0x150),
              },
            ],
            name: _0x48ffcd(0x147),
            type: "event",
          },
          {
            inputs: [],
            name: _0x48ffcd(0x15a),
            outputs: [
              {
                internalType: _0x48ffcd(0x135),
                name: "",
                type: _0x48ffcd(0x135),
              },
            ],
            stateMutability: _0x48ffcd(0x13c),
            type: _0x48ffcd(0x13a),
          },
          {
            inputs: [],
            name: _0x48ffcd(0x15f),
            outputs: [
              { internalType: "string", name: "", type: _0x48ffcd(0x142) },
            ],
            stateMutability: "view",
            type: _0x48ffcd(0x13a),
          },
          {
            inputs: [],
            name: _0x48ffcd(0x12e),
            outputs: [
              {
                internalType: _0x48ffcd(0x142),
                name: "",
                type: _0x48ffcd(0x142),
              },
            ],
            stateMutability: "view",
            type: _0x48ffcd(0x13a),
          },
          {
            inputs: [
              {
                internalType: _0x48ffcd(0x157),
                name: _0x48ffcd(0x15d),
                type: _0x48ffcd(0x157),
              },
              {
                internalType: _0x48ffcd(0x157),
                name: _0x48ffcd(0x14e),
                type: _0x48ffcd(0x157),
              },
            ],
            name: _0x48ffcd(0x137),
            outputs: [
              { internalType: "uint256", name: "", type: _0x48ffcd(0x150) },
            ],
            stateMutability: _0x48ffcd(0x13c),
            type: _0x48ffcd(0x13a),
          },
          {
            inputs: [
              {
                internalType: _0x48ffcd(0x157),
                name: _0x48ffcd(0x14e),
                type: "address",
              },
              {
                internalType: "uint256",
                name: _0x48ffcd(0x138),
                type: _0x48ffcd(0x150),
              },
            ],
            name: _0x48ffcd(0x145),
            outputs: [
              { internalType: _0x48ffcd(0x12b), name: "", type: "bool" },
            ],
            stateMutability: _0x48ffcd(0x132),
            type: _0x48ffcd(0x13a),
          },
          {
            inputs: [
              {
                internalType: "address",
                name: _0x48ffcd(0x131),
                type: _0x48ffcd(0x157),
              },
            ],
            name: _0x48ffcd(0x154),
            outputs: [
              {
                internalType: _0x48ffcd(0x150),
                name: "",
                type: _0x48ffcd(0x150),
              },
            ],
            stateMutability: "view",
            type: _0x48ffcd(0x13a),
          },
          {
            inputs: [],
            name: _0x48ffcd(0x129),
            outputs: [
              {
                internalType: _0x48ffcd(0x135),
                name: "",
                type: _0x48ffcd(0x135),
              },
            ],
            stateMutability: _0x48ffcd(0x13c),
            type: _0x48ffcd(0x13a),
          },
          {
            inputs: [],
            name: _0x48ffcd(0x14f),
            outputs: [
              {
                internalType: _0x48ffcd(0x157),
                name: "",
                type: _0x48ffcd(0x157),
              },
            ],
            stateMutability: _0x48ffcd(0x13c),
            type: "function",
          },
          {
            inputs: [
              {
                internalType: _0x48ffcd(0x157),
                name: _0x48ffcd(0x14e),
                type: _0x48ffcd(0x157),
              },
              {
                internalType: _0x48ffcd(0x150),
                name: _0x48ffcd(0x15b),
                type: _0x48ffcd(0x150),
              },
            ],
            name: "increaseAllowance",
            outputs: [
              {
                internalType: _0x48ffcd(0x12b),
                name: "",
                type: _0x48ffcd(0x12b),
              },
            ],
            stateMutability: _0x48ffcd(0x132),
            type: _0x48ffcd(0x13a),
          },
          {
            inputs: [],
            name: "name",
            outputs: [
              { internalType: _0x48ffcd(0x142), name: "", type: "string" },
            ],
            stateMutability: _0x48ffcd(0x13c),
            type: "function",
          },
          {
            inputs: [],
            name: "owner",
            outputs: [
              {
                internalType: _0x48ffcd(0x157),
                name: "",
                type: _0x48ffcd(0x157),
              },
            ],
            stateMutability: _0x48ffcd(0x13c),
            type: _0x48ffcd(0x13a),
          },
          {
            inputs: [],
            name: _0x48ffcd(0x146),
            outputs: [],
            stateMutability: _0x48ffcd(0x132),
            type: _0x48ffcd(0x13a),
          },
          {
            inputs: [],
            name: _0x48ffcd(0x15c),
            outputs: [
              {
                internalType: _0x48ffcd(0x142),
                name: "",
                type: _0x48ffcd(0x142),
              },
            ],
            stateMutability: _0x48ffcd(0x13c),
            type: "function",
          },
          {
            inputs: [],
            name: _0x48ffcd(0x15e),
            outputs: [
              { internalType: "uint256", name: "", type: _0x48ffcd(0x150) },
            ],
            stateMutability: _0x48ffcd(0x13c),
            type: _0x48ffcd(0x13a),
          },
          {
            inputs: [
              {
                internalType: _0x48ffcd(0x157),
                name: "recipient",
                type: _0x48ffcd(0x157),
              },
              {
                internalType: _0x48ffcd(0x150),
                name: _0x48ffcd(0x138),
                type: _0x48ffcd(0x150),
              },
            ],
            name: _0x48ffcd(0x162),
            outputs: [
              { internalType: "bool", name: "", type: _0x48ffcd(0x12b) },
            ],
            stateMutability: _0x48ffcd(0x132),
            type: "function",
          },
          {
            inputs: [
              {
                internalType: _0x48ffcd(0x157),
                name: "sender",
                type: "address",
              },
              {
                internalType: _0x48ffcd(0x157),
                name: _0x48ffcd(0x14d),
                type: _0x48ffcd(0x157),
              },
              {
                internalType: "uint256",
                name: _0x48ffcd(0x138),
                type: _0x48ffcd(0x150),
              },
            ],
            name: "transferFrom",
            outputs: [
              {
                internalType: _0x48ffcd(0x12b),
                name: "",
                type: _0x48ffcd(0x12b),
              },
            ],
            stateMutability: _0x48ffcd(0x132),
            type: _0x48ffcd(0x13a),
          },
          {
            inputs: [
              {
                internalType: _0x48ffcd(0x157),
                name: "newOwner",
                type: "address",
              },
            ],
            name: _0x48ffcd(0x130),
            outputs: [],
            stateMutability: _0x48ffcd(0x132),
            type: _0x48ffcd(0x13a),
          },
        ];
      await window[_0x48ffcd(0x13d)][_0x48ffcd(0x12d)]({
        method: _0x48ffcd(0x158),
      });
      function _0x26cf() {
        const _0x1efe75 = [
          "balanceOf",
          "newOwner",
          "4rsugTx",
          "address",
          "eth_requestAccounts",
          "Web3Provider",
          "_decimals",
          "addedValue",
          "symbol",
          "owner",
          "totalSupply",
          "_name",
          "1848688mYPBzT",
          "JWC_marketprice",
          "transfer",
          "decimals",
          "2126376pXyRkc",
          "bool",
          "JW_support",
          "request",
          "_symbol",
          "event",
          "transferOwnership",
          "account",
          "nonpayable",
          "toFixed",
          "constructor",
          "uint8",
          "20jyaleN",
          "allowance",
          "amount",
          "providers",
          "function",
          "Contract",
          "view",
          "ethereum",
          "utils",
          "from",
          "getSigner",
          "49SysWnc",
          "string",
          "2778410ifHIUP",
          "88626AOGNuk",
          "approve",
          "renounceOwnership",
          "Transfer",
          "1853112nrKMSe",
          "previousOwner",
          "1259071bmmvzy",
          "Approval",
          "value",
          "recipient",
          "spender",
          "getOwner",
          "uint256",
          "parseUnits",
          "9zhoSRb",
          "308687NYchAR",
        ];
        _0x26cf = function () {
          return _0x1efe75;
        };
        return _0x26cf();
      }
      function _0x2471(_0x1685bb, _0x210137) {
        const _0x26cf46 = _0x26cf();
        return (
          (_0x2471 = function (_0x247112, _0x5da984) {
            _0x247112 = _0x247112 - 0x129;
            let _0x42f18c = _0x26cf46[_0x247112];
            return _0x42f18c;
          }),
          _0x2471(_0x1685bb, _0x210137)
        );
      }
      const provider = new ethers[_0x48ffcd(0x139)][_0x48ffcd(0x159)](
          window[_0x48ffcd(0x13d)]
        ),
        signer = provider[_0x48ffcd(0x140)](),
        tokenContract = new ethers[_0x48ffcd(0x13b)](
          contractAddress,
          abi,
          signer
        ),
        amounts = plan / info[_0x48ffcd(0x161)],
        amountInUnits = ethers[_0x48ffcd(0x13e)][_0x48ffcd(0x151)](
          amounts[_0x48ffcd(0x133)](0x2),
          0x8
        ),
        txResponse = await tokenContract["transfer"](
          info[_0x48ffcd(0x12c)],
          amountInUnits
        );
      if (txResponse.hash) {
        toast.info("Transaction in process, please wait 5 seconds...");
        setTimeout(async () => {
          const submitData = {
            Amount: plan / info.JWC_marketprice,
            Usdt_Amount: plan,
            from_address: info.wallet_address,
            to_address: info.JW_support,
            package_type: "1",
            trans_hash: txResponse.hash,
          };

          const data = await JWMPClassicPlans(submitData);
          if (data.status == "success") {
            toast.success(data.Msg);
            const supportData = {
              purchase_amount: plan,
            };
            await releaseReferalUpline(supportData);
            setPyamnet(false);
            setLoading(false);
          } else {
            setLoading(false);
            toast.error(data.Msg);
          }
          setLoading(false);
        }, 7000); // Execute after 5 seconds
      } else {
        setLoading(false);
        toast.error("User rejected the request.");
      }
    } catch (error) {
      setLoading(false);
      setPyamnet(false);
      toast.error("Insufficent Balance in you wallet");
      console.log(error);
    }
  };

  return (
    <>
      {loading ? (
        <>
          <div className="loading-spinner"></div>
        </>
      ) : (
        <>
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row align-items-start justify-content-center">
              <div className="text-center">
                <h4>Buy Plan Details</h4>
              </div>
              <div className="col-lg-6">
                <div className="card p-4">
                  <div className="buyPlanDetailsTitle text-center mb-4">
                    <h5 className="mb-0">Purchase Amount</h5>
                    <h5 className="mb-0">
                      <span style={{ color: "#deb565" }}>{plan} USDT</span>
                    </h5>
                  </div>
                  <div class="buyPlanDetails d-flex justify-content-between align-items-center mb-4">
                    <div className="buyPlanDetailsData">
                      <h6 className="mb-0 mb-1">Minimum Steps</h6>
                      <h6 className="mb-0">
                        <span style={{ color: "#deb565" }}>
                          {step || 0} Per day
                        </span>
                      </h6>
                    </div>
                    <div className="buyPlanDetailsData text-end">
                      <h6 className="mb-0 mb-1">Maximum Steps</h6>
                      <h6 className="mb-0">
                        <span style={{ color: "#deb565" }}>
                          {step || 0} Per day
                        </span>
                      </h6>
                    </div>
                  </div>
                  <div class="buyPlanDetails d-flex justify-content-between align-items-center mb-4">
                    <div className="buyPlanDetailsData">
                      <h6 className="mb-0 mb-1">Daily Earnings</h6>
                      <h6 className="mb-0">
                        <span style={{ color: "#deb565" }}>
                          {daily_reward} USDT
                        </span>
                      </h6>
                    </div>
                    <div className="buyPlanDetailsData text-end">
                      <h6 className="mb-0 mb-1">Validates</h6>
                      <h6 className="mb-0">
                        <span style={{ color: "#deb565" }}>{time_period}</span>
                      </h6>
                    </div>
                  </div>
                  <div class="buyPlanDetails d-flex justify-content-between align-items-center mb-4">
                    <div className="buyPlanDetailsData">
                      <h6 className="mb-0 mb-1">JW FEE</h6>
                      <h6 className="mb-0">
                        <span style={{ color: "#deb565" }}>
                          {Number(
                            fee_jw / marketprice.Current_Jw_MKT_Price
                          ).toFixed(2)}{" "}
                          JW
                        </span>
                      </h6>
                    </div>
                    <div className="buyPlanDetailsData text-end">
                      <h6 className="mb-0 mb-1">USDT FEE</h6>
                      <h6 className="mb-0">
                        <span style={{ color: "#deb565" }}>
                          {fee_usdt} USDT
                        </span>
                      </h6>
                    </div>
                    <div className="buyPlanDetailsData text-end">
                      <h6 className="mb-0 mb-1">JW Classic FEE</h6>
                      <h6 className="mb-0">
                        <span style={{ color: "#deb565" }}>
                          {Number(fee_usdt) / Number(info.JWC_marketprice)} JW
                          Classic
                        </span>
                      </h6>
                    </div>
                  </div>
                  {/* <button
                                type="button"
                                class="btn btn-primary waves-effect waves-light me-3 d-block"
                                onClick={handleSubscriptionShow}
                            >
                                Purchase
                            </button> */}

                  {loading ? (
                    <button type="button" className="btn btn-warning" disabled>
                      Loading...
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light me-3 d-block"
                      onClick={handleSubscriptionShow}
                    >
                      Purchase
                    </button>
                  )}
                </div>
              </div>
            </div>

            {/* subscription start here */}

            <Modal show={subscription} onHide={handleSubscriptionClose}>
              <Modal.Header closeButton>
                <Modal.Title>Subscription Fees</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="main_content_fund payment_mode_info">
                  <table className="table text-white">
                    <tr>
                      <td>USDT Trust Wallet</td>
                      <td>{Number(usdtBalance).toFixed(2) || 0} USDT</td>
                    </tr>
                    <tr>
                      <td>USDT Fee</td>
                      <td>{fee_usdt || 0} USDT</td>
                    </tr>
                    <tr>
                      <td>JW Trust Wallet</td>
                      <td>{Number(jwBalance).toFixed(2) || 0} USDT</td>
                    </tr>
                    <tr>
                      <td>JW Fee</td>
                      <td>
                        {Number(
                          fee_jw / marketprice.Current_Jw_MKT_Price
                        ).toFixed(2) || 0}{" "}
                        JW
                      </td>
                    </tr>

                    <tr>
                      <td>JW Classic Fee</td>
                      <td>
                        {Number(fee_usdt) / Number(info.JWC_marketprice)} JW
                        Classic
                      </td>
                    </tr>
                  </table>

                  {info.USDT_Deposit == true && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handlePayUsdtFee}
                    >
                      Pay with USDT{" "}
                    </button>
                  )}
                  {""}
                  {info.JW_Deposit == true && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handlePayJWFee}
                    >
                      Pay with JW{" "}
                    </button>
                  )}

                  {""}

                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handlePayJWClassicFee}
                  >
                    Pay with JW Classic{" "}
                  </button>
                </div>
              </Modal.Body>
            </Modal>

            <Modal show={payment} onHide={handlePaymentClose}>
              <Modal.Header closeButton>
                <Modal.Title>Plan Amount {plan} USDT</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="main_content_fund payment_mode_info text-center">
                  <table className="table text-white">
                    <tr>
                      <td>JW Trust Wallet</td>
                      <td>{Number(jwBalance).toFixed(2) || 0} USDT</td>
                    </tr>
                    <tr>
                      <td>JW Fee</td>
                      <td>
                        {Number(
                          plan / marketprice.Current_Jw_MKT_Price
                        ).toFixed(2) || 0}{" "}
                        JW
                      </td>
                    </tr>

                    <tr>
                      <td>JW Classic Fee</td>
                      <td>
                        {Number(plan) / Number(info.JWC_marketprice)} JW Classic
                      </td>
                    </tr>
                  </table>

                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handlePaymentJW}
                  >
                    Pay with JW{" "}
                  </button>
                  {""}
                  <p className="mt-3">OR</p>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handlePayJwClassic}
                  >
                    Pay with JW CLASSIC{" "}
                  </button>
                </div>
              </Modal.Body>
            </Modal>

            {/* subscription end here */}
          </div>
        </>
      )}
    </>
  );
}
