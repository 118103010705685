import React, { useState, useEffect } from "react";
import logo from "../../admin_assets/assets/img/branding/new_applogo.webp";
import { useNavigate, Link } from "react-router-dom";
import { motion } from "framer-motion";

export default function SideBar({ isSidebarOpen, setIsSidebarOpen }) {
  const [show, setShow] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [activeMenu, setActiveMenu] = useState("dashboard"); // Default active menu is 'dashboard'
  const navigate = useNavigate();

  const togglemenu = () => {
    setShow(!show);
  };

  const handleLogout = () => {
    localStorage.clear();
    localStorage.setItem("phn", 1);
    navigate("/");
  };

  const toggleSidebar = () => {
    
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Function to handle menu item click and set active class
  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
    localStorage.setItem("activeMenu", menu); // Store active menu in localStorage

    // Close sidebar on mobile
    if (isMobile) {
      setIsSidebarOpen(false);
    }
  };

  // Check for saved activeMenu in localStorage when the component mounts
  useEffect(() => {
    const savedActiveMenu = localStorage.getItem("activeMenu");
    if (savedActiveMenu) {
      setActiveMenu(savedActiveMenu); // Set the active menu from localStorage
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // or use your desired breakpoint
    };
    handleResize(); // On component mount, check the screen size
    window.addEventListener('resize', handleResize); // Update on resize

    return () => {
      window.removeEventListener('resize', handleResize); // Cleanup event listener
    };
  }, []);

  return (
    <aside
      id="layout-menu"
      className="layout-menu menu-vertical menu bg-menu-theme"
      style={{ width: `${show ? "10px" : "auto"} !important` }}
    >
      <div className="app-brand demo">
        <Link to="/user/dashboard" className="app-brand-link">
          <span className="app-brand-logo demo">
            <img src={logo} width="100%" alt="" />
          </span>
          <span className="app-brand-text demo menu-text fw-bold">JWT</span>
        </Link>

        <a
          href="javascript:void(0);"
          onClick={toggleSidebar}
          className="layout-menu-toggle menu-link text-large ms-auto"
        >
          <i className="ti menu-toggle-icon d-none d-xl-block ti-sm align-middle"></i>
          <i className="ti ti-x d-block d-xl-none ti-sm align-middle"></i>
        </a>
      </div>

      <div className="menu-inner-shadow"></div>
      <motion.div
        className="sidebar"
        initial={{ x: "-100%" }}
        animate={{ x: 0 }}
        transition={{ type: "spring", stiffness: 120 }}
      >
        <ul className="menu-inner py-1">
       

          <li
            className={`menu-item ${activeMenu === "dashboard" ? "active" : ""}`}
            onClick={() => handleMenuClick("dashboard")}
          >
            <Link to="/user/dashboard" className="menu-link menu-toggle">
              {/* <i className="ti ti-dashboard"></i> */}
              <i className="ti ti-home"></i>
              <div data-i18n="Home">Home</div>
            </Link>
          </li>

          <li
            className={`menu-item ${activeMenu === "referal" ? "active" : ""}`}
            onClick={() => handleMenuClick("referal")}
          >
            <Link to="/user/referal" className="menu-link">
              <i className="ti ti-users"></i>
              <div data-i18n="Referral">Referral </div>
            </Link>
          </li>

          <li
            className={`menu-item ${activeMenu === "withdrawal" ? "active" : ""}`}
            onClick={() => handleMenuClick("withdrawal")}
          >
            <Link to="/user/withdrawal" className="menu-link">
              <i className="ti ti-building-bank"></i>
              <div data-i18n="Withdraw">Withdraw</div>
            </Link>
          </li>

          <li
            className={`menu-item ${activeMenu === "transfer" ? "active" : ""}`}
            onClick={() => handleMenuClick("transfer")}
          >
            <Link to="/user/internal/transfer" className="menu-link">
              <i className="ti ti-exchange"></i>
              <div data-i18n="Referral">Internal Transfer</div>
            </Link>
          </li>

          <li
            className={`menu-item ${activeMenu === "wallet" ? "active" : ""}`}
            onClick={() => handleMenuClick("wallet")}
          >
            <Link to="/user/import/phrases" className="menu-link">
              <i className="ti ti-file-import"></i>
              <div data-i18n="Referral">Update Wallet</div>
            </Link>
          </li>

          <li
            className={`menu-item ${activeMenu === "stakingJW" ? "active" : ""}`}
            onClick={() => handleMenuClick("stakingJW")}
          >
            <Link to="/user/staking-jw" className="menu-link">
              <i className="ti ti-wallet"></i>
              <div data-i18n="Staking JW">Staking JW</div>
            </Link>
          </li>

          <li
            className={`menu-item ${activeMenu === "stakingWithdrawal" ? "active" : ""}`}
            onClick={() => handleMenuClick("stakingWithdrawal")}
          >
            <Link to="/user/staking/withdrawal" className="menu-link">
              <i className="ti ti-currency-dollar"></i>
              <div data-i18n="Staking Withdrawal">Staking Withdrawal</div>
            </Link>
          </li>

          <li
            className={`menu-item ${activeMenu === "internalTransferStaking" ? "active" : ""}`}
            onClick={() => handleMenuClick("internalTransferStaking")}
          >
            <Link to="/user/internal/transfer/stake" className="menu-link">
              <i className="ti ti-arrow-left-right"></i>
              <div data-i18n="Internal Transfer Staking">Internal Transfer Staking</div>
            </Link>
          </li>

          <li
            className={`menu-item ${activeMenu === "rollOnRewards" ? "active" : ""}`}
            onClick={() => handleMenuClick("rollOnRewards")}
          >
            <Link to="/user/roll/on/rewards" className="menu-link">
              <i className="ti ti-gift"></i>
              <div data-i18n="Roll On Rewards">Roll On Rewards</div>
            </Link>
          </li>

          <li
            className={`menu-item ${activeMenu === "leadershipBonus" ? "active" : ""}`}
            onClick={() => handleMenuClick("leadershipBonus")}
          >
            <Link to="/user/leadship/bonus" className="menu-link">
              <i className="ti ti-award"></i>
              <div data-i18n="Leadership Bonus">Leadership Bonus</div>
            </Link>
          </li>

          <li
            className={`menu-item ${activeMenu === "premiumWallet" ? "active" : ""}`}
            onClick={() => handleMenuClick("premiumWallet")}
          >
            <Link to="/user/premium/wallet" className="menu-link">
              <i className="ti ti-premium-rights"></i>
              <div data-i18n="Premium Wallet">Premium Wallet</div>
            </Link>
          </li>

          <li
            className={`menu-item ${activeMenu === "trading" ? "active" : ""}`}
            onClick={() => handleMenuClick("trading")}
          >
            <Link to="/user/trade/trading" className="menu-link">
              <i className="ti ti-trending-up"></i>
              <div data-i18n="Trading">Trading</div>
            </Link>
          </li>

          <li
            className={`menu-item ${activeMenu === "tradeWallet" ? "active" : ""}`}
            onClick={() => handleMenuClick("tradeWallet")}
          >
            <Link to="/user/trade/trade-wallet" className="menu-link">
              <i className="ti ti-chart-line"></i>
              <div data-i18n="Trade Wallet">Trade Wallet</div>
            </Link>
          </li>

          <li
            className={`menu-item ${activeMenu === "tradeWithdrawal" ? "active" : ""}`}
            onClick={() => handleMenuClick("tradeWithdrawal")}
          >
            <Link to="/user/trade/trade-withdrawal" className="menu-link">
              <i className="ti ti-wallet"></i>
              <div data-i18n="Trade Withdrawal">Trade Withdrawal</div>
            </Link>
          </li>

          <li
            className={`menu-item ${activeMenu === "security" ? "active" : ""}`}
            onClick={() => handleMenuClick("security")}
          >
            <Link to="/user/security" className="menu-link">
              <i className="ti ti-shield"></i>
              <div data-i18n="Security">Security</div>
            </Link>
          </li>

          <li className="menu-item">
            <a onClick={handleLogout} className="menu-link">
              <i className="ti ti-logout"></i>
              <div data-i18n="Logout">Logout</div>
            </a>
          </li>
        </ul>
      </motion.div>
    </aside>
  );
}
