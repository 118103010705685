import React, { useEffect, useState } from 'react'
import { Link, json, useNavigate } from 'react-router-dom'
import { URL } from '../common/Route';
import { getTestApi, loginApi, newHomepageContent, user_address_trust_live_edit, userWalletLogin } from '../common/Api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import { ethers } from 'ethers';




export default function RegsiterComponent() {

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [code, setCode] = useState('');
    const [user, setUser] = useState([]);
    const [buttonType, setButtonType] = useState(false);
    const [userType, setType] = useState('normaluser');
    const [walletType, setWalletType] = useState('walletuser');

    const [loading, setLoading] = useState(false)
    const [address, setAddress] = useState('');




    const handlelogin = async () => {
        setLoading(true)
        // console.log(email)
        try {
            if (email == '') {
                toast('Email Required')
            } else {
                let origin = 'adduser';
                if (address != '') {
                    origin = 'add_User_new';
                }

                //alert(origin)
                let submitData = {
                    Email: email,
                    Phone_Number: phone,
                    User_Device_id: "",
                    User_type: userType,
                    device_unique_id: "",
                    referal_code: code ? code : 'U8DECP',
                    user_name: name,
                }

                if (address) {
                    submitData = {
                        Email: email,
                        Phone_Number: phone,
                        User_Device_id: "",
                        User_type: walletType,
                        device_unique_id: "",
                        referal_code: code ? code : 'U8DECP',
                        user_name: name,
                        wallet: address
                    }
                }



                const { data } = await axios.post(`${URL}/${origin}/`, submitData)
                console.log(data)
                if (data.status == "false") {
                    localStorage.clear();
                    toast(data.Msg)
                } else {
                    localStorage.clear();
                    toast(data.Msg)
                    localStorage.setItem('user', JSON.stringify(data))
                    localStorage.setItem('ph', 1)
                    if (address) {
                        const user = JSON.parse(localStorage.getItem("user"))
                        const wallet = await user_address_trust_live_edit(address, user.token)
                        console.log(wallet)
                        localStorage.setItem('otp', "true")
                        localStorage.setItem('pin', "true")

                        const version = '6.0.0';
                        const info = await newHomepageContent(user.token, version);
                        const notificationMessage = formatNotificationMessage(info.notification_msg ? info.notification_msg : "<p>Hi sanju</p>");

                        localStorage.setItem('notificationMessage', notificationMessage)
                        toast.success('Register With wallet')
                        navigate('/user/dashboard');
                    } else {
                        navigate('/otp/verify', {
                            state: { isTfaEnable: false, login: false }
                        })
                    }


                }
            }



        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    const handleGlogin = async () => {

        // console.log(email)
        try {
            if (email == '') {
                toast('Email Required')
            } else {

                const { data } = await axios.post(`${URL}/adduser/`, {
                    Email: email,
                    Phone_Number: phone,
                    User_Device_id: "",
                    User_type: userType,
                    device_unique_id: "",
                    referal_code: code ? code : 'U8DECP',
                    user_name: name
                })
                if (data.status == "false") {
                    localStorage.clear();
                    toast(data.Msg)
                } else {
                    localStorage.clear();
                    toast(data.Msg)
                    localStorage.setItem('user', JSON.stringify(data))
                    localStorage.setItem('ph', 1)
                    localStorage.setItem('otp', "true")
                    navigate('/set/pin')
                }
            }



        } catch (error) {
            console.log(error)
        }
    }

    const handleEmaillogin = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    const handleconnectwithwallet = async () => {
        try {
            if (window.ethereum) {
                // Automatically detects if MetaMask or Trust Wallet is installed
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                const newProvider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = newProvider.getSigner();
                const userAddress = await signer.getAddress();
                setAddress(userAddress)

            } else {

                console.log('MetaMask or Trust Wallet is not installed');
            }
        } catch (error) {
            console.log(error)
        }
    }



    useEffect(
        () => {
            if (user) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
                        console.log(res)
                        setEmail(res.data.email);
                        setType('Email')

                        setType('normaluser')
                        setName(res.data.name)
                        setButtonType(true)
                    })
                    .catch((err) => console.log(err));
            }
        },
        [user]
    );

    const formatNotificationMessage = (message) => {
        return message
            .replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>') // Convert URLs to links
            .replace(/\r?\n/g, '<br />'); // Convert newlines to <br /> tags
    };



    return (

        <section className="singup_div bg-image">
            <div className="container item-center">
                <div className="otp_all_info">
                    <div>
                        <h2></h2>
                    </div>
                    <div className="varify_content">
                        <div className="otp-title text-center">

                            <h2 className="mb-0">Register To Your Account</h2>
                        </div>
                        <div className="social-container d-flex justify-content-center align-items-center gap-4 py-3 text-white pb-3">
                            {/* <Link to="#"><i className="fa-brands fa-facebook text-white border rounded-pill p-2"></i></Link> */}
                            <Link onClick={() => handleEmaillogin()} style={{ color: "white" }}><i className="fa-brands fa-google text-white border rounded-pill p-2"></i> Register With Google</Link>
                            {/* <Link to="#"><i className="fa-brands fa-github text-white border rounded-pill p-2"></i></Link> */}
                        </div>

                        <ToastContainer />
                        <div className="Pin_set_form">
                            <div className="">
                                <div className="d-flex flex-column gap-2">
                                    <label for="">UserName</label>
                                    <input type="text" placeholder="Username" value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div className="d-flex flex-column gap-2">
                                    <label for="">Email</label>
                                    <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="d-flex flex-column gap-2">
                                    <label for="">Phone Number</label>
                                    <input type="number" placeholder="Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                </div>
                                <div className="d-flex flex-column gap-2">
                                    <label for="">Referal Code</label>
                                    <input type="text" placeholder="Code" value={code} onChange={(e) => setCode(e.target.value)} />
                                </div>
                                {
                                    address != '' && <div className="d-flex flex-column mb-3">
                                        <label for="">Wallet Address</label>
                                        <input type="text" placeholder="Enter Your Referral Code" value={address} />
                                    </div>
                                }

                                {
                                    loading == true ? <>
                                        <div className='load' style={{ textAlign: "center" }}>
                                            <div className="loader"></div>
                                        </div>
                                    </> : <>
                                        <div className="submit_btn text-center pt-4">
                                            <button className="submit_button" onClick={buttonType ? handleGlogin : handlelogin}>Register</button>
                                        </div>
                                    </>
                                }

                                {
                                    address == '' && <div className="text-center pt-4">
                                        <button className="google_athetication" onClick={handleconnectwithwallet}>Connect With Wallet</button>
                                    </div>


                                }

                            </div>
                        </div>



                        {/* <div className="forgot_password text-center pt-2">
                            <p className="mb-0">Already account? <Link to="/" className="fw-semibold text-white"> Sign In </Link></p>
                        </div> */}



                    </div>
                </div>
            </div>
        </section>
    )
}
