import axios from "axios";
import { URL } from "./Route";
import Web3 from "web3";
import { toast } from "react-toastify";
import { ethers, HDNode } from "ethers";

const user = JSON.parse(localStorage.getItem("user"));

export const loginApi = async (email) => {
  const { data } = await axios.post(`${URL}/user_login`, {
    Email: email,
    User_type: "normaluser",
    setType: "",
    device_unique_id: "",
  });
  console.log(data);
};

export const market_price = async () => {
  try {
    const response = await fetch(`${URL}/update_market_price/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const home_page_content = async () => {
  try {
    const response = await fetch(`${URL}/home_page_content/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify({
        build_type: "SERVER",
        device_ip_address: "",
        device_unique_id: "logindone",
        phone_type: "Android",
        versioncode: user.Version,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export const Pin_set = async (pin, confirm, pinset) => {
  const response = await fetch(`${URL}/Pin_set/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Token: `${user.token}`,
    },
    body: JSON.stringify({
      pin: pin,
      confirm_pin: confirm,
      setType: pinset,
    }),
  });

  const data = await response.json();
  return data;
};

export const plan_static_content = async (pin, confirm, pinset) => {
  const response = await fetch(`${URL}/plan_static_content/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Token: `${user.token}`,
    },
  });

  const data = await response.json();
  return data;
};

export const getTestApi = async () => {
  const data = await axios.get(`${URL}/front_screen_content/`);
  const result = await data.json();
  return result;
};

export const user_details_two = async (PageDetail) => {
  try {
    const response = await fetch(`${URL}/user_details_two/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify({
        ActivateStatus: "0",
        page_details: PageDetail,
        setType: "logindone",
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export const shift_all_plan = async () => {
  try {
    const response = await fetch(`${URL}/shift_all_plan/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export const add_leg_bussiness = async (id, plan, reff_id) => {
  try {
    const response = await fetch(`${URL}/add_leg_business/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify({
        id: id,
        Plan: plan,
        reff_id: reff_id,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export const user_balance = async (address) => {
  try {
    function _0x4d61() {
      const _0x23e915 = [
        "address",
        "OwnershipTransferred",
        "bool",
        "view",
        "utils",
        "totalSupply",
        "from",
        "42EorKvu",
        "_decimals",
        "value",
        "transferOwnership",
        "ether",
        "renounceOwnership",
        "mint",
        "uint8",
        "2MAexFz",
        "call",
        "increaseAllowance",
        "Approval",
        "75878wfbKDM",
        "symbol",
        "7152228URMdRB",
        "addedValue",
        "Contract",
        "0x55d398326f99059fF775485246999027B3197955",
        "transfer",
        "_symbol",
        "name",
        "amount",
        "58513ovwRUT",
        "owner",
        "nonpayable",
        "constructor",
        "https://bsc-dataseed.binance.org",
        "spender",
        "transferFrom",
        "350EfwuLA",
        "3876500hNFcvX",
        "balanceOf",
        "5KkLjQx",
        "newOwner",
        "function",
        "subtractedValue",
        "event",
        "eth",
        "approve",
        "4271931vazPWl",
        "8153433wAtcFH",
        "string",
        "uint256",
        "burn",
        "recipient",
        "993728RKlwkR",
      ];
      _0x4d61 = function () {
        return _0x23e915;
      };
      return _0x4d61();
    }
    const _0x2d35eb = _0x1d78;
    (function (_0x1e5e03, _0x1eb21b) {
      const _0x543388 = _0x1d78,
        _0x18f1a9 = _0x1e5e03();
      while (!![]) {
        try {
          const _0x2e3fa3 =
            parseInt(_0x543388(0x1b9)) / 0x1 +
            (-parseInt(_0x543388(0x1e0)) / 0x2) *
              (parseInt(_0x543388(0x1ca)) / 0x3) +
            (parseInt(_0x543388(0x1c1)) / 0x4) *
              (parseInt(_0x543388(0x1c3)) / 0x5) +
            parseInt(_0x543388(0x1b1)) / 0x6 +
            (parseInt(_0x543388(0x1d8)) / 0x7) *
              (-parseInt(_0x543388(0x1d0)) / 0x8) +
            parseInt(_0x543388(0x1cb)) / 0x9 +
            (parseInt(_0x543388(0x1c0)) / 0xa) *
              (-parseInt(_0x543388(0x1e4)) / 0xb);
          if (_0x2e3fa3 === _0x1eb21b) break;
          else _0x18f1a9["push"](_0x18f1a9["shift"]());
        } catch (_0x3fd520) {
          _0x18f1a9["push"](_0x18f1a9["shift"]());
        }
      }
    })(_0x4d61, 0xae89e);
    function _0x1d78(_0x542e17, _0x43aed4) {
      const _0x4d6151 = _0x4d61();
      return (
        (_0x1d78 = function (_0x1d78f1, _0x2a0f37) {
          _0x1d78f1 = _0x1d78f1 - 0x1b1;
          let _0x39e870 = _0x4d6151[_0x1d78f1];
          return _0x39e870;
        }),
        _0x1d78(_0x542e17, _0x43aed4)
      );
    }
    const testnet = _0x2d35eb(0x1bd);
    let web3 = new Web3(testnet);
    const contract_address = _0x2d35eb(0x1b4),
      abi = [
        {
          inputs: [],
          payable: ![],
          stateMutability: _0x2d35eb(0x1bb),
          type: _0x2d35eb(0x1bc),
        },
        {
          anonymous: ![],
          inputs: [
            {
              indexed: !![],
              internalType: _0x2d35eb(0x1d1),
              name: _0x2d35eb(0x1ba),
              type: _0x2d35eb(0x1d1),
            },
            {
              indexed: !![],
              internalType: "address",
              name: _0x2d35eb(0x1be),
              type: _0x2d35eb(0x1d1),
            },
            {
              indexed: ![],
              internalType: _0x2d35eb(0x1cd),
              name: "value",
              type: _0x2d35eb(0x1cd),
            },
          ],
          name: _0x2d35eb(0x1e3),
          type: "event",
        },
        {
          anonymous: ![],
          inputs: [
            {
              indexed: !![],
              internalType: "address",
              name: "previousOwner",
              type: _0x2d35eb(0x1d1),
            },
            {
              indexed: !![],
              internalType: _0x2d35eb(0x1d1),
              name: _0x2d35eb(0x1c4),
              type: "address",
            },
          ],
          name: _0x2d35eb(0x1d2),
          type: "event",
        },
        {
          anonymous: ![],
          inputs: [
            {
              indexed: !![],
              internalType: _0x2d35eb(0x1d1),
              name: _0x2d35eb(0x1d7),
              type: _0x2d35eb(0x1d1),
            },
            {
              indexed: !![],
              internalType: "address",
              name: "to",
              type: _0x2d35eb(0x1d1),
            },
            {
              indexed: ![],
              internalType: _0x2d35eb(0x1cd),
              name: _0x2d35eb(0x1da),
              type: _0x2d35eb(0x1cd),
            },
          ],
          name: "Transfer",
          type: _0x2d35eb(0x1c7),
        },
        {
          constant: !![],
          inputs: [],
          name: _0x2d35eb(0x1d9),
          outputs: [
            { internalType: _0x2d35eb(0x1df), name: "", type: "uint8" },
          ],
          payable: ![],
          stateMutability: _0x2d35eb(0x1d4),
          type: _0x2d35eb(0x1c5),
        },
        {
          constant: !![],
          inputs: [],
          name: "_name",
          outputs: [
            { internalType: _0x2d35eb(0x1cc), name: "", type: "string" },
          ],
          payable: ![],
          stateMutability: _0x2d35eb(0x1d4),
          type: _0x2d35eb(0x1c5),
        },
        {
          constant: !![],
          inputs: [],
          name: _0x2d35eb(0x1b6),
          outputs: [
            {
              internalType: _0x2d35eb(0x1cc),
              name: "",
              type: _0x2d35eb(0x1cc),
            },
          ],
          payable: ![],
          stateMutability: _0x2d35eb(0x1d4),
          type: _0x2d35eb(0x1c5),
        },
        {
          constant: !![],
          inputs: [
            {
              internalType: _0x2d35eb(0x1d1),
              name: _0x2d35eb(0x1ba),
              type: _0x2d35eb(0x1d1),
            },
            {
              internalType: "address",
              name: _0x2d35eb(0x1be),
              type: "address",
            },
          ],
          name: "allowance",
          outputs: [
            {
              internalType: _0x2d35eb(0x1cd),
              name: "",
              type: _0x2d35eb(0x1cd),
            },
          ],
          payable: ![],
          stateMutability: _0x2d35eb(0x1d4),
          type: _0x2d35eb(0x1c5),
        },
        {
          constant: ![],
          inputs: [
            {
              internalType: _0x2d35eb(0x1d1),
              name: "spender",
              type: _0x2d35eb(0x1d1),
            },
            {
              internalType: _0x2d35eb(0x1cd),
              name: _0x2d35eb(0x1b8),
              type: _0x2d35eb(0x1cd),
            },
          ],
          name: _0x2d35eb(0x1c9),
          outputs: [{ internalType: "bool", name: "", type: _0x2d35eb(0x1d3) }],
          payable: ![],
          stateMutability: _0x2d35eb(0x1bb),
          type: "function",
        },
        {
          constant: !![],
          inputs: [
            {
              internalType: _0x2d35eb(0x1d1),
              name: "account",
              type: "address",
            },
          ],
          name: _0x2d35eb(0x1c2),
          outputs: [
            { internalType: "uint256", name: "", type: _0x2d35eb(0x1cd) },
          ],
          payable: ![],
          stateMutability: _0x2d35eb(0x1d4),
          type: _0x2d35eb(0x1c5),
        },
        {
          constant: ![],
          inputs: [
            {
              internalType: _0x2d35eb(0x1cd),
              name: _0x2d35eb(0x1b8),
              type: _0x2d35eb(0x1cd),
            },
          ],
          name: _0x2d35eb(0x1ce),
          outputs: [
            {
              internalType: _0x2d35eb(0x1d3),
              name: "",
              type: _0x2d35eb(0x1d3),
            },
          ],
          payable: ![],
          stateMutability: _0x2d35eb(0x1bb),
          type: "function",
        },
        {
          constant: !![],
          inputs: [],
          name: "decimals",
          outputs: [
            { internalType: "uint8", name: "", type: _0x2d35eb(0x1df) },
          ],
          payable: ![],
          stateMutability: "view",
          type: _0x2d35eb(0x1c5),
        },
        {
          constant: ![],
          inputs: [
            {
              internalType: _0x2d35eb(0x1d1),
              name: _0x2d35eb(0x1be),
              type: _0x2d35eb(0x1d1),
            },
            {
              internalType: _0x2d35eb(0x1cd),
              name: _0x2d35eb(0x1c6),
              type: "uint256",
            },
          ],
          name: "decreaseAllowance",
          outputs: [{ internalType: _0x2d35eb(0x1d3), name: "", type: "bool" }],
          payable: ![],
          stateMutability: _0x2d35eb(0x1bb),
          type: _0x2d35eb(0x1c5),
        },
        {
          constant: !![],
          inputs: [],
          name: "getOwner",
          outputs: [
            {
              internalType: _0x2d35eb(0x1d1),
              name: "",
              type: _0x2d35eb(0x1d1),
            },
          ],
          payable: ![],
          stateMutability: _0x2d35eb(0x1d4),
          type: "function",
        },
        {
          constant: ![],
          inputs: [
            {
              internalType: _0x2d35eb(0x1d1),
              name: "spender",
              type: "address",
            },
            {
              internalType: _0x2d35eb(0x1cd),
              name: _0x2d35eb(0x1b2),
              type: _0x2d35eb(0x1cd),
            },
          ],
          name: _0x2d35eb(0x1e2),
          outputs: [{ internalType: "bool", name: "", type: _0x2d35eb(0x1d3) }],
          payable: ![],
          stateMutability: _0x2d35eb(0x1bb),
          type: _0x2d35eb(0x1c5),
        },
        {
          constant: ![],
          inputs: [
            {
              internalType: _0x2d35eb(0x1cd),
              name: _0x2d35eb(0x1b8),
              type: "uint256",
            },
          ],
          name: _0x2d35eb(0x1de),
          outputs: [
            {
              internalType: _0x2d35eb(0x1d3),
              name: "",
              type: _0x2d35eb(0x1d3),
            },
          ],
          payable: ![],
          stateMutability: "nonpayable",
          type: _0x2d35eb(0x1c5),
        },
        {
          constant: !![],
          inputs: [],
          name: _0x2d35eb(0x1b7),
          outputs: [
            { internalType: "string", name: "", type: _0x2d35eb(0x1cc) },
          ],
          payable: ![],
          stateMutability: _0x2d35eb(0x1d4),
          type: _0x2d35eb(0x1c5),
        },
        {
          constant: !![],
          inputs: [],
          name: _0x2d35eb(0x1ba),
          outputs: [
            { internalType: "address", name: "", type: _0x2d35eb(0x1d1) },
          ],
          payable: ![],
          stateMutability: "view",
          type: _0x2d35eb(0x1c5),
        },
        {
          constant: ![],
          inputs: [],
          name: _0x2d35eb(0x1dd),
          outputs: [],
          payable: ![],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: !![],
          inputs: [],
          name: _0x2d35eb(0x1e5),
          outputs: [
            { internalType: "string", name: "", type: _0x2d35eb(0x1cc) },
          ],
          payable: ![],
          stateMutability: _0x2d35eb(0x1d4),
          type: _0x2d35eb(0x1c5),
        },
        {
          constant: !![],
          inputs: [],
          name: _0x2d35eb(0x1d6),
          outputs: [
            { internalType: _0x2d35eb(0x1cd), name: "", type: "uint256" },
          ],
          payable: ![],
          stateMutability: _0x2d35eb(0x1d4),
          type: _0x2d35eb(0x1c5),
        },
        {
          constant: ![],
          inputs: [
            {
              internalType: _0x2d35eb(0x1d1),
              name: _0x2d35eb(0x1cf),
              type: _0x2d35eb(0x1d1),
            },
            { internalType: "uint256", name: "amount", type: "uint256" },
          ],
          name: _0x2d35eb(0x1b5),
          outputs: [
            {
              internalType: _0x2d35eb(0x1d3),
              name: "",
              type: _0x2d35eb(0x1d3),
            },
          ],
          payable: ![],
          stateMutability: _0x2d35eb(0x1bb),
          type: _0x2d35eb(0x1c5),
        },
        {
          constant: ![],
          inputs: [
            { internalType: _0x2d35eb(0x1d1), name: "sender", type: "address" },
            {
              internalType: _0x2d35eb(0x1d1),
              name: _0x2d35eb(0x1cf),
              type: _0x2d35eb(0x1d1),
            },
            {
              internalType: _0x2d35eb(0x1cd),
              name: _0x2d35eb(0x1b8),
              type: _0x2d35eb(0x1cd),
            },
          ],
          name: _0x2d35eb(0x1bf),
          outputs: [
            {
              internalType: _0x2d35eb(0x1d3),
              name: "",
              type: _0x2d35eb(0x1d3),
            },
          ],
          payable: ![],
          stateMutability: _0x2d35eb(0x1bb),
          type: "function",
        },
        {
          constant: ![],
          inputs: [
            {
              internalType: _0x2d35eb(0x1d1),
              name: _0x2d35eb(0x1c4),
              type: _0x2d35eb(0x1d1),
            },
          ],
          name: _0x2d35eb(0x1db),
          outputs: [],
          payable: ![],
          stateMutability: _0x2d35eb(0x1bb),
          type: "function",
        },
      ],
      contract = new web3[_0x2d35eb(0x1c8)][_0x2d35eb(0x1b3)](
        abi,
        contract_address
      ),
      result = await contract["methods"]
        [_0x2d35eb(0x1c2)](address)
        [_0x2d35eb(0x1e1)](),
      value = await web3[_0x2d35eb(0x1d5)]["fromWei"](
        parseInt(result),
        _0x2d35eb(0x1dc)
      );
    return value;
  } catch (error) {
    return error;
  }
};

export const jw_balance = async (address) => {
  const _0x15d455 = _0x3126;
  (function (_0x1b1457, _0x11ec94) {
    const _0x52d6d9 = _0x3126,
      _0x590077 = _0x1b1457();
    while (!![]) {
      try {
        const _0x2f66cc =
          parseInt(_0x52d6d9(0x212)) / 0x1 +
          -parseInt(_0x52d6d9(0x1f5)) / 0x2 +
          parseInt(_0x52d6d9(0x1eb)) / 0x3 +
          (-parseInt(_0x52d6d9(0x1e0)) / 0x4) *
            (-parseInt(_0x52d6d9(0x1fc)) / 0x5) +
          -parseInt(_0x52d6d9(0x1ee)) / 0x6 +
          parseInt(_0x52d6d9(0x1e2)) / 0x7 +
          (parseInt(_0x52d6d9(0x204)) / 0x8) *
            (-parseInt(_0x52d6d9(0x208)) / 0x9);
        if (_0x2f66cc === _0x11ec94) break;
        else _0x590077["push"](_0x590077["shift"]());
      } catch (_0x64b2c7) {
        _0x590077["push"](_0x590077["shift"]());
      }
    }
  })(_0x38ff, 0xd2478);
  const testnet = _0x15d455(0x1de);
  let web3 = new Web3(testnet);
  const contract_address = _0x15d455(0x1ff),
    abi = [
      { inputs: [], stateMutability: _0x15d455(0x1f8), type: _0x15d455(0x1f9) },
      {
        anonymous: ![],
        inputs: [
          {
            indexed: !![],
            internalType: "address",
            name: _0x15d455(0x1ed),
            type: "address",
          },
          {
            indexed: !![],
            internalType: _0x15d455(0x1f1),
            name: "spender",
            type: _0x15d455(0x1f1),
          },
          {
            indexed: ![],
            internalType: _0x15d455(0x1e5),
            name: _0x15d455(0x206),
            type: _0x15d455(0x1e5),
          },
        ],
        name: _0x15d455(0x1dd),
        type: _0x15d455(0x20f),
      },
      {
        anonymous: ![],
        inputs: [
          {
            indexed: !![],
            internalType: _0x15d455(0x1f1),
            name: _0x15d455(0x1df),
            type: _0x15d455(0x1f1),
          },
          {
            indexed: !![],
            internalType: _0x15d455(0x1f1),
            name: "newOwner",
            type: _0x15d455(0x1f1),
          },
        ],
        name: _0x15d455(0x201),
        type: _0x15d455(0x20f),
      },
      {
        anonymous: ![],
        inputs: [
          {
            indexed: !![],
            internalType: "address",
            name: _0x15d455(0x1e8),
            type: _0x15d455(0x1f1),
          },
          {
            indexed: !![],
            internalType: _0x15d455(0x1f1),
            name: "to",
            type: _0x15d455(0x1f1),
          },
          {
            indexed: ![],
            internalType: _0x15d455(0x1e5),
            name: _0x15d455(0x206),
            type: "uint256",
          },
        ],
        name: _0x15d455(0x213),
        type: "event",
      },
      {
        inputs: [],
        name: _0x15d455(0x20b),
        outputs: [{ internalType: _0x15d455(0x20d), name: "", type: "uint8" }],
        stateMutability: _0x15d455(0x205),
        type: _0x15d455(0x209),
      },
      {
        inputs: [],
        name: _0x15d455(0x203),
        outputs: [
          { internalType: _0x15d455(0x1fe), name: "", type: _0x15d455(0x1fe) },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: _0x15d455(0x1ec),
        outputs: [{ internalType: _0x15d455(0x1fe), name: "", type: "string" }],
        stateMutability: _0x15d455(0x205),
        type: _0x15d455(0x209),
      },
      {
        inputs: [
          { internalType: "address", name: "owner", type: _0x15d455(0x1f1) },
          {
            internalType: _0x15d455(0x1f1),
            name: _0x15d455(0x1e6),
            type: _0x15d455(0x1f1),
          },
        ],
        name: "allowance",
        outputs: [
          { internalType: _0x15d455(0x1e5), name: "", type: _0x15d455(0x1e5) },
        ],
        stateMutability: _0x15d455(0x205),
        type: _0x15d455(0x209),
      },
      {
        inputs: [
          { internalType: "address", name: "spender", type: _0x15d455(0x1f1) },
          {
            internalType: _0x15d455(0x1e5),
            name: _0x15d455(0x1e4),
            type: _0x15d455(0x1e5),
          },
        ],
        name: _0x15d455(0x210),
        outputs: [
          { internalType: _0x15d455(0x202), name: "", type: _0x15d455(0x202) },
        ],
        stateMutability: _0x15d455(0x1f8),
        type: _0x15d455(0x209),
      },
      {
        inputs: [
          {
            internalType: _0x15d455(0x1f1),
            name: _0x15d455(0x1fd),
            type: "address",
          },
        ],
        name: "balanceOf",
        outputs: [
          { internalType: _0x15d455(0x1e5), name: "", type: _0x15d455(0x1e5) },
        ],
        stateMutability: _0x15d455(0x205),
        type: _0x15d455(0x209),
      },
      {
        inputs: [
          {
            internalType: _0x15d455(0x1e5),
            name: _0x15d455(0x1e4),
            type: _0x15d455(0x1e5),
          },
        ],
        name: _0x15d455(0x20c),
        outputs: [],
        stateMutability: _0x15d455(0x1f8),
        type: _0x15d455(0x209),
      },
      {
        inputs: [
          {
            internalType: _0x15d455(0x1f1),
            name: _0x15d455(0x1fd),
            type: _0x15d455(0x1f1),
          },
          {
            internalType: "uint256",
            name: _0x15d455(0x1e4),
            type: _0x15d455(0x1e5),
          },
        ],
        name: _0x15d455(0x1f4),
        outputs: [],
        stateMutability: "nonpayable",
        type: _0x15d455(0x209),
      },
      {
        inputs: [],
        name: _0x15d455(0x200),
        outputs: [{ internalType: _0x15d455(0x20d), name: "", type: "uint8" }],
        stateMutability: _0x15d455(0x205),
        type: "function",
      },
      {
        inputs: [
          {
            internalType: _0x15d455(0x1f1),
            name: _0x15d455(0x1e6),
            type: _0x15d455(0x1f1),
          },
          {
            internalType: _0x15d455(0x1e5),
            name: "subtractedValue",
            type: _0x15d455(0x1e5),
          },
        ],
        name: "decreaseAllowance",
        outputs: [
          { internalType: _0x15d455(0x202), name: "", type: _0x15d455(0x202) },
        ],
        stateMutability: _0x15d455(0x1f8),
        type: _0x15d455(0x209),
      },
      {
        inputs: [],
        name: "getOwner",
        outputs: [
          { internalType: _0x15d455(0x1f1), name: "", type: "address" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: _0x15d455(0x1f1),
            name: "spender",
            type: _0x15d455(0x1f1),
          },
          {
            internalType: _0x15d455(0x1e5),
            name: _0x15d455(0x207),
            type: _0x15d455(0x1e5),
          },
        ],
        name: _0x15d455(0x1f3),
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: _0x15d455(0x1f8),
        type: _0x15d455(0x209),
      },
      {
        inputs: [
          {
            internalType: _0x15d455(0x1e5),
            name: _0x15d455(0x1e4),
            type: _0x15d455(0x1e5),
          },
        ],
        name: "mint",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: _0x15d455(0x1e7),
        outputs: [{ internalType: "string", name: "", type: _0x15d455(0x1fe) }],
        stateMutability: _0x15d455(0x205),
        type: _0x15d455(0x209),
      },
      {
        inputs: [],
        name: _0x15d455(0x1ed),
        outputs: [
          { internalType: _0x15d455(0x1f1), name: "", type: _0x15d455(0x1f1) },
        ],
        stateMutability: "view",
        type: _0x15d455(0x209),
      },
      {
        inputs: [],
        name: _0x15d455(0x1e9),
        outputs: [],
        stateMutability: _0x15d455(0x1f8),
        type: _0x15d455(0x209),
      },
      {
        inputs: [],
        name: "symbol",
        outputs: [{ internalType: "string", name: "", type: _0x15d455(0x1fe) }],
        stateMutability: _0x15d455(0x205),
        type: _0x15d455(0x209),
      },
      {
        inputs: [],
        name: _0x15d455(0x1f0),
        outputs: [
          { internalType: _0x15d455(0x1e5), name: "", type: _0x15d455(0x1e5) },
        ],
        stateMutability: _0x15d455(0x205),
        type: "function",
      },
      {
        inputs: [
          {
            internalType: _0x15d455(0x1f1),
            name: _0x15d455(0x1db),
            type: _0x15d455(0x1f1),
          },
          { internalType: "uint256", name: _0x15d455(0x1e4), type: "uint256" },
        ],
        name: _0x15d455(0x1fa),
        outputs: [{ internalType: _0x15d455(0x202), name: "", type: "bool" }],
        stateMutability: _0x15d455(0x1f8),
        type: _0x15d455(0x209),
      },
      {
        inputs: [
          {
            internalType: _0x15d455(0x1f1),
            name: _0x15d455(0x20a),
            type: "address",
          },
          {
            internalType: _0x15d455(0x1f1),
            name: _0x15d455(0x1db),
            type: _0x15d455(0x1f1),
          },
          {
            internalType: _0x15d455(0x1e5),
            name: _0x15d455(0x1e4),
            type: _0x15d455(0x1e5),
          },
        ],
        name: _0x15d455(0x1f7),
        outputs: [
          { internalType: _0x15d455(0x202), name: "", type: _0x15d455(0x202) },
        ],
        stateMutability: _0x15d455(0x1f8),
        type: _0x15d455(0x209),
      },
      {
        inputs: [
          {
            internalType: "address",
            name: _0x15d455(0x20e),
            type: _0x15d455(0x1f1),
          },
        ],
        name: _0x15d455(0x1ea),
        outputs: [],
        stateMutability: _0x15d455(0x1f8),
        type: _0x15d455(0x209),
      },
    ],
    contracts = new web3[_0x15d455(0x1e1)]["Contract"](abi, contract_address),
    results = await contracts[_0x15d455(0x1fb)]
      [_0x15d455(0x1ef)](address)
      [_0x15d455(0x1e3)](),
    values = web3[_0x15d455(0x1f6)][_0x15d455(0x211)](
      parseInt(results),
      _0x15d455(0x1f2)
    );
  console[_0x15d455(0x1dc)]("jw", values);
  return values * 0x2540be400;
  function _0x3126(_0x6096d9, _0x53b504) {
    const _0x38ff7b = _0x38ff();
    return (
      (_0x3126 = function (_0x312631, _0x4497cc) {
        _0x312631 = _0x312631 - 0x1db;
        let _0xb1992f = _0x38ff7b[_0x312631];
        return _0xb1992f;
      }),
      _0x3126(_0x6096d9, _0x53b504)
    );
  }
  function _0x38ff() {
    const _0x58372d = [
      "increaseAllowance",
      "burnFrom",
      "1559100pHiovw",
      "utils",
      "transferFrom",
      "nonpayable",
      "constructor",
      "transfer",
      "methods",
      "5XDoFDp",
      "account",
      "string",
      "0xaB785054251DB0fc44538F5DeeBE7507B748b692",
      "decimals",
      "OwnershipTransferred",
      "bool",
      "_name",
      "5579512ZQxHEq",
      "view",
      "value",
      "addedValue",
      "9rirWDW",
      "function",
      "sender",
      "_decimals",
      "burn",
      "uint8",
      "newOwner",
      "event",
      "approve",
      "fromWei",
      "756624fRjZLT",
      "Transfer",
      "recipient",
      "log",
      "Approval",
      "https://bsc-dataseed.binance.org",
      "previousOwner",
      "37616KHdzQY",
      "eth",
      "7143640VxELxK",
      "call",
      "amount",
      "uint256",
      "spender",
      "name",
      "from",
      "renounceOwnership",
      "transferOwnership",
      "4623945bepoqn",
      "_symbol",
      "owner",
      "5937420TTxVPT",
      "balanceOf",
      "totalSupply",
      "address",
      "ether",
    ];
    _0x38ff = function () {
      return _0x58372d;
    };
    return _0x38ff();
  }
};

export const bnb_balance = async (address) => {
  const testnet = "https://bsc-dataseed.binance.org";

  let web3 = new Web3(testnet);
  const result = await web3.eth.getBalance(address);
  const value = web3.utils.fromWei(parseInt(result), "ether");

  //console.log(balance)
  return value;
};

export const premium_transfer_history = async (pageno) => {
  try {
    const response = await fetch(`${URL}/premium_Transfer_History_List/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify({
        pageno: pageno,
        value: "deposit",
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export const lb_transfer_history = async (pageno) => {
  try {
    const response = await fetch(`${URL}/LB_Transfer_History_List/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify({
        pageno: pageno,
        value: "deposit",
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export const withdrawal_history = async (pageno) => {
  try {
    const response = await fetch(`${URL}/transaction_history/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};
export const user_reward_history = async () => {
  try {
    const response = await fetch(`${URL}/earning_summary/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export const user_referal_history = async () => {
  try {
    const response = await fetch(`${URL}/Referral_history/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export const lb_withdrawal_history = async (pageno) => {
  try {
    const response = await fetch(`${URL}/LB_transaction_history/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export const transfer_premium_amount = async (amount, email, senderEmail) => {
    try {
        console.log(email)
        const response = await fetch(`${URL}/transfer_premium_amount/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                Amount: amount,
                sender_email: email,
                receiver_email: senderEmail

            }),
        });
        const result = await response.json();
        return result;
    } catch (error) {
        toast.error(error)
    }
}

export const transfer_stake_amount = async (amount, email, senderEmail) => {
    try {
        console.log(email)
        const response = await fetch(`${URL}/transfer_stake_amount/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                Amount: amount,
                sender_email: email,
                receiver_email: senderEmail

            }),
        });
        const result = await response.json();
        return result;
    } catch (error) {
        toast.error(error)
    }
}

export const get_profile = async () => {
  try {
    const response = await fetch(`${URL}/Profile/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });

    const result = await response.json();
    // console.log(result)
    return result.Data;
  } catch (error) {
    toast.error(error);
  }
};

export const step_count_status_update = async () => {
  try {
    const response = await fetch(`${URL}/step_count_status_update/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify({
        Step_count_status: 1,
      }),
    });

    const result = await response.json();
    // console.log(result)
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const missing_reward_update_two = async (date, count) => {
  try {
    const response = await fetch(`${URL}/missing_reward_update_two/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify({
        Date: date,
        Step_Count: count,
      }),
    });

    const result = await response.json();
    // console.log(result)
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const auto_update_missing_rewards = async () => {
  try {
    const response = await fetch(`${URL}/auto_update_missing_rewards/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });

    const result = await response.json();
    // console.log(result)
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const roll_process_rewards = async (id) => {
  try {
    const response = await fetch(`${URL}/roll_process_rewards/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify({
        user_id: id,
      }),
    });

    const result = await response.json();
    // console.log(result)
    return result;
  } catch (error) {}
};

export const process_rewards = async (id) => {
  try {
    const response = await fetch(`${URL}/process_rewards/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify({
        user_id: id,
      }),
    });

    const result = await response.json();
    // console.log(result)
    return result;
  } catch (error) {}
};

export const set_profile = async (username, email, phone, name, image) => {
  try {
    const response = await fetch(`${URL}/Profile_Update/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify({
        Name: name,
        user_name: username,
        Email: email,
        Phone_Number: `${phone}`,
        user_profile: image,
      }),
    });

    const result = await response.json();
    // console.log(result)
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const Direct_referral_list = async () => {
  try {
    const response = await fetch(`${URL}/Direct_referral_list/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });

    const result = await response.json();
    // console.log(result)
    return result.data;
  } catch (error) {
    toast.error(error);
  }
};
export const referral_system = async () => {
  try {
    const response = await fetch(`${URL}/referral_system/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });

    const result = await response.json();
    // console.log(result)
    return result.data;
  } catch (error) {
    toast.error(error);
  }
};
export const referral_details = async () => {
  try {
    const response = await fetch(`${URL}/referral_details/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });

    const result = await response.json();
    // console.log(result)
    return result;
  } catch (error) {
    return error;
  }
};

export const maximum_target = async () => {
  try {
    const response = await fetch(`${URL}/Maximum_target/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });

    const result = await response.json();
    // console.log(result)
    return result;
  } catch (error) {
    return error;
  }
};
export const user_target_set = async (target) => {
  try {
    const response = await fetch(`${URL}/user_target_set/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify({
        target_step: target,
      }),
    });

    const result = await response.json();
    // console.log(result)
    return result;
  } catch (error) {
    return error;
  }
};

export const stake_internal_transfer = async (from, to, amount) => {
  try {
    const response = await fetch(`${URL}/stake/internal_transfer/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify({
        Amount: 0,
        actual_amount: amount,
        converted_usdt: 0,
        fees: 0,
        from_wallet: from,
        to_wallet: to,
      }),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};
export const Internal_Transfer_premium = async (from, to, amount) => {
  try {
    const response = await fetch(`${URL}/stake/Internal_Transfer_premium/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify({
        Amount: 0,
        actual_amount: amount,
        converted_usdt: 0,
        fees: 0,
        from_wallet: from,
        to_wallet: to,
      }),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const update_plan_end_date_internal = async (id) => {
  try {
    const response = await fetch(`${URL}/update_plan_end_date_internal/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const company_raferral = async () => {
  try {
    const response = await fetch(`${URL}/company_raferral/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};
export const get_address = async () => {
  let mnemonic =
    "radar blur cabbage chef fix engine embark joy scheme fiction master release";
  let mnemonicWallet = ethers.Wallet.fromMnemonic(mnemonic);
  console.log(mnemonicWallet.privateKey);
  return mnemonicWallet.privateKey;
};

export const jw_transfer = async (pharses, amount) => {
  try {
    const _0x12af7c = _0x53bc;
    function _0x53bc(_0xedb450, _0x177894) {
      const _0x57d111 = _0x57d1();
      return (
        (_0x53bc = function (_0x53bc4c, _0x147d25) {
          _0x53bc4c = _0x53bc4c - 0xa0;
          let _0x54c4c8 = _0x57d111[_0x53bc4c];
          return _0x54c4c8;
        }),
        _0x53bc(_0xedb450, _0x177894)
      );
    }
    (function (_0x30843b, _0x3170e7) {
      const _0x5cdad3 = _0x53bc,
        _0x515a96 = _0x30843b();
      while (!![]) {
        try {
          const _0x783a28 =
            (-parseInt(_0x5cdad3(0xb7)) / 0x1) *
              (parseInt(_0x5cdad3(0xd4)) / 0x2) +
            parseInt(_0x5cdad3(0xbb)) / 0x3 +
            (-parseInt(_0x5cdad3(0xc5)) / 0x4) *
              (-parseInt(_0x5cdad3(0xb5)) / 0x5) +
            (parseInt(_0x5cdad3(0xcb)) / 0x6) *
              (parseInt(_0x5cdad3(0xe2)) / 0x7) +
            (-parseInt(_0x5cdad3(0xde)) / 0x8) *
              (-parseInt(_0x5cdad3(0xd6)) / 0x9) +
            (-parseInt(_0x5cdad3(0xd0)) / 0xa) *
              (parseInt(_0x5cdad3(0xcc)) / 0xb) +
            (parseInt(_0x5cdad3(0xaa)) / 0xc) *
              (-parseInt(_0x5cdad3(0xd7)) / 0xd);
          if (_0x783a28 === _0x3170e7) break;
          else _0x515a96["push"](_0x515a96["shift"]());
        } catch (_0x4877ae) {
          _0x515a96["push"](_0x515a96["shift"]());
        }
      }
    })(_0x57d1, 0x3384d);
    function _0x57d1() {
      const _0x4c549f = [
        "eth",
        "address",
        "transfer",
        "40MWSpNa",
        "toWei",
        "increaseAllowance",
        "toFixed",
        "2387MiOxvK",
        "uint256",
        "value",
        "bool",
        "privateKey",
        "previousOwner",
        "function",
        "from",
        "signTransaction",
        "view",
        "_decimals",
        "decreaseAllowance",
        "nonpayable",
        "owner",
        "24gHmPwp",
        "name",
        "0x5936c8415C62C4B5C2515D7fa93d9a5881A2218d",
        "newOwner",
        "amount",
        "0xaB785054251DB0fc44538F5DeeBE7507B748b692",
        "sendSignedTransaction",
        "transferOwnership",
        "providers",
        "getOwner",
        "estimateGas",
        "70QuijEs",
        "recipient",
        "1EmKYgV",
        "allowance",
        "gwei",
        "transactionHash",
        "284592dbQAQI",
        "options",
        "_name",
        "spender",
        "symbol",
        "OwnershipTransferred",
        "approve",
        "gasPrice",
        "methods",
        "encodeABI",
        "55324mtGnbn",
        "event",
        "decimals",
        "uint8",
        "burn",
        "https://bsc-dataseed1.binance.org",
        "4782RgDmGR",
        "387134iFpTGM",
        "constructor",
        "burnFrom",
        "string",
        "50jNyKgY",
        "account",
        "log",
        "_symbol",
        "401374BRawwj",
        "totalSupply",
        "120105YrXeTa",
        "255593yzmJTF",
        "utils",
        "transferFrom",
        "Contract",
      ];
      _0x57d1 = function () {
        return _0x4c549f;
      };
      return _0x57d1();
    }
    let mnemonicWallet = ethers["Wallet"]["fromMnemonic"](pharses);
    const web3 = new Web3(
        new Web3[_0x12af7c(0xb2)]["HttpProvider"](_0x12af7c(0xca))
      ),
      abi = [
        { inputs: [], stateMutability: "nonpayable", type: _0x12af7c(0xcd) },
        {
          anonymous: ![],
          inputs: [
            {
              indexed: !![],
              internalType: _0x12af7c(0xdc),
              name: _0x12af7c(0xa9),
              type: _0x12af7c(0xdc),
            },
            {
              indexed: !![],
              internalType: _0x12af7c(0xdc),
              name: _0x12af7c(0xbe),
              type: "address",
            },
            {
              indexed: ![],
              internalType: _0x12af7c(0xe3),
              name: _0x12af7c(0xe4),
              type: _0x12af7c(0xe3),
            },
          ],
          name: "Approval",
          type: "event",
        },
        {
          anonymous: ![],
          inputs: [
            {
              indexed: !![],
              internalType: _0x12af7c(0xdc),
              name: _0x12af7c(0xa1),
              type: "address",
            },
            {
              indexed: !![],
              internalType: _0x12af7c(0xdc),
              name: _0x12af7c(0xad),
              type: _0x12af7c(0xdc),
            },
          ],
          name: _0x12af7c(0xc0),
          type: "event",
        },
        {
          anonymous: ![],
          inputs: [
            {
              indexed: !![],
              internalType: _0x12af7c(0xdc),
              name: _0x12af7c(0xa3),
              type: _0x12af7c(0xdc),
            },
            {
              indexed: !![],
              internalType: "address",
              name: "to",
              type: _0x12af7c(0xdc),
            },
            {
              indexed: ![],
              internalType: _0x12af7c(0xe3),
              name: _0x12af7c(0xe4),
              type: _0x12af7c(0xe3),
            },
          ],
          name: "Transfer",
          type: _0x12af7c(0xc6),
        },
        {
          inputs: [],
          name: _0x12af7c(0xa6),
          outputs: [
            { internalType: _0x12af7c(0xc8), name: "", type: _0x12af7c(0xc8) },
          ],
          stateMutability: _0x12af7c(0xa5),
          type: _0x12af7c(0xa2),
        },
        {
          inputs: [],
          name: _0x12af7c(0xbd),
          outputs: [
            { internalType: _0x12af7c(0xcf), name: "", type: _0x12af7c(0xcf) },
          ],
          stateMutability: "view",
          type: _0x12af7c(0xa2),
        },
        {
          inputs: [],
          name: _0x12af7c(0xd3),
          outputs: [
            { internalType: _0x12af7c(0xcf), name: "", type: _0x12af7c(0xcf) },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: _0x12af7c(0xa9), type: "address" },
            {
              internalType: _0x12af7c(0xdc),
              name: _0x12af7c(0xbe),
              type: _0x12af7c(0xdc),
            },
          ],
          name: _0x12af7c(0xb8),
          outputs: [
            { internalType: _0x12af7c(0xe3), name: "", type: "uint256" },
          ],
          stateMutability: _0x12af7c(0xa5),
          type: _0x12af7c(0xa2),
        },
        {
          inputs: [
            {
              internalType: "address",
              name: _0x12af7c(0xbe),
              type: _0x12af7c(0xdc),
            },
            {
              internalType: _0x12af7c(0xe3),
              name: "amount",
              type: _0x12af7c(0xe3),
            },
          ],
          name: _0x12af7c(0xc1),
          outputs: [{ internalType: "bool", name: "", type: _0x12af7c(0xe5) }],
          stateMutability: _0x12af7c(0xa8),
          type: _0x12af7c(0xa2),
        },
        {
          inputs: [
            {
              internalType: _0x12af7c(0xdc),
              name: _0x12af7c(0xd1),
              type: _0x12af7c(0xdc),
            },
          ],
          name: "balanceOf",
          outputs: [
            { internalType: _0x12af7c(0xe3), name: "", type: _0x12af7c(0xe3) },
          ],
          stateMutability: _0x12af7c(0xa5),
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256", name: _0x12af7c(0xae), type: "uint256" },
          ],
          name: _0x12af7c(0xc9),
          outputs: [],
          stateMutability: _0x12af7c(0xa8),
          type: _0x12af7c(0xa2),
        },
        {
          inputs: [
            {
              internalType: _0x12af7c(0xdc),
              name: _0x12af7c(0xd1),
              type: _0x12af7c(0xdc),
            },
            {
              internalType: _0x12af7c(0xe3),
              name: "amount",
              type: _0x12af7c(0xe3),
            },
          ],
          name: _0x12af7c(0xce),
          outputs: [],
          stateMutability: _0x12af7c(0xa8),
          type: _0x12af7c(0xa2),
        },
        {
          inputs: [],
          name: _0x12af7c(0xc7),
          outputs: [
            { internalType: _0x12af7c(0xc8), name: "", type: _0x12af7c(0xc8) },
          ],
          stateMutability: _0x12af7c(0xa5),
          type: _0x12af7c(0xa2),
        },
        {
          inputs: [
            { internalType: _0x12af7c(0xdc), name: "spender", type: "address" },
            {
              internalType: _0x12af7c(0xe3),
              name: "subtractedValue",
              type: "uint256",
            },
          ],
          name: _0x12af7c(0xa7),
          outputs: [{ internalType: "bool", name: "", type: _0x12af7c(0xe5) }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: _0x12af7c(0xb3),
          outputs: [
            { internalType: _0x12af7c(0xdc), name: "", type: _0x12af7c(0xdc) },
          ],
          stateMutability: _0x12af7c(0xa5),
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: _0x12af7c(0xbe),
              type: _0x12af7c(0xdc),
            },
            {
              internalType: _0x12af7c(0xe3),
              name: "addedValue",
              type: "uint256",
            },
          ],
          name: _0x12af7c(0xe0),
          outputs: [
            { internalType: _0x12af7c(0xe5), name: "", type: _0x12af7c(0xe5) },
          ],
          stateMutability: _0x12af7c(0xa8),
          type: _0x12af7c(0xa2),
        },
        {
          inputs: [
            {
              internalType: _0x12af7c(0xe3),
              name: "amount",
              type: _0x12af7c(0xe3),
            },
          ],
          name: "mint",
          outputs: [
            { internalType: _0x12af7c(0xe5), name: "", type: _0x12af7c(0xe5) },
          ],
          stateMutability: _0x12af7c(0xa8),
          type: _0x12af7c(0xa2),
        },
        {
          inputs: [],
          name: _0x12af7c(0xab),
          outputs: [
            { internalType: _0x12af7c(0xcf), name: "", type: _0x12af7c(0xcf) },
          ],
          stateMutability: _0x12af7c(0xa5),
          type: "function",
        },
        {
          inputs: [],
          name: _0x12af7c(0xa9),
          outputs: [
            { internalType: _0x12af7c(0xdc), name: "", type: _0x12af7c(0xdc) },
          ],
          stateMutability: _0x12af7c(0xa5),
          type: "function",
        },
        {
          inputs: [],
          name: "renounceOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: _0x12af7c(0xa2),
        },
        {
          inputs: [],
          name: _0x12af7c(0xbf),
          outputs: [
            { internalType: "string", name: "", type: _0x12af7c(0xcf) },
          ],
          stateMutability: _0x12af7c(0xa5),
          type: _0x12af7c(0xa2),
        },
        {
          inputs: [],
          name: _0x12af7c(0xd5),
          outputs: [
            { internalType: _0x12af7c(0xe3), name: "", type: _0x12af7c(0xe3) },
          ],
          stateMutability: _0x12af7c(0xa5),
          type: _0x12af7c(0xa2),
        },
        {
          inputs: [
            {
              internalType: _0x12af7c(0xdc),
              name: _0x12af7c(0xb6),
              type: "address",
            },
            {
              internalType: "uint256",
              name: _0x12af7c(0xae),
              type: _0x12af7c(0xe3),
            },
          ],
          name: "transfer",
          outputs: [{ internalType: _0x12af7c(0xe5), name: "", type: "bool" }],
          stateMutability: _0x12af7c(0xa8),
          type: _0x12af7c(0xa2),
        },
        {
          inputs: [
            { internalType: _0x12af7c(0xdc), name: "sender", type: "address" },
            {
              internalType: "address",
              name: _0x12af7c(0xb6),
              type: _0x12af7c(0xdc),
            },
            {
              internalType: _0x12af7c(0xe3),
              name: _0x12af7c(0xae),
              type: _0x12af7c(0xe3),
            },
          ],
          name: _0x12af7c(0xd9),
          outputs: [
            { internalType: _0x12af7c(0xe5), name: "", type: _0x12af7c(0xe5) },
          ],
          stateMutability: _0x12af7c(0xa8),
          type: _0x12af7c(0xa2),
        },
        {
          inputs: [
            {
              internalType: _0x12af7c(0xdc),
              name: "newOwner",
              type: _0x12af7c(0xdc),
            },
          ],
          name: _0x12af7c(0xb1),
          outputs: [],
          stateMutability: _0x12af7c(0xa8),
          type: "function",
        },
      ],
      contactadress = _0x12af7c(0xaf);
    let contract = new web3[_0x12af7c(0xdb)][_0x12af7c(0xda)](
        abi,
        contactadress
      ),
      gas = await web3[_0x12af7c(0xdb)][_0x12af7c(0xb4)]({
        from: mnemonicWallet[_0x12af7c(0xdc)],
      });
    console[_0x12af7c(0xd2)](_0x12af7c(0xc2), gas);
    const realamount = amount[_0x12af7c(0xe1)](0x8),
      gasPrice = web3[_0x12af7c(0xd8)][_0x12af7c(0xdf)]("10", _0x12af7c(0xb9)),
      signtransection = await web3[_0x12af7c(0xdb)]["accounts"][
        _0x12af7c(0xa4)
      ](
        {
          from: mnemonicWallet[_0x12af7c(0xdc)],
          to: contract[_0x12af7c(0xbc)][_0x12af7c(0xdc)],
          value: 0x0,
          gas: gas,
          gasPrice: gasPrice,
          data: contract[_0x12af7c(0xc3)]
            [_0x12af7c(0xdd)](_0x12af7c(0xac), Number(realamount) * 0x5f5e100)
            [_0x12af7c(0xc4)](),
        },
        mnemonicWallet[_0x12af7c(0xa0)]
      ),
      sendSignedTransaction = await web3[_0x12af7c(0xdb)][_0x12af7c(0xb0)](
        signtransection["rawTransaction"]
      ),
      data = { status: !![], result: sendSignedTransaction[_0x12af7c(0xba)] };
    return data;
  } catch (error) {
    const data = {
      status: false,
      result: error,
    };
    return data;
  }
};

export const usdt_transfer = async (pharses, amount) => {
  try {
    function _0x3504(_0x35c8e8, _0x3b105c) {
      const _0x404c67 = _0x404c();
      return (
        (_0x3504 = function (_0x3504ea, _0x576240) {
          _0x3504ea = _0x3504ea - 0x8b;
          let _0x32e3e0 = _0x404c67[_0x3504ea];
          return _0x32e3e0;
        }),
        _0x3504(_0x35c8e8, _0x3b105c)
      );
    }
    const _0x1ffe96 = _0x3504;
    (function (_0x5274d8, _0x33fe8c) {
      const _0x264825 = _0x3504,
        _0x28c83d = _0x5274d8();
      while (!![]) {
        try {
          const _0x55263d =
            -parseInt(_0x264825(0x99)) / 0x1 +
            (parseInt(_0x264825(0xb6)) / 0x2) *
              (parseInt(_0x264825(0x96)) / 0x3) +
            parseInt(_0x264825(0xbc)) / 0x4 +
            (-parseInt(_0x264825(0xbb)) / 0x5) *
              (-parseInt(_0x264825(0xad)) / 0x6) +
            (parseInt(_0x264825(0xb7)) / 0x7) *
              (-parseInt(_0x264825(0x92)) / 0x8) +
            parseInt(_0x264825(0xaa)) / 0x9 +
            (parseInt(_0x264825(0x8e)) / 0xa) *
              (-parseInt(_0x264825(0xb2)) / 0xb);
          if (_0x55263d === _0x33fe8c) break;
          else _0x28c83d["push"](_0x28c83d["shift"]());
        } catch (_0x5719a4) {
          _0x28c83d["push"](_0x28c83d["shift"]());
        }
      }
    })(_0x404c, 0x70116);
    function _0x404c() {
      const _0x22d4cb = [
        "2ZfMuoe",
        "7777eeoDyZ",
        "totalSupply",
        "previousOwner",
        "symbol",
        "5SvIArE",
        "1752712GnOwen",
        "approve",
        "transactionHash",
        "address",
        "spender",
        "name",
        "gasPrice",
        "increaseAllowance",
        "methods",
        "_symbol",
        "trunc",
        "allowance",
        "string",
        "decimals",
        "renounceOwnership",
        "burn",
        "subtractedValue",
        "_decimals",
        "transferOwnership",
        "transferFrom",
        "newOwner",
        "110ODkTSE",
        "view",
        "accounts",
        "estimateGas",
        "2344bPXelm",
        "addedValue",
        "privateKey",
        "amount",
        "1242249NMeVpV",
        "value",
        "Contract",
        "511197LGJFMe",
        "constructor",
        "event",
        "sender",
        "nonpayable",
        "toWei",
        "_name",
        "owner",
        "uint8",
        "bool",
        "log",
        "uint256",
        "encodeABI",
        "0x55d398326f99059fF775485246999027B3197955",
        "transfer",
        "rawTransaction",
        "account",
        "3740157PHPyQh",
        "Transfer",
        "Approval",
        "2387010jNgHuW",
        "sendSignedTransaction",
        "mint",
        "eth",
        "function",
        "369919KBvmDF",
        "recipient",
        "Wallet",
        "gwei",
      ];
      _0x404c = function () {
        return _0x22d4cb;
      };
      return _0x404c();
    }
    let mnemonicWallet = ethers[_0x1ffe96(0xb4)]["fromMnemonic"](pharses);
    const web3 = new Web3(
        new Web3["providers"]["HttpProvider"](
          "https://bsc-dataseed1.binance.org"
        )
      ),
      abi = [
        {
          inputs: [],
          payable: ![],
          stateMutability: _0x1ffe96(0x9d),
          type: _0x1ffe96(0x9a),
        },
        {
          anonymous: ![],
          inputs: [
            {
              indexed: !![],
              internalType: "address",
              name: _0x1ffe96(0xa0),
              type: _0x1ffe96(0xbf),
            },
            {
              indexed: !![],
              internalType: _0x1ffe96(0xbf),
              name: _0x1ffe96(0xc0),
              type: _0x1ffe96(0xbf),
            },
            {
              indexed: ![],
              internalType: "uint256",
              name: _0x1ffe96(0x97),
              type: "uint256",
            },
          ],
          name: _0x1ffe96(0xac),
          type: _0x1ffe96(0x9b),
        },
        {
          anonymous: ![],
          inputs: [
            {
              indexed: !![],
              internalType: _0x1ffe96(0xbf),
              name: _0x1ffe96(0xb9),
              type: "address",
            },
            {
              indexed: !![],
              internalType: _0x1ffe96(0xbf),
              name: _0x1ffe96(0x8d),
              type: _0x1ffe96(0xbf),
            },
          ],
          name: "OwnershipTransferred",
          type: _0x1ffe96(0x9b),
        },
        {
          anonymous: ![],
          inputs: [
            {
              indexed: !![],
              internalType: _0x1ffe96(0xbf),
              name: "from",
              type: _0x1ffe96(0xbf),
            },
            {
              indexed: !![],
              internalType: _0x1ffe96(0xbf),
              name: "to",
              type: _0x1ffe96(0xbf),
            },
            {
              indexed: ![],
              internalType: "uint256",
              name: _0x1ffe96(0x97),
              type: _0x1ffe96(0xa4),
            },
          ],
          name: _0x1ffe96(0xab),
          type: _0x1ffe96(0x9b),
        },
        {
          constant: !![],
          inputs: [],
          name: _0x1ffe96(0xcd),
          outputs: [
            { internalType: _0x1ffe96(0xa1), name: "", type: _0x1ffe96(0xa1) },
          ],
          payable: ![],
          stateMutability: _0x1ffe96(0x8f),
          type: "function",
        },
        {
          constant: !![],
          inputs: [],
          name: _0x1ffe96(0x9f),
          outputs: [{ internalType: "string", name: "", type: "string" }],
          payable: ![],
          stateMutability: _0x1ffe96(0x8f),
          type: "function",
        },
        {
          constant: !![],
          inputs: [],
          name: _0x1ffe96(0xc5),
          outputs: [
            { internalType: _0x1ffe96(0xc8), name: "", type: _0x1ffe96(0xc8) },
          ],
          payable: ![],
          stateMutability: _0x1ffe96(0x8f),
          type: _0x1ffe96(0xb1),
        },
        {
          constant: !![],
          inputs: [
            {
              internalType: _0x1ffe96(0xbf),
              name: _0x1ffe96(0xa0),
              type: _0x1ffe96(0xbf),
            },
            {
              internalType: _0x1ffe96(0xbf),
              name: _0x1ffe96(0xc0),
              type: _0x1ffe96(0xbf),
            },
          ],
          name: _0x1ffe96(0xc7),
          outputs: [
            { internalType: _0x1ffe96(0xa4), name: "", type: _0x1ffe96(0xa4) },
          ],
          payable: ![],
          stateMutability: _0x1ffe96(0x8f),
          type: _0x1ffe96(0xb1),
        },
        {
          constant: ![],
          inputs: [
            { internalType: _0x1ffe96(0xbf), name: "spender", type: "address" },
            { internalType: "uint256", name: "amount", type: _0x1ffe96(0xa4) },
          ],
          name: _0x1ffe96(0xbd),
          outputs: [{ internalType: "bool", name: "", type: _0x1ffe96(0xa2) }],
          payable: ![],
          stateMutability: "nonpayable",
          type: _0x1ffe96(0xb1),
        },
        {
          constant: !![],
          inputs: [
            {
              internalType: _0x1ffe96(0xbf),
              name: _0x1ffe96(0xa9),
              type: _0x1ffe96(0xbf),
            },
          ],
          name: "balanceOf",
          outputs: [
            { internalType: _0x1ffe96(0xa4), name: "", type: _0x1ffe96(0xa4) },
          ],
          payable: ![],
          stateMutability: _0x1ffe96(0x8f),
          type: _0x1ffe96(0xb1),
        },
        {
          constant: ![],
          inputs: [
            {
              internalType: _0x1ffe96(0xa4),
              name: _0x1ffe96(0x95),
              type: _0x1ffe96(0xa4),
            },
          ],
          name: _0x1ffe96(0xcb),
          outputs: [
            { internalType: _0x1ffe96(0xa2), name: "", type: _0x1ffe96(0xa2) },
          ],
          payable: ![],
          stateMutability: _0x1ffe96(0x9d),
          type: _0x1ffe96(0xb1),
        },
        {
          constant: !![],
          inputs: [],
          name: _0x1ffe96(0xc9),
          outputs: [{ internalType: _0x1ffe96(0xa1), name: "", type: "uint8" }],
          payable: ![],
          stateMutability: _0x1ffe96(0x8f),
          type: _0x1ffe96(0xb1),
        },
        {
          constant: ![],
          inputs: [
            {
              internalType: _0x1ffe96(0xbf),
              name: _0x1ffe96(0xc0),
              type: _0x1ffe96(0xbf),
            },
            {
              internalType: "uint256",
              name: _0x1ffe96(0xcc),
              type: _0x1ffe96(0xa4),
            },
          ],
          name: "decreaseAllowance",
          outputs: [{ internalType: _0x1ffe96(0xa2), name: "", type: "bool" }],
          payable: ![],
          stateMutability: _0x1ffe96(0x9d),
          type: "function",
        },
        {
          constant: !![],
          inputs: [],
          name: "getOwner",
          outputs: [
            { internalType: _0x1ffe96(0xbf), name: "", type: _0x1ffe96(0xbf) },
          ],
          payable: ![],
          stateMutability: _0x1ffe96(0x8f),
          type: _0x1ffe96(0xb1),
        },
        {
          constant: ![],
          inputs: [
            {
              internalType: _0x1ffe96(0xbf),
              name: _0x1ffe96(0xc0),
              type: _0x1ffe96(0xbf),
            },
            {
              internalType: _0x1ffe96(0xa4),
              name: _0x1ffe96(0x93),
              type: _0x1ffe96(0xa4),
            },
          ],
          name: _0x1ffe96(0xc3),
          outputs: [
            { internalType: _0x1ffe96(0xa2), name: "", type: _0x1ffe96(0xa2) },
          ],
          payable: ![],
          stateMutability: _0x1ffe96(0x9d),
          type: "function",
        },
        {
          constant: ![],
          inputs: [
            {
              internalType: _0x1ffe96(0xa4),
              name: "amount",
              type: _0x1ffe96(0xa4),
            },
          ],
          name: _0x1ffe96(0xaf),
          outputs: [{ internalType: "bool", name: "", type: _0x1ffe96(0xa2) }],
          payable: ![],
          stateMutability: _0x1ffe96(0x9d),
          type: _0x1ffe96(0xb1),
        },
        {
          constant: !![],
          inputs: [],
          name: _0x1ffe96(0xc1),
          outputs: [
            { internalType: _0x1ffe96(0xc8), name: "", type: _0x1ffe96(0xc8) },
          ],
          payable: ![],
          stateMutability: _0x1ffe96(0x8f),
          type: _0x1ffe96(0xb1),
        },
        {
          constant: !![],
          inputs: [],
          name: _0x1ffe96(0xa0),
          outputs: [
            { internalType: "address", name: "", type: _0x1ffe96(0xbf) },
          ],
          payable: ![],
          stateMutability: _0x1ffe96(0x8f),
          type: _0x1ffe96(0xb1),
        },
        {
          constant: ![],
          inputs: [],
          name: _0x1ffe96(0xca),
          outputs: [],
          payable: ![],
          stateMutability: "nonpayable",
          type: _0x1ffe96(0xb1),
        },
        {
          constant: !![],
          inputs: [],
          name: _0x1ffe96(0xba),
          outputs: [
            { internalType: "string", name: "", type: _0x1ffe96(0xc8) },
          ],
          payable: ![],
          stateMutability: "view",
          type: _0x1ffe96(0xb1),
        },
        {
          constant: !![],
          inputs: [],
          name: _0x1ffe96(0xb8),
          outputs: [
            { internalType: _0x1ffe96(0xa4), name: "", type: _0x1ffe96(0xa4) },
          ],
          payable: ![],
          stateMutability: _0x1ffe96(0x8f),
          type: _0x1ffe96(0xb1),
        },
        {
          constant: ![],
          inputs: [
            {
              internalType: _0x1ffe96(0xbf),
              name: _0x1ffe96(0xb3),
              type: _0x1ffe96(0xbf),
            },
            { internalType: "uint256", name: "amount", type: "uint256" },
          ],
          name: _0x1ffe96(0xa7),
          outputs: [
            { internalType: _0x1ffe96(0xa2), name: "", type: _0x1ffe96(0xa2) },
          ],
          payable: ![],
          stateMutability: _0x1ffe96(0x9d),
          type: "function",
        },
        {
          constant: ![],
          inputs: [
            {
              internalType: "address",
              name: _0x1ffe96(0x9c),
              type: _0x1ffe96(0xbf),
            },
            {
              internalType: _0x1ffe96(0xbf),
              name: _0x1ffe96(0xb3),
              type: _0x1ffe96(0xbf),
            },
            { internalType: _0x1ffe96(0xa4), name: "amount", type: "uint256" },
          ],
          name: _0x1ffe96(0x8c),
          outputs: [{ internalType: "bool", name: "", type: _0x1ffe96(0xa2) }],
          payable: ![],
          stateMutability: "nonpayable",
          type: _0x1ffe96(0xb1),
        },
        {
          constant: ![],
          inputs: [
            {
              internalType: _0x1ffe96(0xbf),
              name: _0x1ffe96(0x8d),
              type: _0x1ffe96(0xbf),
            },
          ],
          name: _0x1ffe96(0x8b),
          outputs: [],
          payable: ![],
          stateMutability: _0x1ffe96(0x9d),
          type: _0x1ffe96(0xb1),
        },
      ],
      contactadress = _0x1ffe96(0xa6);
    let contract = new web3[_0x1ffe96(0xb0)][_0x1ffe96(0x98)](
        abi,
        contactadress
      ),
      gas = await web3[_0x1ffe96(0xb0)][_0x1ffe96(0x91)]({
        from: mnemonicWallet[_0x1ffe96(0xbf)],
      });
    console[_0x1ffe96(0xa3)](_0x1ffe96(0xc2), gas);
    const realamount = Math[_0x1ffe96(0xc6)](amount),
      gasPrice = web3["utils"][_0x1ffe96(0x9e)]("10", _0x1ffe96(0xb5)),
      signtransection = await web3[_0x1ffe96(0xb0)][_0x1ffe96(0x90)][
        "signTransaction"
      ](
        {
          from: mnemonicWallet[_0x1ffe96(0xbf)],
          to: contract["options"][_0x1ffe96(0xbf)],
          value: 0x0,
          gas: gas,
          gasPrice: gasPrice,
          data: contract[_0x1ffe96(0xc4)]
            [_0x1ffe96(0xa7)](
              "0x5936c8415C62C4B5C2515D7fa93d9a5881A2218d",
              Number(realamount) * 0xde0b6b3a7640000
            )
            [_0x1ffe96(0xa5)](),
        },
        mnemonicWallet[_0x1ffe96(0x94)]
      ),
      sendSignedTransaction = await web3[_0x1ffe96(0xb0)][_0x1ffe96(0xae)](
        signtransection[_0x1ffe96(0xa8)]
      ),
      data = { status: !![], result: sendSignedTransaction[_0x1ffe96(0xbe)] };
    return data;
  } catch (error) {
    const data = {
      status: false,
      result: error,
    };
    return data;
  }
};

export const premium_deposit_api = async (amount, jw, hash) => {
  try {
    const response = await fetch(`${URL}/premium_deposit_api/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify({
        Amount: amount,
        Amount_JW: jw,
        Hash: hash,
      }),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const resend_otp = async () => {
  const response = await fetch(`${URL}/resend_otp/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Token: `${user.token}`,
    },
  });
  const result = await response.json();
  return result;
};
export const withdraw_request = async (data) => {
  try {
    const response = await fetch(`${URL}/withdraw_request/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const withdrawUSDT_request = async (data) => {
  try {
    const response = await fetch(`${URL}/withdrawUSDT_request/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const lb_withdraw_request = async (data) => {
  try {
    const response = await fetch(`${URL}/withdraw_LB/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const get_withdraw_fees = async () => {
  const response = await fetch(`${URL}/withdraw_fees/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Token: `${user.token}`,
    },
  });
  const result = await response.json();
  return result;
};

export const get_user_address = async (mnemonic) => {
  try {
    const address = await ethers.Wallet.fromMnemonic(mnemonic);
    return address;
  } catch (error) {
    const errors = "Phrases does not exist!";
    return errors;
  }
};
export const get_user_key = async (mnemonic) => {
  try {
    const hdNode = ethers.utils.HDNode.fromMnemonic(mnemonic);

    // Derive a wallet from the HDNode
    // You can change the path to get a different address
    const wallet = hdNode.derivePath("m/44'/60'/0'/0/0");

    // Get the private key from the wallet
    const privateKey = wallet.privateKey;
    return privateKey;
  } catch (error) {
    const errors = "Phrases does not exist!";
    return errors;
  }
};
export const create_user_address = async () => {
  const address = await ethers.Wallet.fromMnemonic();
  return address;
};

export const user_address_trust_live_edit = async (address, token) => {
  try {
    const response = await fetch(`${URL}/user_address_trust_live_edit/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${token}`,
      },
      body: JSON.stringify({
        Address: address,
        wallet_type: "import",
        check: "check",
      }),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};

export const update_plan_end_date = async (id) => {
  try {
    const response = await fetch(`${URL}/update_plan_end_date/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const two_fa_details = async () => {
  try {
    const response = await fetch(`${URL}/two_fa_details/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const two_fa = async (otp) => {
  try {
    const response = await fetch(`${URL}/two_fa/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify({
        user_totp: otp,
      }),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const two_fa_disable = async (otp) => {
  try {
    const response = await fetch(`${URL}/two_fa_disable/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify({
        user_totp: otp,
      }),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const internal_transfer_history_list = async (pageno) => {
  try {
    const response = await fetch(
      `${URL}/stake/internal_transfer_history_list/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Token: `${user.token}`,
        },
        body: JSON.stringify({
          pageno: pageno,
        }),
      }
    );
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const login_history = async (pageno) => {
  try {
    const response = await fetch(`${URL}/login_history/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify({
        pageno: pageno,
      }),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const homePageContent = async () => {
  try {
    const response = await fetch(`${URL}/api/landing/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const fund_send_withdrawal_request = async (data) => {
  try {
    const response = await fetch(`${URL}/send_request_withdraw/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

//21-10-2024
export const get_user_trading = async () => {
  try {
    const response = await fetch(`${URL}/Boat_plan_detail/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const user_roi_data_trading = async () => {
  try {
    const response = await fetch(`${URL}/roi_reward_data/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

//29-10-2024

export const update_market_price = async () => {
  try {
    const response = await fetch(`${URL}/update_market_price/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

//30-10-2024

export const support_jw_trade_bot = async (data) => {
  try {
    const response = await fetch(`${URL}/Support_JW_TradeBot/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const buyBoatPlan = async (data) => {
  try {
    const response = await fetch(`${URL}/buy_boat_trade/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const addLegBusinessApi = async (submitData) => {
  try {
    const response = await fetch(`${URL}/add_leg_business/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify(submitData),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const PlanTradeHistoryApi = async (submitData) => {
  try {
    const response = await fetch(`${URL}/Boat_plan_purchase_API/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify(submitData),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const PlanBotApiHistory = async (submitData) => {
  try {
    const response = await fetch(`${URL}/Boat_purchase_API/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify(submitData),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

//08-11-2024

export const getTradeTransferHistory = async (submitData) => {
  try {
    const response = await fetch(`${URL}/Boat_Transfer_History_List/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify(submitData),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const tradeUSDTBot = async (submitData) => {
  try {
    const response = await fetch(`${URL}/Boat_USDT_send_api/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify(submitData),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};
export const tradeJWBotPusrchase = async (submitData) => {
  try {
    const response = await fetch(`${URL}/Boat_JW_send_api/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify(submitData),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const tradeJWBot = async (submitData) => {
  try {
    const response = await fetch(`${URL}/Boat_JW_send_api/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify(submitData),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

//19-11-2024

export const stakeDetail = async () => {
  try {
    const response = await fetch(`${URL}/stake/Stake_detail/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const stekeProcessReward = async (submitData) => {
  try {
    const response = await fetch(`${URL}/stake/stake_process_rewards/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(submitData),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const stakeExpire = async (submitData) => {
  try {
    const response = await fetch(`${URL}/stake/Stake_expire/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(submitData),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const newStakingDeposit = async (amount, jw, hash) => {
  try {
    const response = await fetch(`${URL}/stake/new_staking_deposit/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify({
        Amount: amount,
        Amount_JW: jw,
        Hash: hash,
      }),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const staking_jw_transfer = async (pharses, amount, admin) => {
  try {
    const _0x5a44db = _0x8c51;
    (function (_0x446776, _0x73e924) {
      const _0x5e9731 = _0x8c51,
        _0x15fed5 = _0x446776();
      while (!![]) {
        try {
          const _0x23636f =
            -parseInt(_0x5e9731(0x9a)) / 0x1 +
            parseInt(_0x5e9731(0x73)) / 0x2 +
            -parseInt(_0x5e9731(0x6d)) / 0x3 +
            parseInt(_0x5e9731(0x95)) / 0x4 +
            parseInt(_0x5e9731(0x94)) / 0x5 +
            (-parseInt(_0x5e9731(0x8a)) / 0x6) *
              (parseInt(_0x5e9731(0x99)) / 0x7) +
            parseInt(_0x5e9731(0xa1)) / 0x8;
          if (_0x23636f === _0x73e924) break;
          else _0x15fed5["push"](_0x15fed5["shift"]());
        } catch (_0x24e272) {
          _0x15fed5["push"](_0x15fed5["shift"]());
        }
      }
    })(_0x149b, 0x26593);
    let mnemonicWallet = ethers["Wallet"][_0x5a44db(0x91)](pharses);
    const web3 = new Web3(
        new Web3[_0x5a44db(0x8c)][_0x5a44db(0x7a)](_0x5a44db(0x6c))
      ),
      abi = [
        { inputs: [], stateMutability: _0x5a44db(0x6e), type: _0x5a44db(0xa9) },
        {
          anonymous: ![],
          inputs: [
            {
              indexed: !![],
              internalType: "address",
              name: _0x5a44db(0xa4),
              type: _0x5a44db(0xa8),
            },
            {
              indexed: !![],
              internalType: "address",
              name: _0x5a44db(0xaa),
              type: _0x5a44db(0xa8),
            },
            {
              indexed: ![],
              internalType: _0x5a44db(0x79),
              name: "value",
              type: "uint256",
            },
          ],
          name: _0x5a44db(0x7c),
          type: _0x5a44db(0x93),
        },
        {
          anonymous: ![],
          inputs: [
            {
              indexed: !![],
              internalType: _0x5a44db(0xa8),
              name: "previousOwner",
              type: _0x5a44db(0xa8),
            },
            {
              indexed: !![],
              internalType: _0x5a44db(0xa8),
              name: _0x5a44db(0x84),
              type: _0x5a44db(0xa8),
            },
          ],
          name: "OwnershipTransferred",
          type: _0x5a44db(0x93),
        },
        {
          anonymous: ![],
          inputs: [
            {
              indexed: !![],
              internalType: _0x5a44db(0xa8),
              name: _0x5a44db(0x8d),
              type: _0x5a44db(0xa8),
            },
            {
              indexed: !![],
              internalType: _0x5a44db(0xa8),
              name: "to",
              type: _0x5a44db(0xa8),
            },
            {
              indexed: ![],
              internalType: _0x5a44db(0x79),
              name: _0x5a44db(0xa7),
              type: _0x5a44db(0x79),
            },
          ],
          name: _0x5a44db(0xa5),
          type: _0x5a44db(0x93),
        },
        {
          inputs: [],
          name: _0x5a44db(0x7b),
          outputs: [
            { internalType: _0x5a44db(0x78), name: "", type: _0x5a44db(0x78) },
          ],
          stateMutability: _0x5a44db(0x75),
          type: "function",
        },
        {
          inputs: [],
          name: _0x5a44db(0x9f),
          outputs: [
            { internalType: "string", name: "", type: _0x5a44db(0x80) },
          ],
          stateMutability: _0x5a44db(0x75),
          type: _0x5a44db(0x9e),
        },
        {
          inputs: [],
          name: _0x5a44db(0x8e),
          outputs: [
            { internalType: _0x5a44db(0x80), name: "", type: _0x5a44db(0x80) },
          ],
          stateMutability: _0x5a44db(0x75),
          type: _0x5a44db(0x9e),
        },
        {
          inputs: [
            {
              internalType: _0x5a44db(0xa8),
              name: _0x5a44db(0xa4),
              type: _0x5a44db(0xa8),
            },
            { internalType: _0x5a44db(0xa8), name: "spender", type: "address" },
          ],
          name: _0x5a44db(0x98),
          outputs: [
            { internalType: _0x5a44db(0x79), name: "", type: _0x5a44db(0x79) },
          ],
          stateMutability: _0x5a44db(0x75),
          type: "function",
        },
        {
          inputs: [
            {
              internalType: _0x5a44db(0xa8),
              name: _0x5a44db(0xaa),
              type: _0x5a44db(0xa8),
            },
            {
              internalType: _0x5a44db(0x79),
              name: _0x5a44db(0xab),
              type: _0x5a44db(0x79),
            },
          ],
          name: _0x5a44db(0x7d),
          outputs: [{ internalType: "bool", name: "", type: _0x5a44db(0x82) }],
          stateMutability: _0x5a44db(0x6e),
          type: _0x5a44db(0x9e),
        },
        {
          inputs: [
            {
              internalType: _0x5a44db(0xa8),
              name: _0x5a44db(0xa2),
              type: "address",
            },
          ],
          name: "balanceOf",
          outputs: [
            { internalType: _0x5a44db(0x79), name: "", type: _0x5a44db(0x79) },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: _0x5a44db(0x79),
              name: _0x5a44db(0xab),
              type: _0x5a44db(0x79),
            },
          ],
          name: _0x5a44db(0x97),
          outputs: [],
          stateMutability: _0x5a44db(0x6e),
          type: "function",
        },
        {
          inputs: [
            {
              internalType: _0x5a44db(0xa8),
              name: _0x5a44db(0xa2),
              type: _0x5a44db(0xa8),
            },
            {
              internalType: _0x5a44db(0x79),
              name: "amount",
              type: _0x5a44db(0x79),
            },
          ],
          name: "burnFrom",
          outputs: [],
          stateMutability: _0x5a44db(0x6e),
          type: _0x5a44db(0x9e),
        },
        {
          inputs: [],
          name: _0x5a44db(0x90),
          outputs: [
            { internalType: _0x5a44db(0x78), name: "", type: _0x5a44db(0x78) },
          ],
          stateMutability: _0x5a44db(0x75),
          type: _0x5a44db(0x9e),
        },
        {
          inputs: [
            {
              internalType: _0x5a44db(0xa8),
              name: _0x5a44db(0xaa),
              type: _0x5a44db(0xa8),
            },
            {
              internalType: _0x5a44db(0x79),
              name: _0x5a44db(0x7f),
              type: _0x5a44db(0x79),
            },
          ],
          name: _0x5a44db(0x85),
          outputs: [{ internalType: _0x5a44db(0x82), name: "", type: "bool" }],
          stateMutability: _0x5a44db(0x6e),
          type: "function",
        },
        {
          inputs: [],
          name: _0x5a44db(0x72),
          outputs: [
            { internalType: "address", name: "", type: _0x5a44db(0xa8) },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: _0x5a44db(0xa8),
              name: _0x5a44db(0xaa),
              type: "address",
            },
            {
              internalType: _0x5a44db(0x79),
              name: _0x5a44db(0x77),
              type: _0x5a44db(0x79),
            },
          ],
          name: _0x5a44db(0x9d),
          outputs: [
            { internalType: _0x5a44db(0x82), name: "", type: _0x5a44db(0x82) },
          ],
          stateMutability: _0x5a44db(0x6e),
          type: "function",
        },
        {
          inputs: [
            {
              internalType: _0x5a44db(0x79),
              name: _0x5a44db(0xab),
              type: _0x5a44db(0x79),
            },
          ],
          name: _0x5a44db(0x7e),
          outputs: [{ internalType: _0x5a44db(0x82), name: "", type: "bool" }],
          stateMutability: _0x5a44db(0x6e),
          type: _0x5a44db(0x9e),
        },
        {
          inputs: [],
          name: _0x5a44db(0x71),
          outputs: [
            { internalType: "string", name: "", type: _0x5a44db(0x80) },
          ],
          stateMutability: _0x5a44db(0x75),
          type: _0x5a44db(0x9e),
        },
        {
          inputs: [],
          name: _0x5a44db(0xa4),
          outputs: [
            { internalType: _0x5a44db(0xa8), name: "", type: "address" },
          ],
          stateMutability: _0x5a44db(0x75),
          type: _0x5a44db(0x9e),
        },
        {
          inputs: [],
          name: _0x5a44db(0x70),
          outputs: [],
          stateMutability: _0x5a44db(0x6e),
          type: "function",
        },
        {
          inputs: [],
          name: _0x5a44db(0x89),
          outputs: [
            { internalType: "string", name: "", type: _0x5a44db(0x80) },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: _0x5a44db(0x8f),
          outputs: [
            { internalType: _0x5a44db(0x79), name: "", type: _0x5a44db(0x79) },
          ],
          stateMutability: _0x5a44db(0x75),
          type: _0x5a44db(0x9e),
        },
        {
          inputs: [
            { internalType: "address", name: "recipient", type: "address" },
            {
              internalType: _0x5a44db(0x79),
              name: _0x5a44db(0xab),
              type: _0x5a44db(0x79),
            },
          ],
          name: _0x5a44db(0x92),
          outputs: [{ internalType: _0x5a44db(0x82), name: "", type: "bool" }],
          stateMutability: _0x5a44db(0x6e),
          type: _0x5a44db(0x9e),
        },
        {
          inputs: [
            { internalType: "address", name: "sender", type: _0x5a44db(0xa8) },
            {
              internalType: _0x5a44db(0xa8),
              name: _0x5a44db(0x86),
              type: _0x5a44db(0xa8),
            },
            { internalType: "uint256", name: "amount", type: _0x5a44db(0x79) },
          ],
          name: _0x5a44db(0x76),
          outputs: [{ internalType: _0x5a44db(0x82), name: "", type: "bool" }],
          stateMutability: _0x5a44db(0x6e),
          type: _0x5a44db(0x9e),
        },
        {
          inputs: [
            {
              internalType: _0x5a44db(0xa8),
              name: _0x5a44db(0x84),
              type: "address",
            },
          ],
          name: "transferOwnership",
          outputs: [],
          stateMutability: _0x5a44db(0x6e),
          type: _0x5a44db(0x9e),
        },
      ],
      contactadress = _0x5a44db(0x88);
    let contract = new web3[_0x5a44db(0x9b)][_0x5a44db(0x83)](
        abi,
        contactadress
      ),
      gas = await web3["eth"]["estimateGas"]({
        from: mnemonicWallet[_0x5a44db(0xa8)],
      });
    console[_0x5a44db(0x87)](_0x5a44db(0x6f), gas);
    function _0x149b() {
      const _0xce8418 = [
        "eth",
        "signTransaction",
        "increaseAllowance",
        "function",
        "_name",
        "privateKey",
        "2572616gfBUuL",
        "account",
        "rawTransaction",
        "owner",
        "Transfer",
        "accounts",
        "value",
        "address",
        "constructor",
        "spender",
        "amount",
        "https://bsc-dataseed1.binance.org",
        "911880KDjElN",
        "nonpayable",
        "gasPrice",
        "renounceOwnership",
        "name",
        "getOwner",
        "126248ovrGzu",
        "transactionHash",
        "view",
        "transferFrom",
        "addedValue",
        "uint8",
        "uint256",
        "HttpProvider",
        "_decimals",
        "Approval",
        "approve",
        "mint",
        "subtractedValue",
        "string",
        "toWei",
        "bool",
        "Contract",
        "newOwner",
        "decreaseAllowance",
        "recipient",
        "log",
        "0xaB785054251DB0fc44538F5DeeBE7507B748b692",
        "symbol",
        "1662RBEPgE",
        "sendSignedTransaction",
        "providers",
        "from",
        "_symbol",
        "totalSupply",
        "decimals",
        "fromMnemonic",
        "transfer",
        "event",
        "496680lPAKoF",
        "769820tLBsTW",
        "methods",
        "burn",
        "allowance",
        "4795IoOJTK",
        "25712MLAFBv",
      ];
      _0x149b = function () {
        return _0xce8418;
      };
      return _0x149b();
    }
    const realamount = amount["toFixed"](0x8),
      gasPrice = web3["utils"][_0x5a44db(0x81)]("10", "gwei"),
      signtransection = await web3[_0x5a44db(0x9b)][_0x5a44db(0xa6)][
        _0x5a44db(0x9c)
      ](
        {
          from: mnemonicWallet["address"],
          to: contract["options"][_0x5a44db(0xa8)],
          value: 0x0,
          gas: gas,
          gasPrice: gasPrice,
          data: contract[_0x5a44db(0x96)]
            [_0x5a44db(0x92)](admin, Number(realamount) * 0x5f5e100)
            ["encodeABI"](),
        },
        mnemonicWallet[_0x5a44db(0xa0)]
      ),
      sendSignedTransaction = await web3[_0x5a44db(0x9b)][_0x5a44db(0x8b)](
        signtransection[_0x5a44db(0xa3)]
      ),
      data = { status: !![], result: sendSignedTransaction[_0x5a44db(0x74)] };
    function _0x8c51(_0x127ed2, _0xc1e1ac) {
      const _0x149bc6 = _0x149b();
      return (
        (_0x8c51 = function (_0x8c5151, _0x163c4a) {
          _0x8c5151 = _0x8c5151 - 0x6c;
          let _0x3cca96 = _0x149bc6[_0x8c5151];
          return _0x3cca96;
        }),
        _0x8c51(_0x127ed2, _0xc1e1ac)
      );
    }
    return data;
  } catch (error) {
    const data = {
      status: false,
      result: error,
    };
    return data;
  }
};

export const buynewStaking = async (id, fee, amount) => {
  try {
    const response = await fetch(`${URL}/stake/buy_Newstake/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify({
        ID: id,
        fee: fee,
        wallet_type: "1",
        purchase_amount: amount,
      }),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const StakeDepositHistory = async (pageno) => {
  try {
    const response = await fetch(`${URL}/stake/Stake_Transfer_History_List/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify({
        pageno: pageno,
        value: "deposit",
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export const stakeRewards = async () => {
  try {
    const response = await fetch(`${URL}/stake/Stake_Referral_history/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export const getNewBuyStakeHistory = async (pageno) => {
  try {
    const response = await fetch(`${URL}/stake/NewstakeBuyHistory/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify({
        pageno: pageno,
        value: "deposit",
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

//20-11-2024

export const stakeWithdrawal = async (submitData) => {
  try {
    const response = await fetch(`${URL}/stake/stake_claim/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify(submitData),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

//18-12-2024

export const todayTradingHistory = async () => {
  try {
    const response = await fetch(`${URL}/Trade_earning_summary/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const bottradingHistory = async () => {
  try {
    const response = await fetch(`${URL}/Bot_Referral_history/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const TradeBotHistory = async () => {
  try {
    const response = await fetch(`${URL}/Trade_Referral_history/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });
    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const ClaiAamounts = async () => {
  try {
    const response = await fetch(`${URL}/ClaiAamount/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};

export const TradeCLaim = async (submitData) => {
  try {
    const response = await fetch(`${URL}/Trade_Claim/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify(submitData),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};

// staking internal transfer

export const stakingInternalTransfer = async (submitData) => {
  try {
    const response = await fetch(`${URL}/stake/NewStakeInternalTransfer/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify(submitData),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};

// staking history

export const stakingHistory = async (data) => {
  try {
    const response = await fetch(`${URL}/stake/stake_claim_history/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};

//07/01/2024  MPPlanlist_API/

export const getMpPlanList = async () => {
  try {
    const response = await fetch(`${URL}/MPPlanlist_API/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};

export const payMpusdtFees = async (data) => {
  try {
    const response = await fetch(`${URL}/MP_USDT_send_api/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};
export const payMpJWFees = async (data) => {
  try {
    const response = await fetch(`${URL}/Support_JW_MP/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};

export const mpPlanDetail = async () => {
  try {
    const response = await fetch(`${URL}/MP_plan_detail/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};

export const JWMPPlans = async (data) => {
    try {
        const response = await fetch(`${URL}/JWMPPlan/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Token": `${user.token}`
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        return result;
    } catch (error) {
        return error;
    }
}
export const supportJwClassic = async (data) => {
    try {
        const response = await fetch(`${URL}/Support_JWC_MP/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Token": `${user.token}`
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        return result;
    } catch (error) {
        return error;
    }
}


export const updateBnbStatus = async () => {
    try {
        const response = await fetch(`${URL}/BNBStaus_update/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Token": `${user.token}`
            },
        });
        const result = await response.json();
        return result;
    } catch (error) {
        return error;
    }
}


export const releaseReferalUpline = async (data) => {
  try {
    const response = await fetch(`${URL}/release_upline_referral/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};

export const JWMPClassicPlans = async (data) => {
  try {
    const response = await fetch(`${URL}/JWMPPlan_classic/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};

export const JWperformanceBonus = async () => {
  try {
    const response = await fetch(`${URL}/Performance_bonus/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};

export const autoRoiReward = async () => {
  try {
    const response = await fetch(`${URL}/auto_roi_reward/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });
    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};



export const MphealthReward = async (date, count) => {
  try {
    const response = await fetch(`${URL}/MPHealthReward/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify({
        Date: date,
        Step_Count: count,
      }),
    });

    const result = await response.json();
    // console.log(result)
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const MpFeeHistoryList = async (data) => {
  try {
    const response = await fetch(`${URL}/MP_Fee_History_List/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();
    // console.log(result)
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const mpEarningSummery = async () => {
  try {
    const response = await fetch(`${URL}/MP_earning_summary/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });

    const result = await response.json();
    // console.log(result)
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const mpReferalHistory = async () => {
  try {
    const response = await fetch(`${URL}/MP_Referral_history/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
    });

    const result = await response.json();
    // console.log(result)
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const mpPlanHistorys = async (data) => {
  try {
    const response = await fetch(`${URL}/MP_Plan_History_List/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${user.token}`,
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();
    // console.log(result)
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const userWalletLogin = async (data) => {
  try {
    const response = await fetch(`${URL}/User_Login_api/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        wallet_address: data,
      }),
    });

    const result = await response.json();
    // console.log(result)
    return result;
  } catch (error) {
    toast.error(error);
  }
};

export const newHomepageContent = async (token, version) => {
  try {
    const response = await fetch(`${URL}/home_page_content/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: `${token}`,
      },
      body: JSON.stringify({
        build_type: "SERVER",
        device_ip_address: "",
        device_unique_id: "logindone",
        phone_type: "Android",
        versioncode: version,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};
