import React from "react";
import ReferalCodeComponent from "../components/ReferalCodeComponent";

export default function ReferalCodePage() {
  return (
    <div>
      <ReferalCodeComponent />
    </div>
  );
}
