import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";

export default function LeaderShipHistoryComponent({
  childdata,
  handlePageChange,
  activePage,
  count,
}) {

  return (
    <div className="card pt-4 mb-3 px-3">
      <div className="card-header">
        <h4>Reward History</h4>
      </div>
      <div className="table-responsive text-center">
        <table className="table table-borderless border-top text-center">
          <thead className="border-bottom text-center">
            <tr>
              <th>S.No</th>
              <th>Amount</th>
              <th>Hash</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {childdata.map((e) => {
              return (
                <tr key={e.sno}>
                  <td>{e.sno}</td>
                  <td>{parseFloat(e.amount_usdt).toFixed(2)}</td>

                  <td>
                    {e.Hash == "0x1468a5baaaca8d5e927ce129fd3c" ? (
                      <>
                        <p>Internal Transfer</p>
                      </>
                    ) : (
                      <>{e.Hash.slice(0, 10)}</>
                    )}
                  </td>
                  <td>
                    <Link target="_blank" to={`https://bscscan.com/tx/${e.Hash}`}>Go</Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          activePage={activePage}
          itemsCountPerPage={10}
          totalItemsCount={count}
          pageRangeDisplayed={count / 10}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}
