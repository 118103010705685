import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { getNewBuyStakeHistory } from "../../common/Api";
import DateFormatter from "../common/DateFormatter";

export default function NewStakeBuyHistory() {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(true);

  const get_premium_transfer_history = async () => {
    const data = await getNewBuyStakeHistory(page);
    console.log(data);
    setData(data.data);
    setCount(data.count);
    setLoading(false)
  };

  useEffect(() => {
    get_premium_transfer_history();
  }, [page]);

  const handlePageChange = async (pageNumber) => {
    setActivePage(pageNumber);
    console.log(`active page is ${pageNumber}`);
    const data = await getNewBuyStakeHistory(pageNumber);
    setData(data.data);
    setCount(data.count);
    setLoading(false)
  };

  // console.log(data)
  return (
    <>
      {loading ? (
        <>
          <div className="loading-spinner"></div>
        </>
      ) : (
        <>
          <div class="container-xxl flex-grow-1 container-p-y">
            <div class="row">
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-borderless border-top">
                    <thead className="border-bottom">
                      <tr>
                        <th>S.No</th>
                        <th>Plan Name</th>
                        <th>Wallet Type</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((e) => {
                        return (
                          <tr key={e.sno}>
                            <td>{e.sno}</td>
                            <td>
                              {e.Plan_name}
                              <br />
                              Amount : {parseFloat(e.Plan_Amount).toFixed(
                                2
                              )}{" "}
                              USDT
                            </td>

                            <td>{e.Wallet_Type}</td>
                            <td>
                              <DateFormatter timestamp={e?.start_date} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {data.length > 10 && (
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={count}
                      pageRangeDisplayed={count / 10}
                      onChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
