import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getMpPlanList,
  MpFeeHistoryList,
  mpPlanHistorys,
  mpPlanDetail,
} from "../common/Api";
import Pagination from "react-js-pagination";

export default function BuyPlanNew() {
  const ph = JSON.parse(localStorage.getItem("ph"));
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [plan, setPlan] = useState([]);
  const [eligiblePlan, setEligiblePlan] = useState([]);
  const [currentPlan, setCurrentPlan] = useState(null);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [mpFeeHistory, setMpFeeHistory] = useState([]);
  const [mpPlanHistory, setMpPlanHistory] = useState([]);
  const [activeTab, setActiveTab] = useState("all");
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const getMpPlanListss = async () => {
    try {
      const data = await getMpPlanList();
      const plandetails = await mpPlanDetail();
      setEligiblePlan(plandetails?.buyplan);
      setLoading(false);
      setPlan(data.data);

      const defaultPlan = data.data.find(
        (item) => item.plan_name === "Basic Plan"
      );
      if (defaultPlan) {
        setSelectedPlan(defaultPlan);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (event) => {
    const selectedPlanName = event.target.value;
    const plans = plan.find((item) => item.plan_name === selectedPlanName);
    setSelectedPlan(plans);
  };

  useEffect(() => {
    getMpPlanListss();
  }, []);

  const handlePlanClick = (plan) => {
    setCurrentPlan(plan);
  };

  const handleBuy = () => {
    if (currentPlan && eligiblePlan.includes(String(currentPlan.plan))) {
      navigate("/user/buy-plan-details", { state: currentPlan });
    } else {
      alert("You are not eligible for this plan.");
    }
  };

  const fetchMpFeeHistory = async () => {
    try {
      const data = {
        pageno: activePage,
        value: "deposit",
      };
      const response = await MpFeeHistoryList(data);
      setMpFeeHistory(response.Data);
      setCount(response.Data.length);
    } catch (error) {
      console.error("Error fetching MP Fee History:", error);
    }
  };

  const handlePageChange = async (pageNumber) => {
    setActivePage(pageNumber);

    const subnitData = {
      pageno: pageNumber,
      value: "deposit",
    };
    const data = await fetchMpFeeHistory(subnitData);
    setMpFeeHistory(data.Data);
    setCount(data.Data.length);
    setCount(data.count);
  };

  const fetchMpPlanHistory = async () => {
    try {
      const data = {
        pageno: activePage,
        value: "deposit",
      };

      const response = await mpPlanHistorys(data);
      setMpPlanHistory(response.Data);
      setCount(response.Data.length);
    } catch (error) {
      console.error("Error fetching MP Plan History:", error);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === "all") {
      fetchMpFeeHistory();
    } else if (tab === "user_referal") {
      fetchMpPlanHistory();
    }
  };

  useEffect(() => {
    if (activeTab === "all") {
      fetchMpFeeHistory();
    } else if (activeTab === "user_referal") {
      fetchMpPlanHistory();
    }
  }, [activeTab]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <>
      {loading ? (
        <>
          <div className="loading-spinner"></div>
        </>
      ) : (
        <>
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row buyPlanRow">
              <div className="col-lg-4 mb-3">
                <h3>Plans</h3>
                <select
                  onChange={handleSelectChange}
                  value={selectedPlan ? selectedPlan.plan_name : ""}
                  className="form-control"
                >
                  <option value="" disabled>
                    Select a Plan Category
                  </option>
                  {plan.map((planCategory, index) => (
                    <option key={index} value={planCategory.plan_name}>
                      {planCategory.plan_name}
                    </option>
                  ))}
                </select>
              </div>

              {selectedPlan && (
                <div>
                  <div className="row">
                    {selectedPlan.plans.map((plan, idx) => (
                      <div
                        className="col-lg-3 col-md-4 col-12 mb-3 mt-0 mt-lg-4 mt-md-4"
                        key={idx}
                      >
                        <div
                          className={`card p-4 planBox-wrapper-card ${
                            currentPlan === plan ? "priceCardActive" : ""
                          }`}
                          onClick={() => handlePlanClick(plan)}
                        >
                          <div className="planBox-wrapper d-flex align-items-center justify-content-between">
                            <div className="planBox-part1">
                              <h6 className="mb-0 mb-1">
                                <small>
                                  {plan.plan_name}{" "}
                                  {eligiblePlan.includes(String(plan.plan)) && (
                                    <b>
                                      <span
                                        style={{ color: "#e19600" }}
                                        className="blink-animation"
                                      >
                                        Eligible
                                      </span>
                                    </b>
                                  )}
                                </small>
                              </h6>
                              <h4 className="mb-0">{plan.plan} USDT</h4>
                            </div>
                            <div className="planBox-part2 text-end">
                              <h6 className="mb-0 mb-1">
                                <small>
                                  <span style={{ color: "#e19600" }}>
                                    {plan.time_period}
                                  </span>
                                </small>
                              </h6>
                              <h4 className="mb-0" style={{ fontSize: "16px" }}>
                                <b>Daily Rewards</b> {plan.daily_reward}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {currentPlan && (
              <div className="my-3">
                <button
                  className="btn btn-primary newBuyButtonUser"
                  onClick={handleBuy}
                >
                  Buy Now
                </button>
              </div>
            )}

            <div className="row">
              <div className="col-lg-12 mb-4">
                <div className="card px-4 pt-3 h-100">
                  <ul className="nav nav-tabs bg-transparent d-flex justify-content-center border-0 pb-3">
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          activeTab === "all" ? "active" : ""
                        }`}
                        href="#all"
                        data-bs-toggle="tab"
                        onClick={() => handleTabClick("all")}
                      >
                        MP Fee History List
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          activeTab === "user_referal" ? "active" : ""
                        }`}
                        href="#user_referal"
                        data-bs-toggle="tab"
                        onClick={() => handleTabClick("user_referal")}
                      >
                        MP Plan History List
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content bg-transparent">
                    <div
                      id="all"
                      className={`tab-pane fade ${
                        activeTab === "all" ? "show active" : ""
                      }`}
                    >
                      <div className="table-responsive">
                        <table className="table table-borderless border-top">
                          <thead>
                            <tr>
                              <th>Sr no</th>
                              <th>Amount</th>

                              <th>Type</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {mpFeeHistory.length > 0 ? (
                              mpFeeHistory.map((item, index) => (
                                <tr key={index}>
                                  <td>{item.sno} </td>
                                  <td>{item.amount_usdt} USDT</td>

                                  <td>{item.type}</td>
                                  <td>{formatDate(item.created_on)}</td>
                                  {/* Add more fields based on your API response */}
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="3">No data available</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {count > 10 && (
                          <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={count}
                            pageRangeDisplayed={count / 10}
                            onChange={handlePageChange}
                          />
                        )}
                      </div>
                    </div>

                    <div
                      id="user_referal"
                      className={`tab-pane fade ${
                        activeTab === "user_referal" ? "show active" : ""
                      }`}
                    >
                      <div className="table-responsive">
                        <table className="table table-borderless border-top">
                          <thead>
                            <tr>
                              <th>Sr no</th>
                              <th>Amount</th>

                              <th>Type</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {mpPlanHistory.length > 0 ? (
                              mpPlanHistory.map((item, index) => (
                                <tr key={index}>
                                  <td>{item.sno} </td>
                                  <td>{item.amount_usdt} USDT</td>

                                  <td>{item.type}</td>
                                  <td>{formatDate(item.created_on)}</td>
                                  {/* Add more fields based on your API response */}
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="3">No data available</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {count > 10 && (
                          <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={count}
                            pageRangeDisplayed={count / 10}
                            onChange={handlePageChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
