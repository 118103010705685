import { useState, useEffect, useRef } from 'react';

const useAutoLogout = (timeout = 30) => {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [timeRemaining, setTimeRemaining] = useState(timeout);
  const timerRef = useRef(null);

  // Handle user activity and reset the timer
  const handleUserActivity = () => {
    setTimeRemaining(timeout); // Reset time remaining on activity
    if (timerRef.current) {
      clearTimeout(timerRef.current); // Clear the existing timer
    }
    // Start a new timer
    timerRef.current = setTimeout(() => {
      handleLogout(); // Trigger logout after inactivity
    }, timeout * 60 * 1000); // timeout in milliseconds
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.clear();
    //localStorage.setItem("phn", 1);
    window.location.href = '/Login';
  };

  useEffect(() => {
    // Initialize the timer when the component mounts
    timerRef.current = setTimeout(() => {
      handleLogout();
    }, timeout * 60 * 1000);

    // Add event listeners for user activity
    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);

    // Cleanup event listeners and the timer when the component unmounts
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
    };
  }, [timeout]); // Reset the effect if the timeout value changes

  return { isLoggedIn, timeRemaining };
};

export default useAutoLogout;

